import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core';

@Component({
  selector: 'app-bingo',
  templateUrl: './bingo.component.html',
  styleUrls: ['./bingo.component.scss']
})
export class BingoComponent implements OnInit {

  isActive: boolean = false;

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.getToken();
  }

  getToken() {
    this.apiService.post('Bingo/Open').subscribe(
      r => {
        if (r.Result) {
          this.compileScript(r.Token);
          this.isActive = true;
        }
      }
    );
  }

  compileScript(value: any) {
    const hh =
      '(function (l, i, v, e, t, c, h) {' +
      'l["LGFrameObject"] = t; l[t] = l[t] || function () { (l[t].q = l[t].q || []).push(arguments) },' +
      'l[t].l = 1 * new Date(); c = i.createElement(v), h = i.getElementsByTagName(v)[0];' +
      'c.async = 1; c.src = e; h.parentNode.insertBefore(c, h)' +
      '})(window, document, "script", ("https://static.lgio.net/lg-f.js?v=" + (Date.now())), "lgf");' +
      'if (lgf) {' +
      'lgf("config", {' +
      'container: "lgf-container",' +
      'width: "100%",' +
      'height: "calc(100vh + 500px)",' +
      'params: {' +
      'sign: "' + value + '",' +
      '}' +
      '})' +
      '}';

    const node = document.createElement('script');
    node.type = 'text/javascript';
    node.appendChild(document.createTextNode(hh));
    document.getElementsByTagName('body')[0].appendChild(node);
  }

}
