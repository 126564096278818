import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { JwtService, CasinoService, LangService } from 'src/app/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { StorageMap } from '@ngx-pwa/local-storage';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { CasinoPlaySuitComponent } from 'src/app/shared/casino-play-suit/casino-play-suit.component';

@AutoUnsubscribe()
@Component({
  selector: 'app-casino-game-list',
  templateUrl: './casino-game-list.component.html',
  styleUrls: ['./casino-game-list.component.scss']
})
export class CasinoGameListComponent implements OnInit, OnDestroy {

  @Input() header: boolean = true;
  @Input() localImg: boolean = false;
  @Input() icon: string;
  @Input() desc: string = '';
  @Input() title: string = '';
  @Input() dataList: any[];
  @Input() vendors: any[];
  @Input() featuredVendors: any[];
  @Input() noDiscountGames: any[] = [];
  @Input() classList: string = 'col-xl-3 col-lg-3 col-md-4';
  @Input() suitUrl: string;
  @Output() vendor = new EventEmitter<any>();

  list: any = [];
  animateClass: string = 'zoomIn';
  allItemsShow: boolean = true;
  selectedVendor: any = '';
  userCurrency: string = 'TRY';
  defaultLang:string;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    public jwtService: JwtService,
    private ngxSmartModalService: NgxSmartModalService,
    private storage: StorageMap,
    private casinoService: CasinoService,
    private bottomSheet: MatBottomSheet,
    private langService: LangService
  ) {
    this.defaultLang = String(this.langService.currentLang()).toUpperCase();
    this.jwtService.isLoggedIn().subscribe(
      t => {
        if (t) {
          this.storage.get('userInfo').subscribe(
            (r: any) => {
              this.userCurrency = r.user.currencyCode;
            }
          )
        } else {
          this.userCurrency = 'TRY';
        }
      }
    )
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {}

  total(i: any, e: any) {
    return Number(i) + Number(e);
  }

  play(item: any) {
    this.jwtService.isLoggedIn().subscribe(
      isLogin => {
        if (!item.data.playMode.fun && !isLogin) {
          this.ngxSmartModalService.getModal('loginModal').open();
        } else {
          sessionStorage.setItem('casinoGameData', JSON.stringify(item));
          this.breakpointObserver.observe([
            Breakpoints.Handset,
            Breakpoints.Tablet
          ]).subscribe((result: BreakpointState) => {
            if (result.matches) {
              this.bottomSheet.open(CasinoPlaySuitComponent,
                { panelClass: 'bottom-sheet-casino', disableClose: true, closeOnNavigation: false })
                .afterOpened().subscribe(
                  () => {
                    this.casinoService.fastPlayOpen.next(true);
                  }
                );
            } else {
              this.router.navigate(['/' + this.suitUrl]);
            }
          }).unsubscribe();
        }
      }
    ).unsubscribe();
  }

  changeVendor(id?: any) {
    if (id) {
      this.vendor.emit(id);
      this.selectedVendor = id;
    } else {
      this.vendor.emit('');
      this.selectedVendor = '';
    }
  }

  hasNoDiscount(gameCode:any){
    const noDiscountGames = this.noDiscountGames || [];
    for (const item of noDiscountGames) {
      return item == gameCode;
    }
  }
}
