import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';

@Component({
  selector: 'app-sidebar-card-sports',
  templateUrl: './sidebar-card-sports.component.html',
  styleUrls: ['./sidebar-card-sports.component.scss']
})
export class SidebarCardSportsComponent implements OnInit, AfterViewInit {

  carouselTileConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    speed: 1500,
    point: {
      visible: true
    },
    touch: true,
    loop: true,
    interval: { timing: 5500 },
    animation: 'lazy'
  };

  data = [
    {
      'url': 'assets/img/sports-1.jpg',
      'category': 'SPORTS',
      'title': 'World Cup Live Cashback',
      'content': 'Bonus on Multiple Bets.'
    },
    {
      'url': 'assets/img/sports-1.jpg',
      'category': 'SPORTS',
      'title': 'World Cup Live Cashback',
      'content': 'Bonus on Multiple Bets.'
    },
    {
      'url': 'assets/img/sports-1.jpg',
      'category': 'SPORTS',
      'title': 'World Cup Live Cashback',
      'content': 'Bonus on Multiple Bets.'
    },
    {
      'url': 'assets/img/sports-1.jpg',
      'category': 'SPORTS',
      'title': 'World Cup Live Cashback',
      'content': 'Bonus on Multiple Bets.'
    }
  ];

  index: any = 0;

  constructor(private _cdr: ChangeDetectorRef) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this._cdr.detectChanges();
  }

}
