import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventStatsService {

  constructor(
    private http: HttpClient,
    private loaderService: NgxUiLoaderService
  ) { }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  private endLoader(loaderID: any) {
    if (loaderID) {
      this.loaderService.stopLoader(loaderID);
    }
  }

  private startLoader(loaderID: any) {
    if (loaderID) {
      this.loaderService.startLoader(loaderID);
    }
  }

  post(path: string, body: Object = {}, loaderID: any = false): Observable<any> {
    this.startLoader(loaderID);
    return this.http.post(
      `${environment.event_api_url}${path}`,
      JSON.stringify(body)
    ).pipe(
      catchError(this.formatErrors),
      finalize(() => {
        this.endLoader(loaderID);
      })
    );
  }
}
