import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';

@Component({
  selector: 'app-home-featured-providers',
  templateUrl: './featured-providers.component.html',
  styleUrls: ['./featured-providers.component.scss']
})
export class FeaturedProvidersComponent implements OnInit, AfterViewInit {

  carouselTileConfig: NguCarouselConfig = {
    grid: { xs: 2, sm: 4, md: 5, lg: 6, all: 0 },
    speed: 1500,
    point: {
      visible: true
    },
    touch: true,
    loop: true,
    interval: { timing: 5500 },
    animation: 'lazy'
  };

  data = [
    {
      'url': 'assets/img/providers/netent.png'
    },
    {
      'url': 'assets/img/providers/gowithflow.png'
    },
    {
      'url': 'assets/img/providers/connecto.png'
    },
    {
      'url': 'assets/img/providers/playground.png'
    },
    {
      'url': 'assets/img/providers/netent.png'
    },
    {
      'url': 'assets/img/providers/squirel.png'
    }, {
      'url': 'assets/img/providers/netent.png'
    },
    {
      'url': 'assets/img/providers/gowithflow.png'
    },
    {
      'url': 'assets/img/providers/connecto.png'
    },
    {
      'url': 'assets/img/providers/playground.png'
    },
    {
      'url': 'assets/img/providers/netent.png'
    },
    {
      'url': 'assets/img/providers/squirel.png'
    }
  ];

  constructor(private _cdr: ChangeDetectorRef) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this._cdr.detectChanges();
  }

}
