import { Pipe, PipeTransform } from '@angular/core';
import { BetCardService } from 'src/app/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Pipe({
  name: 'oddLoader'
})
export class OddLoaderPipe implements PipeTransform {

  constructor(
    private betCardService: BetCardService
  ) {
  }

  transform(value: any): Observable<boolean> {
    return this.betCardService.activeLoaderOdds().pipe(
      map(x => {
        for (let index = 0; index < x.length; index++) {
          const element = x[index];
          if (Number(element) === Number(value)) {
            return true;
          }
        }
        return false;
      })
    );
  }

}
