import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { EventsService, ApiService, FavoriteService } from 'src/app/core';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { diff } from 'deep-object-diff';

@AutoUnsubscribe()
@Component({
  selector: 'app-live-betting-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('panel') panel: ElementRef;
  menuTitle: boolean = false;
  liveList: any = [];
  liveLayout: any = [];
  data: any = [];
  menuList: any = [];
  timer: any;
  catTimer: any;
  columnLimit: any = 3;
  tableClass: any = 'large';
  liveReq: any;
  indexTrackFn = (index: number) => index;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private eventService: EventsService,
    private router: Router,
    private favoriteService: FavoriteService
  ) {
    this.getCategories();
  }

  ngOnInit() {

    this.route.paramMap.subscribe(
      () => {
        const c = this.route.snapshot.queryParamMap.get('c');
        this.getLive(c);
      }
    );

    combineLatest([this.route.queryParams]).subscribe(
      r => {
        if (r[0].c && !r[0].t) {
          this.getLive(r[0].c);
        }
      }
    );

    this.timer = setInterval(() => {
      const c = this.route.snapshot.queryParams.c;
      if (c) {
        this.getLive(c, null);
      }
    }, 3000);

    this.catTimer = setInterval(() => {
      this.getCategories(true);
    }, 6000);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.onResized();
    }, 0);
  }

  ngOnDestroy() {
    clearInterval(this.timer);
    clearInterval(this.catTimer);
  }

  onResized() {
    if (this.panel.nativeElement.offsetWidth > 900) {
      this.columnLimit = 3;
      this.tableClass = 'large';
    } else if (this.panel.nativeElement.offsetWidth < 900 && this.panel.nativeElement.offsetWidth > 700) {
      this.columnLimit = 2;
      this.tableClass = 'medium';
    } else {
      this.columnLimit = 1;
      this.tableClass = 'small';
    }
  }

  async getLive(catId: any, loaderId: any = 'liveHighlights') {
    const params = {};
    params['StartDate'] = formatDate(Date.now(), 'yyyy-MM-dd', 'en-US', 'UTC');
    params['EndDate'] = formatDate(Date.now() + (15 * 86400000), 'yyyy-MM-dd', 'en-US', 'UTC');
    params['isLive'] = true;
    params['PageSize'] = 100;
    params['CategoryId'] = catId;

    if (Number(catId) === 0) {
      const compFavs = await this.favoriteService.getFavorite('liveComp').toPromise();
      params['CompetitionFilter'] = compFavs.toString();
    }

    this.liveReq = this.eventService.getData(params, loaderId, 'comp').subscribe(
      r => {
        if (r.length > 0) {
          const df: any = diff(r, this.data);
          if (Object.keys(df).length > 0) {
            this.data = r;
            if (r[0].list[0] && typeof r[0].list[0].competitions !== 'undefined') {
              if (!this.route.snapshot.queryParamMap.get('t') && !this.route.snapshot.queryParamMap.get('eid')) {
                this.router.navigate(['.'],
                  {
                    relativeTo: this.route,
                    queryParams: {
                      t: r[0].list[0].competitions[0].events[0].externalId,
                      eid: r[0].list[0].competitions[0].events[0].id
                    }, queryParamsHandling: 'merge'
                  }
                );
              }
            }
          }
        } else {
          this.data = [];
        }
      }
    );
  }

  getCategories(refresh: boolean = false) {
    const data = {
      IsLive: 1,
      currentOnly: 1
    };
    let firstActv = false;
    let lastActv = false;
    this.apiService.post('Category/GetCategoriesWithEventCounter', data)
      .pipe(
        map(data => data.filter(item => item.type === 0 && item.enabled && item.eventCount > 0)),
        map(data => data.filter(
          item => {
            if (refresh) {
              if (Number(item.id) === Number(this.route.snapshot.queryParams.c)) {
                item.active = true;
                firstActv = item.id;
              }
              return item;
            } else {
              if (item.liveEventCount > 0 && Number(firstActv) !== 1) {
                if (item.id === 1) {
                  firstActv = item.id;
                } else if (item.id === 3) {
                  firstActv = item.id;
                } else if (item.id === 4) {
                  firstActv = item.id;
                } else if (item.id === 2) {
                  firstActv = item.id;
                } else {
                  lastActv = item.id;
                }
              }
            }
            return item;
          }
        )
        )
      )
      .subscribe(
        r => {
          const sportId = firstActv || lastActv || false;
          const df: any = diff(r, this.menuList);
          if ((typeof df === 'undefined' || df.length > 0 || this.menuList.length === 0)) {
            for (let index = 0; index < r.length; index++) {
              const element = r[index];
              if (element.id === sportId) {
                r[index].active = true;
              } else {
                r[index].active = false;
              }
            }
            if (sportId) {
              this.router.navigate([], { queryParams: { c: sportId }, queryParamsHandling: 'merge' });
            }
            this.menuList = r;
          }
        }
      );
  }

}
