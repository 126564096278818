import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiService, GlobalModalService, JwtService } from 'src/app/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sportsbook-sportsbook',
  templateUrl: './sportsbook.component.html',
  styleUrls: ['./sportsbook.component.scss']
})
export class SportsbookComponent implements OnInit, AfterViewInit {

  isLoggedIn: any = false;
  list: any;
  leftReset: any = false;
  menuTitle: string = 'Football';
  eventList: any;
  time: number = 12;
  activeCat: number = 1;

  constructor(
    private apiService: ApiService,
    private globalModalService: GlobalModalService,
    public jwtService: JwtService
  ) {

    this.jwtService.isLoggedIn().subscribe(
      isLogin => {
        const item = {
          'type': 'aviator',
          'data': {
            'slug': 'rocket',
            'playMode': {
              'fun': false,
              'anonymity': false,
              'realMoney': true
            },
            'presentation': {
              'gameName': {
                '*': 'Rocket'
              },
              'shortName': {
                '*': 'Rocket'
              },
              'backgroundImage': {
                '*': '//static.everymatrix.com/cms2/base/_casino/3/35DC003F8443F0B7C73E73F25DDE8598.jpg'
              },
              'thumbnail': {
                '*': '//static.everymatrix.com/cms2/base/_casino/3/35DC003F8443F0B7C73E73F25DDE8598.jpg'
              }
            },
            'property': {}
          }
        };
        sessionStorage.setItem('casinoGameData', JSON.stringify(item));
        this.isLoggedIn = isLogin;
      }
    )
  }

  ngOnInit() {
    this.getCategories();
  }

  ngAfterViewInit() {
    this.globalModalService.messageControl('modal_sportsbook');
  }

  leftScrool(event: any) {
    event.scrollLeft = Number(event.scrollLeft - 50);
    if (Number(event.scrollLeft) <= 50) { this.leftReset = false; }
  }

  rightScrool(event: any) {
    event.scrollLeft = Number(event.scrollLeft + 50);
    this.leftReset = true;
  }

  getCategories() {
    const data = {
      IsLive: 1,
      currentOnly: 1
    };
    this.apiService.post('Category/GetCategoriesWithEventCounter', data, false, true)
      .pipe(
        map(data => data.filter(item => item.type === 0 && item.enabled && item.eventCount > 0)),
        map(data => data.sort(
          (a, b) => {
            return a.id < b.id ? -1 : 1;
          }
        ))
      )
      .subscribe(
        r => {
          this.list = r;
        }
      );
  }

}
