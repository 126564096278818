import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ApiService, BetCardService } from 'src/app/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'app-virtual-bet-races',
  templateUrl: './races.component.html',
  styleUrls: ['./races.component.scss']
})
export class RacesComponent implements OnInit, OnDestroy {

  @Input() state: any;

  events: any = [];
  timer: any;

  constructor(
    private apiService: ApiService,
    private betCardService: BetCardService
  ) {
    this.getEvents('racesLoader');
  }

  ngOnInit() {
    this.timer = setInterval(() => {
      this.getEvents();
    }, 10000);
  }

  ngOnDestroy(){}

  getEvents(loader: any = false) {
    this.apiService.post('virtualrace/globalnextevent/122', {}, loader).subscribe(
      r => {
        if (r && r[0]) {
          this.events = r[0];
        } else {
          this.events = [];
        }
      }
    )
  }

  toggleOdd(id: any) {
    this.betCardService.toggleOdd(id).subscribe(() => {});
  }

}
