import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BingoRoutingModule } from './bingo-routing.module';
import { SharedModule } from '../shared';
import { BingoComponent } from './bingo.component';


@NgModule({
  declarations: [BingoComponent],
  imports: [
    CommonModule,
    BingoRoutingModule,
    SharedModule
  ]
})
export class BingoModule { }
