import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TabsModule } from 'ngx-tabset';
import { NgxMaskModule } from 'ngx-mask';
import { ResponsiveModule } from 'ngx-responsive';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { PlatformModule } from '@angular/cdk/platform';
import { LayoutModule } from '@angular/cdk/layout';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSliderModule } from '@angular/material/slider';
import { MatBottomSheetModule, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatSidenavModule } from '@angular/material/sidenav';
import { StorageModule } from '@ngx-pwa/local-storage';
import { ToastrModule } from 'ngx-toastr';
import { ClipboardModule } from 'ngx-clipboard';
import { CountdownModule } from 'ngx-countdown';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { VimeModule } from '@vime/angular';

import { FilterPipeModule } from 'ngx-filter-pipe';
import { OrderModule } from 'ngx-order-pipe';
import { OddPipe, OddActivePipe, FavoritePipe } from './pipes';

import { DropdownDirective } from './dropdown/dropdown.directive';

import { InputComponent } from './input/input.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { TextareaComponent } from './textarea/textarea.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { AlertComponent } from './alert/alert.component';
import { BetSlipComponent } from './bet-slip/bet-slip.component';
import { BestSellerComponent } from './best-seller/best-seller.component';
import { StreamTrackerComponent } from './stream-tracker/stream-tracker.component';
import { SidebarLiveCalendarComponent } from './sidebar-live-calendar/sidebar-live-calendar.component';
import { SidebarTopLeaguesComponent } from './sidebar-top-leagues/sidebar-top-leagues.component';
import { SidebarFavoritesComponent } from './sidebar-favorites/sidebar-favorites.component';
import { SidebarPrematchComponent } from './sidebar-prematch/sidebar-prematch.component';
import { SidebarCardCasinoComponent } from './sidebar-card-casino/sidebar-card-casino.component';
import { SidebarCardSportsComponent } from './sidebar-card-sports/sidebar-card-sports.component';
import { CasinoGameSuitComponent } from './casino-game-suit/casino-game-suit.component';
import { CasinoMenuComponent } from './casino-menu/casino-menu.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { SlideMenuComponent } from './slide-menu/slide-menu.component';
import { FeaturedEventsComponent } from './featured-events/featured-events.component';
import { LiveHighlightsComponent } from './live-highlights/live-highlights.component';
import { MobileNavComponent } from './layout/mobile-nav/mobile-nav.component';
import { ValidateComponent } from './validate/validate.component';
import { CasinoPlaySuitComponent } from './casino-play-suit/casino-play-suit.component';
import { OddsGroupComponent } from './odds-group/odds-group.component';
import { StatScoreComponent } from './stat-score/stat-score.component';
import { MybetsComponent } from './mybets/mybets.component';
import { GlobalModalComponent } from './global-modal/global-modal.component';
import { LiveSupportDirective } from './directives/live-support.directive';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { OddLoaderPipe } from './pipes/odd-loader.pipe';
import { SvgLoaderEventviewComponent } from './svg-loader-eventview/svg-loader-eventview.component';
import { LiveTvComponent } from './live-tv/live-tv.component';
import { SvgLoaderCasinoComponent } from './svg-loader-casino/svg-loader-casino.component';
import { StandingsComponent } from './standings/standings.component';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { CasinoGameListComponent } from './casino-game-list/casino-game-list.component';
import { PlaytechCasinoComponent } from './playtech-casino/playtech-casino.component';
import { PlaytechLiveCasinoComponent } from './playtech-live-casino/playtech-live-casino.component';


const config = {
  breakPoints: {
    xs: { max: 575.98 },
    sm: { min: 576, max: 767.98 },
    md: { min: 768, max: 991.98 },
    lg: { min: 992, max: 1199.98 },
    xl: { min: 1200 }
  },
  debounceTime: 100
};

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgSelectModule,
    TooltipModule,
    NgxSmartModalModule,
    CarouselModule,
    NgxUiLoaderModule,
    ToastrModule.forRoot(),
    TabsModule.forRoot(),
    NgxMaskModule.forRoot(),
    TranslateModule.forChild(),
    ResponsiveModule.forRoot(config),
    PlatformModule,
    LayoutModule,
    ScrollingModule,
    MatExpansionModule,
    MatSliderModule,
    MatBottomSheetModule,
    MatSidenavModule,
    MatNativeDateModule,
    MatDatepickerModule,
    StorageModule,
    FilterPipeModule,
    OrderModule,
    ClipboardModule,
    CountdownModule,
    InfiniteScrollModule,
    ContentLoaderModule,
    VimeModule
  ],
  declarations: [
    DropdownDirective,
    OddPipe,
    OddActivePipe,
    FavoritePipe,
    InputComponent,
    InputNumberComponent,
    TextareaComponent,
    HeaderComponent,
    FooterComponent,
    AlertComponent,
    BetSlipComponent,
    BestSellerComponent,
    StreamTrackerComponent,
    SidebarLiveCalendarComponent,
    SidebarTopLeaguesComponent,
    SidebarFavoritesComponent,
    SidebarPrematchComponent,
    SidebarCardCasinoComponent,
    SidebarCardSportsComponent,
    CasinoGameSuitComponent,
    CasinoMenuComponent,
    LoginComponent,
    RegisterComponent,
    SlideMenuComponent,
    FeaturedEventsComponent,
    LiveHighlightsComponent,
    MobileNavComponent,
    ValidateComponent,
    CasinoPlaySuitComponent,
    OddsGroupComponent,
    StatScoreComponent,
    MybetsComponent,
    GlobalModalComponent,
    LiveSupportDirective,
    ResetPasswordComponent,
    OddLoaderPipe,
    SvgLoaderEventviewComponent,
    LiveTvComponent,
    SvgLoaderCasinoComponent,
    StandingsComponent,
    InfoModalComponent,
    CasinoGameListComponent,
    PlaytechCasinoComponent,
    PlaytechLiveCasinoComponent
  ],
  exports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgSelectModule,
    TooltipModule,
    NgxSmartModalModule,
    CarouselModule,
    NgxUiLoaderModule,
    ToastrModule,
    TabsModule,
    NgxMaskModule,
    TranslateModule,
    ResponsiveModule,
    PlatformModule,
    LayoutModule,
    ScrollingModule,
    MatExpansionModule,
    MatSliderModule,
    MatBottomSheetModule,
    MatSidenavModule,
    MatNativeDateModule,
    MatDatepickerModule,
    StorageModule,
    FilterPipeModule,
    OrderModule,
    ClipboardModule,
    CountdownModule,
    InfiniteScrollModule,
    ContentLoaderModule,
    VimeModule,
    DropdownDirective,
    OddPipe,
    OddActivePipe,
    FavoritePipe,
    InputComponent,
    InputNumberComponent,
    TextareaComponent,
    HeaderComponent,
    FooterComponent,
    AlertComponent,
    BetSlipComponent,
    BestSellerComponent,
    StreamTrackerComponent,
    SidebarLiveCalendarComponent,
    SidebarTopLeaguesComponent,
    SidebarFavoritesComponent,
    SidebarPrematchComponent,
    SidebarCardCasinoComponent,
    SidebarCardSportsComponent,
    CasinoGameSuitComponent,
    CasinoMenuComponent,
    LoginComponent,
    RegisterComponent,
    SlideMenuComponent,
    FeaturedEventsComponent,
    LiveHighlightsComponent,
    MobileNavComponent,
    CasinoPlaySuitComponent,
    OddsGroupComponent,
    StatScoreComponent,
    GlobalModalComponent,
    LiveSupportDirective,
    ResetPasswordComponent,
    OddLoaderPipe,
    SvgLoaderEventviewComponent,
    LiveTvComponent,
    SvgLoaderCasinoComponent,
    StandingsComponent,
    InfoModalComponent,
    CasinoGameListComponent,
    PlaytechCasinoComponent,
    PlaytechLiveCasinoComponent
  ],
  providers: [
    { provide: MatBottomSheetRef, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} }
  ]
})
export class SharedModule { }
