import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ApiService, JwtService } from 'src/app/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mybets',
  templateUrl: './mybets.component.html',
  styleUrls: ['./mybets.component.scss']
})
export class MybetsComponent implements OnInit {

  @Output() sendDataToParent = new EventEmitter<any>();
  list: any;
  subList: any;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private jwtService: JwtService
  ) { }

  ngOnInit() {
    this.jwtService.isLoginSubject.subscribe(
      isLogin => {
        if (isLogin) {
          this.apiService.post('betstacks/list', {}, 'mybetsModal').subscribe(
            r => {
              this.list = r;
            }
          );
        }
      }
    );
  }

  onDetailToggle(id: any) {
    const temp = this.list;

    for (let index = 0; index < temp.length; index++) {
      const element = temp[index];
      if (Number(element.id) === Number(id)) {
        temp[index].subOpen = true;
      } else {
        temp[index].subOpen = false;
      }
    }

    this.apiService.post('betstacks/detail/' + id, {}, 'mybetsModal').subscribe(
      r => {
        if (r && r.betCards) {
          this.subList = r.betCards;
          this.list = temp;
        }
      }
    );
  }

  redirectFullList(){
    this.sendDataToParent.emit();
    setTimeout(() => {
      this.router.navigate(['/dashboard/history/bets']);
    }, 300);
  }

  getColorClass(status: any) {
    if (status === 1) {
      return 'color-warning';
    } else if (status === 4) {
      return 'color-primary';
    } else if (status === 2 || status === 5) {
      return 'color-danger';
    }
  }

}
