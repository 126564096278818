import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { JwtService } from './jwt.service';

@Injectable({
  providedIn: 'root'
})
export class OddtypeService {

  private defaultOddType: number = 2;
  oddSubject = new BehaviorSubject<number>(this.defaultOddType);
  oddsList: any = [
    { id: 1, type: 'FRACTIONAL' },
    { id: 2, type: 'DECIMAL' },
    { id: 4, type: 'AMERICAN' },
  ];

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService
  ) {
    if (localStorage.getItem('oddType')) {
      this.oddSubject.next(Number(localStorage.getItem('oddType')));
    }
  }

  init() {
    if (!localStorage.getItem('oddType')) {
      localStorage.setItem('oddType', String(this.defaultOddType));
    } else {
      this.oddSubject.next(Number(localStorage.getItem('oddType')));
    }
  }

  async oddTypeChange(id: number) {
    for (let index = 0; index < this.oddsList.length; index++) {
      const element = this.oddsList[index];
      if (element.id === id) {
        localStorage.setItem('oddType', String(id));
        this.oddSubject.next(id);
        this.jwtService.hasLogin().subscribe(
          hasLogin => {
            if (hasLogin) {
              const newOddType = { oddDisplay: (element.type).toLowerCase() };
              this.apiService.post('user/UpdateOddDisplay', newOddType).subscribe(() => { });
            }
          }
        );
        break;
      }
    }
  }

  selectedOddType() {
    for (let index = 0; index < this.oddsList.length; index++) {
      const element = this.oddsList[index];
      if (element.id === Number(localStorage.getItem('oddType'))) {
        return element;
      }
    }
  }
}
