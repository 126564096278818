import { Component, OnInit } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { CasinoService, StaticService, ApiService } from 'src/app/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { delay } from 'rxjs/operators';

export class CasinoPostObject {
  vendorID: any = '';
  category: any = '';
  type: any = 'tables';
  platform: any = 'PC';
  nextIndex: number = 0;
}

@Component({
  selector: 'app-live-casino-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  featuredGames: any = [];
  popularGames: any = [];
  list: any = [];
  playtechList: any = [];
  category: any[];
  vendors: any[];
  catId: any;
  count: any;
  total: any = 0;
  casinoObject = new CasinoPostObject();
  featuredVendors: any[];
  otherVendors: any[];
  discountList: any[];
  timeReset: any;
  isSearch: boolean = false;
  selectedVendor: any = '';
  private featuredList: any = {
    'BetGames': 1,
    'NetEnt': 2,
    'EvolutionGaming': 3,
    'PragmaticPlay': 4
  };

  constructor(
    private casinoService: CasinoService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private staticService: StaticService,
    private platform: Platform,
    private http: HttpClient,
    private apiService: ApiService
  ) {
    if (this.platform.ANDROID) {
      this.casinoObject.platform = 'Android';
    } else if (this.platform.IOS) {
      this.casinoObject.platform = 'iPhone';
    } else if (this.platform.SAFARI) {
      this.casinoObject.platform = 'iPad';
    } else {
      this.casinoObject.platform = 'PC';
    }
  }

  ngOnInit() {
    // this.getPlaytechList();
    // this.getFeaturedGames();
    // this.getNoDiscount();
    // this.getVendors();
    //this.getGames();
    combineLatest([this.route.queryParams]).subscribe(
      r => {
        if (typeof r[0].k !== 'undefined' && r[0].k.length >= 3) {
          clearTimeout(this.timeReset);
          this.timeReset = setTimeout(() => {
            this.search(r[0].k);
          }, 1000);
        } else if (typeof r[0].k === 'undefined') {
          this.isSearch = false;
          //this.getGames();
        }
      }
    );
  }

  search(key: string) {
    const data = this.casinoObject;
    data['search'] = key;
    this.casinoService.post('search', data, 'casinoModal')
      .pipe(
        delay(500),
      )
      .subscribe(
        res => {
          this.isSearch = true;
          this.list = res['data'];
        }
      );
  }

  getGames(reset?: any, loaderId: any = 'casinoModal') {
    //this.isSearch = false;
    if (this.total >= this.casinoObject.nextIndex) {
      const data = this.casinoObject;
      this.casinoService.post('games', data, loaderId)
        .pipe(
          delay(500),
        )
        .subscribe(
          res => {
            res.data = this.hourZone(res.data);
            reset ? this.list = res.data : this.list = [...this.list, ...res.data];
            const cats = [];
            this.translateService.get('All').subscribe(
              l => {
                cats.push({
                  id: '',
                  label: l
                });
              }
            );

            for (let index = 0; index < res.categories.length; index++) {
              const item = res.categories[index];
              this.translateService.get(String(item)).subscribe(
                l => {
                  cats.push({
                    id: item,
                    label: l
                  });
                }
              );
            }
            this.category = cats;
            this.count = this.list.length;
            this.casinoObject.nextIndex = res.nextIndex;
            this.total = res.total;
          }
        );
    }
  }

  getVendors() {
    const query = {};
    query['type'] = 'tables';
    this.casinoService.post('vendors', query)
      .pipe(
        delay(500),
      )
      .subscribe(
        res => {
          let vendors = [];
          const otherVendors = [],
            featuredVendors = [];
          for (let index = 0; index < res.length; index++) {
            const item = res[index];
            this.translateService.get(String(item.id)).subscribe(
              l => {
                item.langText = l;
              }
            );
            if (this.featuredList[item.id]) {
              item.pr = this.featuredList[item.id];
              featuredVendors.push(item);
            } else {
              otherVendors.push(item);
            }
          }
          this.translateService.get('All').subscribe(
            l => {
              vendors.push({
                id: 'All',
                langText: l
              });
            }
          );

          vendors.push({
            id: 'Playtech',
            langText: 'Playtech',
            data: {
              vendorID: '-2',
              name: 'Playtech',
              presentation: {
                icon: {
                  '*': './assets/img/casinovendors/playtech.png'
                }
              }
            }
          });
          vendors = [...vendors, ...res];
          this.vendors = vendors;
          this.otherVendors = otherVendors;
          this.featuredVendors = featuredVendors;
        }
      );
  }

  changeCategory(id: any) {
    this.isSearch = false;
    this.casinoObject.nextIndex = 0;
    this.casinoObject.category = id;
    this.getGames(true);
  }

  changeVendor(id: any) {
    this.isSearch = false;
    this.selectedVendor = id;
    if (id !== '-2') {
      this.casinoObject.nextIndex = 0;
      this.casinoObject.category = '';
      this.casinoObject.vendorID = id;
      this.getGames(true);
    }
  }


  getFeaturedGames() {
    this.http.get('assets/img/casino/featured.json?' + Date.now()).subscribe(
      (r: any) => {
        const data = r.slice(0, 3);
        this.featuredGames[0] = {};
        this.featuredGames[0].cls = 'col-xl-4 col-lg-4 col-md-4 large px-1';
        this.featuredGames[0].data = data;
        const data1 = r.slice(3, 9);
        this.featuredGames[1] = {};
        this.featuredGames[1].cls = 'col-xl-2 col-lg-2 col-md-4 small px-1';
        this.featuredGames[1].data = data1;
        const data2 = r.slice(9);
        this.popularGames[0] = {};
        this.popularGames[0].cls = 'col-xl-2 col-lg-2 col-md-4 mid px-1';
        this.popularGames[0].data = data2;
      }
    );
  }

  getNoDiscount() {
    const params = new HttpParams({ fromObject: { file: 'live_casino_no_discount_list' } });
    this.staticService.get('getJson', params).subscribe(
      (r: any) => {
        if (r && r.status && r.data) {
          this.discountList = r.data;
        }
      }
    );
  }

  hourZone(data: any) {
    const d = new Date();
    const n = (d.getTimezoneOffset() / 60);


    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const element = data[key].data;
        if (typeof element.openingTime !== 'undefined' && element.openingTime.is24HoursOpen === false) {
          let startTime: any = Number(Number(element.openingTime.startTime.split(':')[0]) - n);
          let endTime: any = Number(Number(element.openingTime.endTime.split(':')[0]) - n);

          if (startTime >= 24) {
            startTime = Number(startTime - 24);
          } else if (startTime < 0) {
            startTime = Number(startTime + 24);
          }

          if (endTime >= 24) {
            endTime = Number(endTime - 24);
          } else if (endTime < 0) {
            endTime = Number(endTime + 24);
          }

          if (startTime < 10) {
            startTime = '0' + startTime;
          }

          if (endTime < 10) {
            endTime = '0' + endTime;
          }
          data[key].data.openingTime.startTime2 = startTime + ':' + element.openingTime.startTime.split(':')[1];
          data[key].data.openingTime.endTime2 = ' - ' + endTime + ':' + element.openingTime.endTime.split(':')[1];
        } else {
          data[key].data.openingTime.startTime2 = '7/24';
          data[key].data.openingTime.endTime2 = '';
        }
      }
    }

    return data;
  }


  getPlaytechList() {
    this.apiService.post('casino/GetLiveCasinoList').subscribe(
      r => {
        this.playtechList = r;
      }
    )
  }
}
