import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-poker-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  userName: any;
  Password: any;
  data: any;
  response: any;

  constructor(
    private apiService: ApiService,
    private router: Router
  ) { }

  ngOnInit() {
    this.apiService.post('PokerV2/GGPokerGetUser').subscribe(
      r => {
        this.data = r;
        this.userName = r.username;
      }
    );
  }

  register() {
    this.apiService.post('PokerV2/GGPokerRegisterUser', { password: this.Password }, 'pokerReg').subscribe(
      r => {
        this.response = r;
        if (r.status) {
          setTimeout(() => {
            this.router.navigate(['/poker']);
          }, 2000);
        }
      }
    )
  }

}
