import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from 'src/app/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-live-betting-event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.scss']
})
export class EventViewComponent implements OnInit, OnDestroy {

  data: any = [];
  markets: any = [];
  moreMarkets: any = [];
  fullMarkets: any = [];
  selectedMarket: any;
  active: any = 'all';
  homeName: string;
  awayName: string;
  time: any;
  indexTrackFn = (index: number) => index;
  window: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private translate: TranslateService
  ) {
    combineLatest([this.route.params]).subscribe(
      r => {
        this.selectedMarket = null;
        this.active = 'all';
        const id = r[0]['id'];
        clearInterval(this.time);
        this.getOdds(id);
        this.time = setInterval(() => {
          this.getOdds(id, false);
        }, 3000);
      }
    );
  }

  ngOnInit() { }

  ngOnDestroy() {
    clearInterval(this.time);
  }

  getOdds(id: any, loaderId: any = 'eventViewComp') {
    this.apiService.post('LiveBetsV2/EventV2/' + id, {}, loaderId).subscribe(
      res => {
        if (res.eventId === 0) { this.router.navigate(['/livebetting/dashboard/']); }
        if (res.oddSets && res.oddSets.length > 0) {
          const markets = [];
          for (let index = 0; index < res.oddSets.length; index++) {
            const element = res.oddSets[index];
            const oddGroup = element.competitionEventOddGroup;
            if (element.oddTypeGroupName === 'SCORE') {
              for (let i = 0; i < element.competitionEventOddGroup.length; i++) {
                const el = element.competitionEventOddGroup[i];
                if (el.typeId === '332') {
                  res.oddSets[index].competitionEventOddGroup[i] = this.correctScore(el, res.title);
                }
              }
            } else if (oddGroup.length > 1) {

              const newGroups = {};
              for (let i = 0; i < oddGroup.length; i++) {
                const e = oddGroup[i];

                if (!newGroups[e.title]) {
                  newGroups[e.title] = {};
                  newGroups[e.title]['typeId'] = e.typeId;
                  newGroups[e.title]['title'] = String(e.title.split('(' + e.specialBetValue + ')')[0]).trim();
                  newGroups[e.title]['odds'] = [];
                  if (e['odds'].length === 1) {
                    newGroups[e.title]['clsName'] = 12;
                  } else if (e['odds'].length === 2) {
                    newGroups[e.title]['clsName'] = 6;
                  } else {
                    newGroups[e.title]['clsName'] = 4;
                  }
                }
                newGroups[e.title]['odds'] = [...newGroups[e.title]['odds'], ...e['odds']];

              }

              res.oddSets[index].competitionEventOddGroup = [];
              for (const key in newGroups) {
                if (newGroups.hasOwnProperty(key)) {
                  const n = newGroups[key];
                  res.oddSets[index].competitionEventOddGroup.push(n);
                }
              }
            }

            if (element.oddTypeGroupName !== '') {
              this.translate.get(element.oddTypeGroupName).subscribe((r: string) => {
                element.oddTypeGroupName = r;
                markets.push({
                  oddTypeGroupIndex: element.oddTypeGroupIndex,
                  oddTypeGroupName: element.oddTypeGroupName
                });
              });
            } else {
              markets.push({
                oddTypeGroupIndex: element.oddTypeGroupIndex,
                oddTypeGroupName: element.oddTypeGroupName
              });
            }
          }
          this.data = res;
          this.fullMarkets = markets;
          this.markets = markets.slice(0, 4);
          this.moreMarkets = markets.slice(4);
          this.homeName = res.title.split('-')[0];
          this.awayName = res.title.split('-')[1];
        }else{
          this.data = res;
        }
      }
    );
  }

  correctScore(temp: any, title: string) {
    const item = {
      home: title.split('-')[0],
      away: title.split('-')[1],
      homeMax: 0,
      awayMax: 0,
      scores: {}
    };

    for (let index = 0; index < temp.odds.length; index++) {
      const element = temp.odds[index];
      const scrs = element.title.split(':');
      if (typeof scrs[0] !== 'undefined' && scrs[1] !== 'undefined') {
        if (item.homeMax < Number(scrs[0])) {
          item.homeMax = Number(scrs[0]);
        }
        if (item.awayMax < Number(scrs[1])) {
          item.awayMax = Number(scrs[1]);
        }

        item.scores[element.title] = element;
      }
    }
    return Object.assign({}, temp, item);
  }

}
