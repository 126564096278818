import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { LangService } from 'src/app/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-home-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  data: any = [];
  defaultLang: any = 'en';
  ind: boolean = false;
  activeSlide: any = '0';
  customOptions: OwlOptions = {
    autoplay: true,
    autoplayTimeout: 15000,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 1500,
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    }
  };

  constructor(
    private http: HttpClient,
    private langService: LangService
  ) {
    this.defaultLang = this.langService.currentLang();
  }

  ngOnInit() {
    // this.http.get('assets/img/slider/home.json?' + Date.now()).subscribe(
    //   r => {

    //     const data = [];

    //     for (let index = 0; index < Object.keys(r).length; index++) {
    //       const element = r[index];
    //       element.id = String(index);
    //       data.push(element);
    //     }
    //     setTimeout(() => {
    //       this.data = data;
    //       this.ind = true;
    //     }, 1000);
    //   }
    // );
  }

  slideChange(event: any) {
    this.activeSlide = Number(event.startPosition);
  }
}
