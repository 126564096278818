import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SearchComponent } from './search.component';
import { ResultComponent } from './result/result.component';


const routes: Routes = [{
  path: '',
  component: SearchComponent,
  children: [
    {
      path: '',
      redirectTo: 'result',
      pathMatch: 'full'
    }, {
      path: 'result',
      component: ResultComponent
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SearchRoutingModule { }
