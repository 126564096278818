export const resultCategories = [
  {
    id: 1,
    name: 'Football',
    data: {}
  },
  {
    id: 4,
    name: 'Basketball',
    data: {}
  },
  {
    id: 3,
    name: 'Tennis',
    data: {}
  },
  {
    id: 10,
    name: 'Handball',
    data: {}
  },
  {
    id: 18,
    name: 'Volleyball',
    data: {}
  },
  {
    id: 14,
    name: 'American Football',
    data: {}
  },
  {
    id: 9,
    name: 'Rugby',
    data: {}
  },
  {
    id: 19,
    name: 'Snooker',
    data: {}
  },
  {
    id: 23,
    name: 'Dart',
    data: {}
  },
  {
    id: 2,
    name: 'Ice Hockey',
    data: {}
  },
  {
    id: 20,
    name: 'Badminton',
    data: {}
  },
  {
    id: 16,
    name: 'Table Tennis',
    data: {}
  }
];
