import { Component, OnInit } from '@angular/core';
import { FavoriteService } from 'src/app/core';

@Component({
  selector: 'app-sidebar-favorites',
  templateUrl: './sidebar-favorites.component.html',
  styleUrls: ['./sidebar-favorites.component.scss']
})
export class SidebarFavoritesComponent implements OnInit {

  data: any;
  teams: any;

  constructor(
    private favoriteService: FavoriteService
  ) {
    this.favoriteService.isFavoriteChange().subscribe(
      _ => {
        this.getFavorites();
      }
    );
  }

  ngOnInit() { }

  getFavorites() {
    this.favoriteService.getFavorite('comp').subscribe(
      r => {
        this.data = r;
      }
    );
    this.favoriteService.getFavorite('team').subscribe(
      r => {
        this.teams = r;
      }
    );
  }

  remove(id: number, type: string) {
    this.favoriteService.removeFavorite(id, type);
  }

}
