export class Confirm {
  Confirmed: boolean = false;
  EmailOptIn: boolean = true;
  SmsOptIn: boolean = true;
}

export class Personal {
  Gender: string = 'M';
  City: any = 'none';
  HouseNumberName: any = 'none';
  StreetAddress: any = 'none';
  PostalCode: any = '00000';
  Name: string = '';
  Surname: string = '';
  NCID: any = '';
  Mobile: any = '';
  Country: number = 236;
  TimeZone: string = 'Europe/Istanbul';
  DateOfBirth: any = '';
  EMail: string = '';
  EMail2: string = '';
}

export class Account {
  UserName: string = '';
  Password: string = '';
  Password2: string = '';
  SecurityAnswer: string = '';
  SecurityQuestion: number;
  BettingCurrency: number = 1;
  BettingCurrencySign: string = 'TL';
}

export class AffiliateDetails {
  Tag: string = '';
  LandingPageId: number = 0;
}

export class RegisterRequestData {
  mode: number = 1;
  target: number = 0;
  confirm = new Confirm();
  personal = new Personal();
  account = new Account();
  BonusTrackingCode: string = '';
  PromoCode: string = '';
  AffiliateDetails = new AffiliateDetails();
}
