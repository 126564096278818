import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GlobalModalService } from 'src/app/core';

@Component({
  selector: 'app-live-betting',
  templateUrl: './live-betting.component.html',
  styleUrls: ['./live-betting.component.scss']
})
export class LiveBettingComponent implements OnInit, AfterViewInit {

  navMenu: any;
  activeCat: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private globalModalService: GlobalModalService
  ) {
    this.mblNav();
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.mblNav();
      });
  }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.globalModalService.messageControl('modal_livebetting');
  }

  mblNav() {
    this.route.data
      .subscribe(
        r => {
          this.navMenu = r;
        }
      );
  }

}
