import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-live-betting-live-match-stream',
  templateUrl: './live-match-stream.component.html',
  styleUrls: ['./live-match-stream.component.scss']
})
export class LiveMatchStreamComponent implements OnInit {

  categories = ['', 'Soccer', 'Basketball', 'Baseball', 'Ice Hockey', 'Tennis', 'Handball', 'Golf', 'Motorsport', 'Rugby', 'Aussie Rules'];
  selectedCategory: number = 1;
  results: any;

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.getLiveStreamingSchedule();
  }

  getLiveStreamingSchedule() {
    const data = {
      sportTypeId: 0,
      startDate: formatDate(Date.now(), 'yyyy-MM-dd', 'en-US', 'UTC') + ' 00:00:00',
      endDate: formatDate(Date.now(), 'yyyy-MM-dd', 'en-US', 'UTC') + ' 23:59:59'
    };

    this.apiService.post('User/GetLiveStreamingSchedule', data, 'livematchstreamLDR', true).subscribe(
      r => {

        const newdata = [];
        const temp = [];
        for (const key in r) {
          if (Object.prototype.hasOwnProperty.call(r, key)) {
            const element = r[key];
            if (typeof newdata[element.betradarSportTypeId] === 'undefined') {
              newdata[element.betradarSportTypeId] = {};
              newdata[element.betradarSportTypeId].name = this.categories[element.betradarSportTypeId];
              newdata[element.betradarSportTypeId].data = [];
            }
            newdata[element.betradarSportTypeId].data.push(element);
          }
        }

        for (const key in newdata) {
          if (Object.prototype.hasOwnProperty.call(newdata, key)) {
            const item = newdata[key];
            temp.push(item);
          }
        }

        this.results = temp;
      }
    );

  }

}
