import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {

  favoriteSubject = new BehaviorSubject<boolean>(true);
  favoriteList = new BehaviorSubject<any>({});
  activeFavList: any = [];

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService,
    private storage: StorageMap
  ) {
    // this.storage.has('favorites').subscribe(
    //   isHas => {
    //     if (!isHas) {
    //       this.init();
    //     }
    //   }
    // );
  }

  init() {
    // const favObj = {};
    // favObj['team'] = [];
    // favObj['comp'] = [];
    // favObj['liveTeam'] = [];
    // favObj['liveComp'] = [];
    // this.favoriteList.next(favObj);
    // this.storage.set('favorites', favObj).subscribe(() => {
    //   setTimeout(async () => {
    //     this.jwtService.isLoggedIn().subscribe(
    //       hasLogin => {
    //         if (hasLogin) {
    //           this.loginControl();
    //         }
    //       }
    //     )
    //   }, 3000);
    // });
  }

  isFavoriteChange(): Observable<boolean> {
    return this.favoriteSubject.asObservable();
  }

  favorites(): Observable<any> {
    return this.favoriteList.asObservable();
  }

  setFavorite(item: any, type: string) {
    this.storage.get('favorites').subscribe(
      async r => {
        if (typeof r !== 'undefined' && typeof r[type] !== 'undefined') {
          r[type].push(item);
          this.storage.set('favorites', r).subscribe(() => {
            const hasLogin = this.jwtService.isLoginSubject.value;
            if (hasLogin) {
              this.apiService.post('favorites/UpdateUsersDetailsFavorites', { favorites: JSON.stringify(r) }).subscribe(_ => { });
            }
          });
          this.favoriteSubject.next(true);
        }
      }
    );
  }

  removeFavorite(id: number, type: string) {
    this.storage.get('favorites').subscribe(
      async r => {
        if (typeof r !== 'undefined' && typeof r[type] !== 'undefined') {
          const newtemp = [];
          for (let index = 0; index < r[type].length; index++) {
            const element = r[type][index];
            if (element.id) {
              if (element.id !== id) {
                newtemp.push(element);
              }
            } else {
              if (element !== id) {
                newtemp.push(element);
              }
            }
          }
          r[type] = newtemp;
          this.storage.set('favorites', r).subscribe(() => {
            const hasLogin = this.jwtService.isLoginSubject.value;
            if (hasLogin) {
              this.apiService.post('favorites/UpdateUsersDetailsFavorites', { favorites: JSON.stringify(r) }).subscribe(_ => { });
            }
          });
          this.favoriteSubject.next(true);
        }
      }
    );
  }

  getFavorite(type: string) {
    return new Observable(observer => {
      this.storage.get('favorites').subscribe(
        async r => {
          if (typeof r !== 'undefined' && typeof r[type] !== 'undefined') {
            await this.addList(type, r[type]);
            observer.next(r[type]);
            observer.complete();
          } else {
            observer.next(false);
            observer.complete();
          }
        }
      );
    });
  }

  loginControl() {
    this.storage.get('favorites').subscribe(
      (localList: any) => {
        if (typeof localList !== 'undefined') {
          this.apiService.post('Favorites/GetUsersDetailsFavorites').subscribe(
            r => {
              if (r._apiResultCode && r._apiResultCode === 2) {
                const remoteList = JSON.parse(r._apiResultMessage);
                if (typeof remoteList['team'] !== 'undefined') {
                  for (const key in remoteList) {
                    if (Object.prototype.hasOwnProperty.call(remoteList, key)) {
                      const type = remoteList[key];
                      for (const key2 in type) {
                        if (Object.prototype.hasOwnProperty.call(type, key2)) {
                          const fav = type[key2];
                          if (localList[key].length > 0 && localList[key].find(item => item.id !== fav.id)) {
                            localList[key].push(fav);
                          }else{
                            localList[key].push(fav);
                          }
                        }
                      }
                    }
                  }
                }
                this.storage.set('favorites', localList).subscribe(() => {
                  this.favoriteSubject.next(true);
                  this.apiService.post('favorites/UpdateUsersDetailsFavorites', { favorites: JSON.stringify(localList) })
                    .subscribe(_ => { });
                });
              }
            }
          )
        }
      }
    );
  }

  addList(type: string, data: any) {
    const temp = [];
    for (let index = 0; index < data.length; index++) {
      const item = data[index];
      if (item.id) {
        temp['_' + item.id] = 1;
      } else {
        temp['_' + item] = 1;
      }
    }
    const list = this.favoriteList.value;
    list[type] = temp;
    this.activeFavList = list;
    this.favoriteList.next(list);
  }
}
