import { Component, OnInit, EventEmitter, Output, OnDestroy, Inject } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { filter, map } from 'rxjs/operators';
import { JwtService, LangService, OddtypeService, BetCardService, UserService, menu as topMenu, ApiService } from 'src/app/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { environment } from '../../../../environments/environment';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { DatafeedService } from 'src/app/core/services/datafeed.service';
import { PlaytechService } from 'src/app/core/services/playtech.service';
import { ControlContainer } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@AutoUnsubscribe()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Output() sendDataToParent = new EventEmitter<any>();
  isLoggedIn: Observable<boolean>;
  isStart: any = false;
  navMenu: any = [];
  searchModel: string;
  searchPlaceHolder: string = 'Search';

  list = topMenu;

  selectedLang: any;
  langs: any[];

  selectedOdd: any;
  oddsList: any[];
  userInfo: any;
  userBalance: any;
  timeOne: any;
  timeTwo: any;

  constructor(
    private oddTypeService: OddtypeService,
    private langService: LangService,
    private route: ActivatedRoute,
    private router: Router,
    public ngxSmartModalService: NgxSmartModalService,
    private jwtService: JwtService,
    private apiService: ApiService,
    private betCardService: BetCardService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private storage: StorageMap,
    private dataFeed: DatafeedService,
    private userService: UserService) {
    this.isLoggedIn = this.jwtService.isLoggedIn();
    this.langs = this.langService.langs;
    this.selectedOdd = this.oddTypeService.selectedOddType();

    const selectedOdd = this.oddTypeService.selectedOddType();
    if (selectedOdd) {
      this.translateService.get(selectedOdd.type).subscribe(
        l => {
          this.selectedOdd = { id: selectedOdd.id, type: l };
        }
      );
    }

    this.oddsList = this.oddTypeService.oddsList;
    this.langService.selectedLang().subscribe(
      r => {
        this.selectedLang = r;
      }
    );
  }

  language: string;
  pokerUrl: any;
  errorMsg: string;
  casinoLobiUrl: string = "www.google.com";

  qToken: string;
  qUserCode: string;
  qLanguage: string;
  currentUrl: string;

  ngOnInit() {
    this.jwtService.isLoggedIn().subscribe(
      isLogin => {
        clearTimeout(this.timeOne);
        clearInterval(this.timeTwo);
        if (isLogin) {
          this.timeOne = setTimeout(() => {
            this.getUserInfo();
            this.getUserBalance();
          }, 1000);
          this.timeTwo = setInterval(() => {
            this.userService.updateBalance().subscribe(() => this.getUserBalance());
          }, 5000);
        }
      }
    );
  }

  OnNavigate() {
    const url = 'https://www.betorder.com';
    window.open(url, '_blank');
  }

  ngOnDestroy() { }

  getUserInfo() {
    this.storage.get('userInfo').subscribe(
      r => {
        if (r && r['user']) {
          this.userInfo = r['user'];
        }
      }
    );
  }

  ChangePokerStatus() {
    this.dataFeed.isPlayPoker.next(false);
    this.router.navigate(['/home']);
  }

  getUserBalance() {
    this.storage.get('balance').subscribe(
      r => {
        this.userBalance = r;
      }
    );
  }

  langChange(lang: any) {
    this.langService.langChange(lang);
  }

  oddTypeChange(id: number) {
    this.oddTypeService.oddTypeChange(id);
  }

  logout() {
    this.jwtService.logout();
  }

  searchControl(key: string) {
    if (this.route.firstChild.snapshot.url[0].path === 'casino' || this.route.firstChild.snapshot.url[0].path === 'livecasino') {
      if (key && key.length > 0) {
        this.router.navigate([], { queryParams: { k: key } });
      } else {
        this.router.navigate([], { queryParams: { k: null } });
      }
    } else {
      if (key.length >= 3 && !this.route.firstChild.snapshot.queryParams.k) {
        this.router.navigate(['/search/result'], { queryParams: { k: key } });
      } else if (key.length >= 3) {
        this.router.navigate([], { queryParams: { k: key } });
      }
    }
  }

  isShowMenu(item: any) {
    if (!item.showType) {
      return true;
    } else if (this.isLoggedIn && item.showType && item.showType === 'VIP' && this.userInfo && this.userInfo.isVip) {
      return true;
    } else {
      return false;
    }
  }
}