
export const menu = [{
  title: 'Sports',
  url: '/sportsbook',
  icon: 'bet-tennis'
}, {
  title: 'In-Play',
  url: '/livebetting',
  icon: 'bet-soccer-shoe'
}, {
  title: 'Slot',
  url: '/casino',
  icon: 'bet-slot-machine'
}, {
  title: 'Casino',
  url: '/livecasino',
  icon: 'bet-cherry'
}, {
  title: 'Virtual Bet',
  url: '/virtualbet',
  icon: 'bet-gamepad'
}, {
  title: 'Live Games',
  url: '/livegames',
  icon:'bet-cards-double'
}, {
  title: 'Poker',
  url: '/poker',
  icon: 'bet-cards-double'
}, {
  title: 'Bingo',
  url: '/bingo',
  icon: 'bet-cards-double'
}, {
  title: 'Bonuses',
  url: '/promotions',
  icon: 'bet-gift'
}];
