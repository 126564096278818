import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  private validator: ValidatorFn;
  private isValid: boolean;
  private val: any;

  constructor() { }

  validate(type): ValidatorFn {
    return (c: AbstractControl) => {
      this.val = this.validTypes()[type];
      this.isValid = this.val ? this.val.test(c.value) : true;
      if (this.isValid) {
        return null;
      } else {
        return {
          type: {
            valid: false
          }
        };
      }
    };
  }

  validTypes() {
    return {
      'email': /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    };
  }
}
