import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ApiService } from 'src/app/core';
import { Platform } from '@angular/cdk/platform';

export class FormObject {
  transactionType: any = 'D';
  amount: any;
  username: string;
}

@Component({
  selector: 'app-poker-cashier',
  templateUrl: './cashier.component.html',
  styleUrls: ['./cashier.component.scss']
})
export class CashierComponent implements OnInit {

  @Output() sendDataToParent = new EventEmitter<any>();

  data: any;
  reqData = new FormObject();
  resData: any;

  constructor(
    public platform: Platform,
    private apiService: ApiService
    ) { }

  ngOnInit() {}

  send() {
    this.apiService.post('PokerV2/GGPokerTransferfunds', this.reqData, 'pokerModal').subscribe(
      r => {
        this.resData = r;
        if (r.status) {
          this.sendDataToParent.emit();
        }
      }
    );
  }


  resetForm() {
    this.resData = null;
    this.reqData = new FormObject();
  }

}
