import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-live-betting-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {


  constructor() { }

  ngOnInit() {}

}
