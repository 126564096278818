import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-promotions-container",
  templateUrl: "./container.component.html",
  styleUrls: ["./container.component.scss"]
})
export class ContainerComponent implements OnInit {
  data = [
    {
      url: "assets/img/sports-1.jpg",
      category: "SPORTS",
      title: "World Cup Live Cashback",
      content: "Bonus on Multiple Bets."
    },
    {
      url: "assets/img/sports-1.jpg",
      category: "SPORTS",
      title: "World Cup Live Cashback",
      content: "Bonus on Multiple Bets."
    },
    {
      url: "assets/img/sports-1.jpg",
      category: "SPORTS",
      title: "World Cup Live Cashback",
      content: "Bonus on Multiple Bets."
    }
  ];
  constructor() {}

  ngOnInit() {}
}
