import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { GlobalModalComponent } from 'src/app/shared/global-modal/global-modal.component';
import { StaticService } from 'src/app/core/services/static.service';
import { Platform } from '@angular/cdk/platform';
import { NgxSmartModalService } from 'ngx-smart-modal';
import * as hash from 'object-hash';

@Injectable({
  providedIn: 'root'
})
export class GlobalModalService {

  constructor(
    private staticService: StaticService,
    private platform: Platform,
    public ngxSmartModalService: NgxSmartModalService
  ) { }

  messageControl(fileName: any, isFile: boolean = true) {
    // if (isFile) {
    //   const params = new HttpParams({ fromObject: { file: fileName } });
    //   this.staticService.get('getJson', params).subscribe(
    //     (r: any) => {
    //       if (r && r.status && r.data) {
    //         if (!localStorage.getItem(hash(r.data))) {
    //           this.alertMdl(r.data);
    //         }
    //       }
    //     }
    //   );
    // } else if (fileName) {
    //   this.alertMdl(fileName);
    // }
  }

  private alertMdl(data: any) {
    const options: any = {
      escapable: false,
      dismissable: false,
      customClass: !this.platform.BLINK ? 'app-modal' : 'app-modal animated zoomIn faster'
    };
    this.ngxSmartModalService.create('globalModal', GlobalModalComponent, options).removeData().setData(data).open();
  }
}