import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { BetCardService, OddtypeService, JwtService, ApiService } from 'src/app/core';
import { diff } from 'deep-object-diff';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-bet-slip',
  templateUrl: './bet-slip.component.html',
  styleUrls: ['./bet-slip.component.scss']
})
export class BetSlipComponent implements OnInit, OnDestroy, AfterViewInit {

  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  betCardBackup: any;
  betCard: any;
  singlesAmount: any;
  errorMsg: string;
  highlight: boolean = true;
  timer: any;
  timeOut: any;
  oddType: any;
  placeResult: any;
  placeSuccesTime: any;
  isRateChange: boolean = false;
  isAllowAllRateChanges: boolean = false;
  userBonusList: any = [];
  userBonusLoader: boolean = false;
  selectedBonus: any = null;
  indexTrackFn = (index: number) => index;

  constructor(
    public betCardService: BetCardService,
    private apiService: ApiService,
    private oddTypeService: OddtypeService,
    private ngxSmartModalService: NgxSmartModalService,
    public jwtService: JwtService,
    private bottomSheetRef: MatBottomSheetRef<BetSlipComponent>
  ) {
    this.getBetStack();
  }

  ngOnInit() {
    this.jwtService.isLoginSubject.subscribe(
      isLogin => {
        this.betCardService.getBetCard().subscribe(() => { });
        if (isLogin) {
          this.getUserFreeBonusList();
        }
      }
    );

    this.oddTypeService.oddSubject.subscribe(
      r => {
        this.oddType = r;
      }
    );
    this.betCardService.isBetCardRefresh().subscribe(
      r => {
        if (r) {
          this.getBetStack();
        } else {
          this.getBetStack(false);
        }
      }
    );

    this.betCardService.activeClassList().subscribe(
      (r: Array<any>) => {
        if (r.length > 0) {
          clearTimeout(this.placeSuccesTime);
          this.placeResult = null;
        }
      }
    );

    this.betCardDataInterval();
  }

  ngAfterViewInit() {
    this.onClickTabSelect();
  }

  ngOnDestroy() {
    this.clearBetCardInterval();
  }

  betCardDataInterval() {
    this.clearBetCardInterval();
    this.timer = setInterval(() => {
      if (this.betCard && this.betCard.betList && this.betCard.betList.length > 0) {
        this.betCardService.getBetCard(undefined, false)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(() => { });
      }
    }, 5000);
  }

  clearBetCardInterval() {
    this.ngUnsubscribe.next();
    clearInterval(this.timer);
    this.ngUnsubscribe.complete();
  }

  placeBet() {
    this.jwtService.hasLogin().subscribe(
      hasLogin => {
        if (!hasLogin) {
          this.betCardService.isBottomSheetOpen.subscribe(
            isOpen => {
              if (isOpen) {
                this.closeMobile();
                this.ngxSmartModalService.getModal('loginModal').setData('betcard').open();
              } else {
                this.ngxSmartModalService.getModal('loginModal').open();
              }
            }
          ).unsubscribe();
        } else {
          const type =
            this.betCard.systems.betCardCount > 0 ? 'systems' : this.betCard.combos.betCardCount > 0 ? 'combos' : 'singles';
          const betlist = {
            betList: this.betCard.betList,
            allowRateChanges: this.isAllowAllRateChanges
          };
          this.betCardService.placeBet(type, betlist).subscribe(
            r => {
              this.placeResult = r;
              if (r.result) {
                this.clear();
                clearTimeout(this.placeSuccesTime);
                this.placeSuccesTime = setTimeout(() => {
                  this.placeResult = null;
                }, 20000);
              }
            }
          );
        }
      }
    )
  }

  acceptChanges() {
    this.jwtService.hasLogin().subscribe(
      hasLogin => {
        if (!hasLogin) {
          this.betCardService.isBottomSheetOpen.subscribe(
            isOpen => {
              if (isOpen) {
                this.closeMobile();
                this.ngxSmartModalService.getModal('loginModal').setData('betcard').open();
              } else {
                this.ngxSmartModalService.getModal('loginModal').open();
              }
            }
          ).unsubscribe();
        } else {
          this.clearBetCardInterval();
          const temp = this.betCard.betList;
          const betRateList = {
            betRateList: []
          };
          for (let index = 0; index < temp.length; index++) {
            const element = temp[index];
            betRateList.betRateList.push({
              bseId: element.bseId,
              rate: element.currentRate
            });
          };

          this.betCardService.acceptChanges(betRateList).subscribe(() => this.betCardDataInterval());
        }
      }
    )
  }

  setSingle() {
    clearInterval(this.timer);
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      const items = {};
      const list = this.betCard.betList;
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        items[element.bseId] = Number(element.betAmountNumeric);
      }
      this.betCardService.setSingleStake(items)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => { this.betCardDataInterval() });
    }, 1000);
  }

  setSinglesStakesAll() {
    clearInterval(this.timer);
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.betCardService.setSinglesStakesAll(Number(this.singlesAmount))
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => { this.betCardDataInterval() });
    }, 1000);
  }

  setComboOptionStake(item: any) {
    clearInterval(this.timer);
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.betCardService.setComboOptionStake(item.type, Number(item.betAmountNumeric))
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => { this.betCardDataInterval() });
    }, 1000);
  }

  setSelectedSystemOption(item: any) {
    clearInterval(this.timer);
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.betCardService.setSystem(item.type, Number(item.betAmount))
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => { this.betCardDataInterval() });
    }, 1000);
  }

  setGroupOptionStake(item: any, groupId: any) {
    clearInterval(this.timer);
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.betCardService.setGroupOptionStake(item.type, Number(item.betAmountNumeric), groupId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          () => { this.betCardDataInterval() }
        );
    }, 1000);
  }

  setBonusOption(item: any, section?: any) {
    clearInterval(this.timer);
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.betCardService.setBonusOption(item.transactionId, section)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          () => { this.betCardDataInterval() }
        );
    }, 0);
  }

  reOrderGroup(item: any, list: any, type: string) {
    const temp = [];
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (element.groupId === item.groupId) {
        for (let index = 0; index < element.betList.length; index++) {
          const item = element.betList[index];
          temp.push(item.bseId);
        }
        break;
      }
    }
    let temp2 = [];
    if (type === 'up' && Number(temp.length) > 0 && Number(item.index) !== 0) {
      temp2 = this.arrayMove(temp, Number(item.index), Number(item.index) - 1);
    } else if (type === 'down' && Number(temp.length) > 0 && Number(temp.length - 1) > Number(item.index)) {
      temp2 = this.arrayMove(temp, Number(item.index), Number(item.index) + 1);
    }
    if (temp2.length > 0) {
      clearInterval(this.timer);
      clearTimeout(this.timeOut);
      this.betCardService.reOrderGroup(Number(item.groupId), temp2)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => { this.betCardDataInterval() });
    }
  }

  toggleEachWayBet(id: number, status: boolean) {
    clearInterval(this.timer);
    clearTimeout(this.timeOut);
    this.betCardService.toggleEachWayBet(Number(id), status)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => { this.betCardDataInterval() });
  }

  getBetStack(isLoader?: boolean) {
    this.betCardService.getBetCardData(isLoader).subscribe(
      (r: any) => {
        if (this.betCardBackup) {
          const df: any = diff(r, this.betCardBackup);
          if (Object.keys(df).length > 0) {
            this.betCard = r;
            this.betCardBackup = r;
          }
        } else {
          this.betCard = r;
          this.betCardBackup = r;
        }
      }
    );
  }

  toggleBetStack(id: any, status: boolean) {
    this.placeResult = null;
    this.betCardService.toggleBetStack(id, status)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => { });
  }

  removeOdd(id: any) {
    this.betCardService.removeOdd(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        r => {
          if (r.result) {
            this.betCard = r.betStack;
          } else {
            this.errorMsg = r.message;
          }
        }
      );
  }

  clear() {
    this.betCardService.clear()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        r => {
          if (r.result) {
            this.betCard = r.betStack;
          } else {
            this.errorMsg = r.message;
          }
        }
      );
  }

  clearBonus() {
    this.betCardService.clearBonusOption()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        r => {
          if (r.result) {
            this.betCard = r.betStack;
            this.selectedBonus = null;
          } else {
            this.errorMsg = r.message;
          }
        }
      );
  }

  rateChange(list: any) {
    if (!this.isAllowAllRateChanges) {
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        if (element.rateAction !== '-' && element.rateAction !== null) {
          this.isRateChange = true;
          return;
        }
      }
      this.isRateChange = false;
    }
  }

  arrayMove(arr: any, from: number, to: number) {
    const cutOut = arr.splice(from, 1)[0];
    arr.splice(to, 0, cutOut);
    return arr;
  }

  closeMobile() {
    if (this.bottomSheetRef.instance) {
      this.bottomSheetRef.dismiss();
      this.bottomSheetRef.afterDismissed().subscribe(
        () => {
          this.betCardService.isBottomSheetOpen.next(false);
        }
      );
    }
  }

  async login() {
    this.jwtService.hasLogin().subscribe(
      isLogin => {
        if (!isLogin) {
          if (this.bottomSheetRef.instance) {
            this.closeMobile();
            setTimeout(_ => this.ngxSmartModalService.open('loginModal'), 300);
          } else {
            this.ngxSmartModalService.open('loginModal');
          }
        }
      }
    );
  }

  getUserFreeBonusList() {
    this.selectedBonus = null;
    this.apiService.post('bonus/GetUsersFreeBetList').subscribe(
      r => {
        if (r && r.status) {
          this.userBonusList = r.bonusList;

          if (this.betCard.bonusOptions && typeof this.betCard.bonusOptions[0] !== 'undefined') {
            for (let index = 0; index < r.bonusList.length; index++) {
              const element = r.bonusList[index];
              if (Number(element.bonusId) === Number(this.betCard.bonusOptions[0].bonusId)) {
                this.selectedBonus = element;
              }
            }
          }
        }
      }
    );
  }

  allowedRateChangesButton(event: boolean) {
    if (event) {
      this.isRateChange = false;
    }
  }

  doSomethingOnTabSelect(event: any) {
    if (event.classList.contains('disabled')) {
      this.login();
    }
  }

  onClickTabSelect() {
    document.getElementsByTagName('app-bet-slip')[0].getElementsByClassName('nav-tab')[1].setAttribute('id', 'app-bet-slip-mybets');
    setTimeout(_ => document.getElementById('app-bet-slip-mybets').onclick = () => {
      this.login();
    }, 100);
  }
}
