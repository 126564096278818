import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CasinoService, JwtService, ApiService, CasinoSocketService } from 'src/app/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CasinoPlaySuitComponent } from 'src/app/shared/casino-play-suit/casino-play-suit.component';

@Component({
  selector: 'app-playtech-live-casino',
  templateUrl: './playtech-live-casino.component.html',
  styleUrls: ['./playtech-live-casino.component.scss']
})
export class PlaytechLiveCasinoComponent implements OnInit {

  @Input() dataList: any = [];
  classList: string = 'col-xl-3 col-lg-3 col-md-4';
  animateClass: string = 'zoomIn';
  suitUrl: string = 'livecasino/suit';
  sampleData: object = {
    'data': {
      'id': '-2',
      'slug': '',
      'playMode': {
        'fun': true,
        'anonymity': true,
        'realMoney': true
      },
      'presentation': {
        'gameName': {
          '*': ''
        },
        'shortName': {
          '*': ''
        },
        'backgroundImage': {
          '*': ''
        }
      }
    }
  };

  lobbyNames: any = ['roulette', 'baccarat', 'blackjack'];
  lobbyArr: any = [];

  constructor(
    private breakpointObserver: BreakpointObserver,
    public jwtService: JwtService,
    private ngxSmartModalService: NgxSmartModalService,
    private casinoService: CasinoService,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private apiService: ApiService,
    private casinoSocketService: CasinoSocketService
  ) {

    console.log(this.dataList);

    this.casinoSocketService.connect();
    this.jwtService.isLoggedIn().subscribe(
      isLogin => {
        if (isLogin) {
          this.getLobbys();
        }
      }
    )
  }

  ngOnInit(): void {
    this.casinoSocketService.onMessage('playtech_live').subscribe(
      (r: any) => {
        for (const key in this.dataList) {
          if (Object.prototype.hasOwnProperty.call(this.dataList, key)) {
            const element = this.dataList[key];
            if (r.eventType === 'OnlinePlayersEvent') {
              if (element.id === r.gameTableId) {
                setTimeout(() => {
                  element.playerCount = r.playerCount;
                }, 500)
              }
            }
          }
        }
      }
    )
  }

  getLobbys() {
    this.apiService.post('casino/GetLiveLobbyLinks').subscribe(
      r => {
        for (const item of r) {
          this.lobbyArr[item.LobbyName] = item.LobbyUrl;
        }
      }
    )
  }

  play(item: any) {
    this.jwtService.isLoggedIn().subscribe(
      isLogin => {
        if (!isLogin) {
          this.ngxSmartModalService.getModal('loginModal').open();
        } else {
          this.sampleData['ImsGameType'] = item.imsGameType;
          this.sampleData['data']['slug'] = item.launchAlias.find(element => element.search('direct_launch_') === -1);
          sessionStorage.setItem('casinoGameData', JSON.stringify(this.sampleData));
          this.run();
        }
      }
    ).unsubscribe();
  }

  getLobby(item: string) {
    this.jwtService.isLoggedIn().subscribe(
      isLogin => {
        if (!isLogin) {
          this.ngxSmartModalService.getModal('loginModal').open();
        } else {
          this.sampleData['data']['slug'] = '#lobby';
          this.sampleData['data']['url'] = this.lobbyArr[item];
          sessionStorage.setItem('casinoGameData', JSON.stringify(this.sampleData));
          this.run();
        }
      }
    ).unsubscribe();
  }

  run() {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.bottomSheet.open(CasinoPlaySuitComponent,
          { panelClass: 'bottom-sheet-casino', disableClose: true, closeOnNavigation: false })
          .afterOpened().subscribe(
            () => {
              this.casinoService.fastPlayOpen.next(true);
            }
          );
      } else {
        this.router.navigate(['/' + this.suitUrl]);
      }
    }).unsubscribe();
  }

}
