import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/core';
import { NgForm } from '@angular/forms';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-poker-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  @ViewChild('pokerPassFrm') form: NgForm;
  password: any;
  resData: any;

  constructor(
    public platform: Platform,
    private apiService: ApiService
  ) { }

  ngOnInit() {
  }

  send() {
    this.apiService.post('PokerV2/ResetPassword', { password: this.password }, 'pokerPassModal').subscribe(
      r => {
        this.resData = r;
      }
    );
  }

  resetForm() {
    this.form.reset();
    this.resData = null;
  }

}
