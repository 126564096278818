import { Component, OnInit, Input, AfterViewInit, OnDestroy, Renderer2, SimpleChanges, OnChanges } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { BetCardService, OddtypeService, FavoriteService } from 'src/app/core';
import { combineLatest } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { diff } from 'deep-object-diff';

@Component({
  selector: 'app-live-highlights',
  templateUrl: './live-highlights.component.html',
  styleUrls: ['./live-highlights.component.scss']
})
export class LiveHighlightsComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @Input() list: any;
  @Input() layout: any;
  @Input() eventFilter: boolean = false;
  @Input() categoryFilter: boolean = false;
  @Input() footerShow: boolean = false;
  @Input() title: string = '';
  @Input() flag: string;
  @Input() state: any;
  @Input() tableClass: any = 'large';
  @Input() columnLimit: any = 3;
  @Input() showFavIcon: boolean = false;
  @Input() leftBadge: boolean = false;
  @Input() menuList: any = [];

  selectedCarId = 1;
  expanded: boolean = false;
  menuTitle: boolean = false;
  oddType: any;
  filters: any = [];
  icon: string = 'bet-goalrss';
  private backup: any = [];
  private filtersBackup: any = [];
  indexTrackFn = (index: number) => index;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private render: Renderer2,
    private breakpointObserver: BreakpointObserver,
    public betCardService: BetCardService,
    private oddTypeService: OddtypeService,
    private favoriteService: FavoriteService
  ) {
    this.breakPoints();
    this.oddTypeService.oddSubject.subscribe(
      r => {
        this.oddType = r;
      }
    );
  }

  ngOnInit() {
    combineLatest([this.route.queryParams]).subscribe(
      r => {
        if (Number(r[0]['c']) === 0) {
          this.icon = 'bet-' + this.state;
        } else if (r[0]['ls']) {
          this.icon = 'bet-' + r[0]['ls'];
        }
        this.filters = [];
        this.filtersBackup = [];
        this.backup = [];
      }
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.expanded = true;
    }, 0);
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.list && changes.list.firstChange === false) {
      if (this.filtersBackup.length > 0 && this.list['events'] && this.list['events'].length > 0) {
        const df: any = diff(this.list['events'][0]['columns'], this.filtersBackup);
        if (df.length > 0) {
          this.filters = this.list['events'][0]['columns'];
          this.filtersBackup = this.list['events'][0]['columns'];
        }
      } else if (this.list['events'] && this.list['events'].length > 0) {
        this.filters = this.list['events'][0]['columns'];
        this.filtersBackup = this.list['events'][0]['columns'];
      }

      if (this.backup['filters']) {
        this.list['filters'] = this.backup['filters'];
      } else {
        this.backup = this.list;
      }
    }
  }

  breakPoints() {
    this.breakpointObserver.observe([
      Breakpoints.Small,
      Breakpoints.XSmall
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.menuTitle = true;
      } else {
        this.menuTitle = false;
      }
    });
  }

  getFilterObject(temp: any, event: any) {
    for (let index = 0; index < temp.length; index++) {
      const element = temp[index];
      if (element.oddTypeId === event.oddTypeId) {
        event.index = index;
        break;
      }
    }
    return event;
  }

  toggleOdd(id: any) {
    this.betCardService.toggleOdd(id).subscribe(() => { });
  }

  ngOnDestroy() { }

  filterSet(index: any) {
    if (!this.filters[index]) {
      this.filters[index] = {};
      this.filters[index].isOpen = false;
    }
  }

  selectedMarket(markets, oddTypeId: number) {
    if (typeof markets !== 'undefined' && markets.length > 0) {
      for (let index = 0; index < markets.length; index++) {
        const element = markets[index];
        if (element.oddTypeId === oddTypeId && element.odds && element.odds.length > 0) {
          return element.odds;
        }
      }
    }
    return false;
  }

  getTracker(exid: number, id: number) {
    this.router.navigate([], { queryParams: { t: exid, eid: id }, queryParamsHandling: 'merge' });
  }

  toggleCompFav() {
    const temp2 = this.list;
    const compId = temp2.events[0].competitionId;
    if (this.favoriteService.activeFavList['liveComp'] && this.favoriteService.activeFavList['liveComp']['_' + compId]) {
      this.favoriteService.removeFavorite(compId, 'liveComp');
    } else {
      this.favoriteService.setFavorite(compId, 'liveComp');
    }
  }

}
