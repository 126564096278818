import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageMap } from '@ngx-pwa/local-storage';
import { HttpCacheManager } from '@ngneat/cashew';
import { registerLocaleData } from '@angular/common';
import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import localeTr from '@angular/common/locales/tr';
import localeEn from '@angular/common/locales/en';
/*import localeRu from '@angular/common/locales/ru';
import localeIt from '@angular/common/locales/it';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import localeEl from '@angular/common/locales/el';
import localeZh from '@angular/common/locales/zh';
import localeAz from '@angular/common/locales/az';*/

@Injectable({
  providedIn: 'root'
})
export class LangService {

  private defaultLang: any = 'en';
  private cookieLang: any;
  langSubject = new BehaviorSubject<boolean>(this.defaultLang);
  langs: any = [
    { id: 1, lang: 'ENGLISH', flag: 'assets/img/flags/united-kingdom.svg', code: 'en' },
    { id: 2, lang: 'TÜRKÇE', flag: 'assets/img/flags/turkey.svg', code: 'tr' }/*,
    { id: 3, lang: 'РОССИЯ', flag: 'assets/img/flags/russia.svg', code: 'ru' },
    { id: 4, lang: 'ITALIANO', flag: 'assets/img/flags/italy.svg', code: 'it' },
    { id: 5, lang: 'FRANÇAIS', flag: 'assets/img/flags/france.svg', code: 'fr' },
    { id: 6, lang: 'DEUTSCH', flag: 'assets/img/flags/germany.svg', code: 'de' },
    { id: 7, lang: 'ESPAÑOL', flag: 'assets/img/flags/spain.svg', code: 'es' },
    { id: 7, lang: 'PORTUGUêS', flag: 'assets/img/flags/portugal.svg', code: 'pt' },
    { id: 8, lang: 'ΕΛΛΗΝΙΚΆ', flag: 'assets/img/flags/greece.svg', code: 'el' },
    { id: 9, lang: '中国', flag: 'assets/img/flags/china.svg', code: 'zh' },
    { id: 10, lang: 'SİSTEM', flag: 'assets/img/flags/international.svg', code: 'az' } */
  ];

  private langsCode: any = ['tr', 'en'/*, 'ru', 'it', 'fr', 'de', 'es', 'pt', 'el', 'zh', 'az'*/];
  private matchRegex: any = new RegExp(String(this.langsCode.join('|')).trim());


  constructor(
    private translate: TranslateService,
    private storage: StorageMap,
    private httpCacheManager: HttpCacheManager,
    private apiService: ApiService,
    private jwtService: JwtService
  ) {
  }

  init() {
    return new Observable(observer => {
      setTimeout(async () => {
        if (await this.storage.has('lang').toPromise()) {
          this.cookieLang = await this.storage.get('lang').toPromise();
          if (this.currentLang() !== this.cookieLang) {
            this.translate.use(this.cookieLang);
          }
        } else {
          this.translate.addLangs(this.langsCode);
          this.translate.setDefaultLang(this.defaultLang);
          this.cookieLang = await this.storage.has('lang').toPromise();

          if (!this.cookieLang) {
            this.cookieLang = this.defaultLang;
            await this.storage.set('lang', String(this.cookieLang)).toPromise();
          }

          if (this.currentLang() !== this.cookieLang) {
            this.translate.use(this.cookieLang);
          }
        }
        observer.next(true);
        observer.complete();
      }, 0);
    });
  }

  selectedLang() {
    return new Observable(observer => {
      setTimeout(async () => {
        const lang = await this.storage.get('lang').toPromise();
        for (let index = 0; index < this.langs.length; index++) {
          const element = this.langs[index];
          if (String(element['code']) === String(lang)) {
            observer.next(element);
            observer.complete();
          }
        }
        observer.next(this.langs[0]);
        observer.complete();
      }, 0);
    });
  }

  currentLang() {
    return this.translate.currentLang;
  }

  async langChange(lang: any) {
    this.cookieLang = lang['code'].match(this.matchRegex) ? lang['code'] : this.defaultLang;
    await this.storage.set('lang', String(this.cookieLang)).toPromise();
    this.translate.use(this.cookieLang);
    this.langSubject.next(this.cookieLang);
    this.httpCacheManager.delete();

    this.jwtService.hasLogin().subscribe(
      hasLogin => {
        if (hasLogin) {
          const lg = { locale: 'tr-tr' };
          if (this.cookieLang === 'en') {
            lg.locale = 'en-gb';
          }
          this.apiService.post('user/UpdatePreferredLocale', lg).subscribe(() => { });
        }
        window.location.reload();
      },
      err => { window.location.reload() },
      () => { window.location.reload() }
    );
  }

  setLang() {
    switch (this.currentLang()) {
      case 'tr': registerLocaleData(localeTr);
        break;
      /*case 'ru': registerLocaleData(localeRu);
        break;
      case 'it': registerLocaleData(localeIt);
        break;
      case 'fr': registerLocaleData(localeFr);
        break;
      case 'de': registerLocaleData(localeDe);
        break;
      case 'es': registerLocaleData(localeEs);
        break;
      case 'pt': registerLocaleData(localePt);
        break;
      case 'el': registerLocaleData(localeEl);
        break;
      case 'zh': registerLocaleData(localeZh);
        break;
      case 'az': registerLocaleData(localeAz);
        break;*/
      default: registerLocaleData(localeEn);
        break;
    }

    return this.currentLang() || 'en';
  }
}
