import { Component, EventEmitter, Output, Input, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BetSlipComponent } from 'src/app/shared/bet-slip/bet-slip.component';
import { LangService, BetCardService } from 'src/app/core';
import { DatafeedService } from 'src/app/core/services/datafeed.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit {
  @ViewChild('footerSign') footerSign: ElementRef;
  @Output() sendDataToParent = new EventEmitter<any>();
  @Input() hideMobileMenu: boolean;
  selectedLang: any;
  langs: any[];
  betCard: any;
  mobileAnime: boolean = false;

  constructor(
    private langService: LangService,
    private betcardService: BetCardService,
    private bottomSheet: MatBottomSheet,
    private dataFeed: DatafeedService
  ) {
    this.langs = this.langService.langs;
    this.langService.selectedLang().subscribe(
      r => {
        this.selectedLang = r;
      }
    )
  }

  ngOnInit() {

    // this.betcardService.activeClassList().subscribe(() => {
    //   this.betcardService.getBetCardData().subscribe(
    //     (r: any) => {
    //       if (r && r['betList']) {
    //         r.systems.totalRate = r.systems.totalRate.toFixed(2);
    //         r.combos.totalRate = r.combos.totalRate.toFixed(2);
    //         r.singles.totalRate = r.singles.totalRate.toFixed(2);
    //         if (r.betList.length > 0) {
    //           this.mobileAnime = true;
    //         }
    //         setTimeout(() => this.mobileAnime = false, 1000);
    //       }
    //       this.betCard = r;
    //     }
    //   );
    // });

  }

  ngAfterViewInit() {
    setTimeout(() => {
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.src = 'https://cebf8fba-e479-4a50-a78b-35c51c1363cd.curacao-egaming.com/ceg-seal.js';
      // document.body.querySelector('app-footer').appendChild(scriptElement);
    }, 300);
  }

  langChange(lang: any) {
    this.langService.langChange(lang);
  }

  redirectSign() {
    const url = this.footerSign.nativeElement.querySelector('a').getAttribute('href');
    window.open(url, '_blank');
  }

  openBottom() {
    this.bottomSheet.open(BetSlipComponent, { panelClass: 'bottom-sheet-betslip', disableClose: true, closeOnNavigation: false })
      .afterOpened().subscribe(
        () => {
          this.betcardService.isBottomSheetOpen.next(true);
        }
      )
  }
}