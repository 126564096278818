import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Location } from '@angular/common';
import { EventStatsService, FavoriteService } from 'src/app/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-sportsbook-event-widget',
  templateUrl: './event-widget.component.html',
  styleUrls: ['./event-widget.component.scss']
})
export class EventWidgetComponent implements OnInit, OnChanges {

  @Input() eventId: number;
  @Input() exData: any;
  data: any;
  imgUrl: string = environment.event_api_url + 'logo/';

  constructor(
    private location: Location,
    private eventStatsService: EventStatsService,
    private favoriteService: FavoriteService
  ) { }

  back() {
    this.location.back();
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (typeof changes.eventId !== 'undefined' && typeof changes.eventId.currentValue !== 'undefined') {
      this.getMatchForm(this.eventId);
    }
  }

  getMatchForm(eventID: number) {
    const data = {
      eventID: eventID
    };
    this.eventStatsService.post('getStats', data).subscribe(
      r => {
        if (r.status) {
          this.data = r.data;
        }
      }
    );
  }

  toggleCompFav(item: any) {
    if (this.favoriteService.activeFavList['comp'] && this.favoriteService.activeFavList['comp']['_' + item.competitionId]) {
      this.favoriteService.removeFavorite(item.competitionId, 'comp');
    } else {
      const temp = {
        id: item.competitionId,
        competitionId: item.competitionId,
        competitionName: item.competitionName,
        subCategoryName: item.subCategoryName,
        sportId: item.sportId,
        countryIsoCode: item.countryIsoCode
      };
      this.favoriteService.setFavorite(temp, 'comp');
    }
  }

  toggleTeamFav(sportId: number, teamCode: number, name: string, eid: number) {
    if (this.favoriteService.activeFavList['team'] && this.favoriteService.activeFavList['team']['_' + teamCode]) {
      this.favoriteService.removeFavorite(teamCode, 'team');
    } else {
      const temp = {
        id: teamCode,
        eid: eid,
        sportId: sportId,
        teamCode: teamCode,
        teamName: name
      };
      this.favoriteService.setFavorite(temp, 'team');
    }
  }

}
