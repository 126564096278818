import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ApiService } from 'src/app/core';
import { map } from 'rxjs/operators';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-sidebar-live-calendar',
  templateUrl: './sidebar-live-calendar.component.html',
  styleUrls: ['./sidebar-live-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarLiveCalendarComponent implements OnInit {

  @Input() isSidebar: boolean = true;
  leftReset: any = false;
  list: any;
  menuTitle: string = 'Football';
  eventList: any;
  time: number = 24;
  activeCat: number = 1;
  indexTrackFn = (index: number) => index;

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.getCategories();
  }

  leftScrool(event: any) {
    event.scrollLeft = Number(event.scrollLeft - 50);
    if (Number(event.scrollLeft) <= 50) { this.leftReset = false; }
  }

  rightScrool(event: any) {
    event.scrollLeft = Number(event.scrollLeft + 50);
    this.leftReset = true;
  }

  getCategories() {
    const data = {
      IsLive: 1,
      currentOnly: 0
    };
    this.apiService.post('Category/GetCategoriesWithEventCounter', data, false, true)
      .pipe(
        map(data => data.filter(item => item.type === 0 && item.enabled && item.eventCount > 0)),
        map(data => data.sort(
          (a, b) => {
            return a.id < b.id ? -1 : 1;
          }
        ))
      )
      .subscribe(
        r => {
          this.list = r;
          this.getUpcomingEvents(this.time, 1);
        }
      );
  }

  getUpcomingEvents(hour: number, catId: number) {
    const data = {
      CategoryId: catId,
      StartDate: formatDate(Date.now(), 'yyyy-MM-dd HH:mm:ss', 'en-US', 'UTC'),
      EndDate: formatDate(Date.now() + (hour * 3600000), 'yyyy-MM-dd HH:mm:ss', 'en-US', 'UTC'),
      Limit: 0,
    };
    this.apiService.post('Schedule/CategoryUpcomingEventsSimple', data, 'sidebarUpcomingLive', true).subscribe(
      r => {
        this.eventList = r;
      }
    );
  }

  getEventRemainingTime(time: any) {
    const now = new Date();
    const utcNowTimestamp = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
      now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
    const event = new Date(time);
    const utcEventtimestamp = Date.UTC(event.getUTCFullYear(), event.getUTCMonth(), event.getUTCDate(),
      event.getUTCHours(), event.getUTCMinutes(), event.getUTCSeconds(), event.getUTCMilliseconds());


    const remeainingTime = Math.ceil(((+utcEventtimestamp) - (+utcNowTimestamp)) / 60000);

    return remeainingTime;

  }

  getEventRemainingHour(date:any){
    return String(this.getEventRemainingTime(date)/59).split('.')[0];
  }

}
