import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-loader-casino',
  templateUrl: './svg-loader-casino.component.html',
  styleUrls: ['./svg-loader-casino.component.scss']
})
export class SvgLoaderCasinoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
