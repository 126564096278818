import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor(public updates: SwUpdate) {
    if (updates.isEnabled) {
      interval(100000).subscribe(() => updates.checkForUpdate()
        .then(() => console.log('checking for updates')));
    }
  }

  public checkForUpdates(): void {
    this.updates.available.subscribe(() => this.promptUser());
  }

  private promptUser(): void {
    this.updates.activateUpdate().then(async () => {
      try {
        await this.clearCache().then(() => { });
      } catch (error) {
        console.log('cache temizlenemedi');
      } finally {
        window.location.reload();
      }
    });
  }

  public clearCache(): Promise<void[]> {
    return caches.keys().then(cachesNames => {
      return Promise.all(cachesNames.map(cacheName => {
        return caches.delete(cacheName).then(() => { });
      }));
    });
  }
}
