import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CasinoService } from 'src/app/core';

export class CasinoPostObject {
  vendorID: any = '';
  category: any = 'VIRTUALSPORTS';
  type: any = 'games';
  platform: any = 'PC';
  nextIndex: number = 0;
}

@Component({
  selector: 'app-virtual-bet',
  templateUrl: './virtual-bet.component.html',
  styleUrls: ['./virtual-bet.component.scss']
})
export class VirtualBetComponent implements OnInit {

  state: any;
  subList: any = [];
  subListMdl: any = 0;
  casinoObject = new CasinoPostObject();
  list: any = [
    {
      id: 0,
      name: 'Global',
      class: 'road',
      state: 'global'
    }, {
      id: 167,
      name: 'Kiron',
      class: 'magic',
      state: 'kiron',
      subcat: []
    }, {
      id: 135,
      name: 'OneX Game',
      class: 'magnet',
      state: 'onex',
      subcat: []
    },{
      id: 3,
      name: 'Virtual Futbol',
      class: 'soccer-shoe',
      state: 'vfl'
    }, {
      id: 4,
      name: 'Virtual Tennis',
      class: 'tennis',
      state: 'vtl'
    }, {
      id: 5,
      name: 'Virtual Basketbal',
      class: 'basketball',
      state: 'vbl'
    }
  ];

  constructor(
    private casinoService: CasinoService,
    private router: Router,
    public route: ActivatedRoute
  ) {
    this.getSubMenu();
    if (!this.route.snapshot.queryParamMap.get('c')) {
      this.setCat();
    }

    this.route.queryParams.subscribe(
      r =>{
        this.subMenuChange();
      }
    )
  }

  ngOnInit() {
  }

  getSubMenu() {
    this.casinoService.post('games', this.casinoObject, 'sidebarMenuVt').subscribe(
      r => {
        const list = this.list;
        if (r && r.data && r.data.length > 0) {
          for (let index = 0; index < r.data.length; index++) {
            const subitem = r.data[index];
            for (const key in list) {
              if (list.hasOwnProperty(key)) {
                const item = list[key];
                if (subitem.data.vendorID === item.id) {
                  item.subcat.push(subitem);
                }
              }
            }
          }
        }
        this.list = list;
        this.subMenuChange();
      }
    );
  }

  subMenuChange() {
    const cat = this.route.snapshot.queryParamMap.get('oc');
    if (cat === 'kiron' || cat === 'onex') {
      this.state = cat;
      for (const item of this.list) {
        if (item.state === cat) {
          this.subList = item.subcat;
          break;
        }
      }

      if (this.route.snapshot.queryParamMap.get('gid')) {
        this.subListMdl = this.route.snapshot.queryParamMap.get('gid');
      }
    } else {
      this.subList = [];
      this.subListMdl = 0;
    }
  }

  setCat(cat: string = this.list[0].state) {
    if (cat !== 'kiron' && cat !== 'onex') {
      this.router.navigate([], { queryParams: { c: cat } });
    } else {
      this.router.navigate([], { queryParams: { c: this.route.firstChild.snapshot.queryParamMap.get('c'), oc: cat } });
    }
  }

  playGame(id: any, cat: string) {
    let item;
    for (const it of this.subList) {
      if (it.id === Number(id)) {
        item = it;
        break;
      }
    }
    sessionStorage.setItem('casinoGameData', JSON.stringify(item));
    this.router.navigate([], { queryParams: { c: cat, oc: cat, gid: item.data.id } });
  }

}
