import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ApiService } from 'src/app/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { formatDate } from '@angular/common';
import { filter } from 'rxjs/operators';

@AutoUnsubscribe()
@Component({
  selector: 'app-sidebar-top-leagues',
  templateUrl: './sidebar-top-leagues.component.html',
  styleUrls: ['./sidebar-top-leagues.component.scss']
})
export class SidebarTopLeaguesComponent implements OnInit, OnDestroy {

  @Input() todaysLeague: boolean = false;
  @Input() title: string = 'Top Leagues';
  list: any = [];
  sday: any = null;
  sportId: any = 0;

  constructor(
    private apiService: ApiService,
    private router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnDestroy() { }

  ngOnInit() {
    if (this.route.snapshot.params.sportName) {
      this.sportId = this.route.snapshot.params.sportName;
    }
    if (this.route.snapshot.queryParamMap.get('sday')) {
      this.sday = this.route.snapshot.queryParamMap.get('sday');
    }
    this.getData(this.sportId);
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd)
      )
      .subscribe(() => {
        if (this.todaysLeague && (this.sday !== this.route.snapshot.queryParamMap.get('sday') ||
          this.sportId !== this.route.snapshot.params.sportName)) {
          this.sday = this.route.snapshot.queryParamMap.get('sday');
          this.sportId = this.route.snapshot.params.sportName;
          this.getData(this.sportId);
        } else if (!this.todaysLeague && this.route.firstChild !== null && this.route.firstChild.snapshot.params.sportName) {
          this.sportId = this.route.firstChild.snapshot.params.sportName;
          this.getData(this.sportId);
        }
      });
  }

  getData(sportId?: number) {
    sportId = sportId || 1;
    if (!this.todaysLeague) {
      this.apiService.post('Competition/GetTopCompetitions/' + sportId + '/6', {}, 'sidebarTopLeagues').subscribe(
        res => {
          this.list = res;
        }
      );
    } else {
      let startDate = formatDate(Date.now(), 'yyyy-MM-dd', 'en-US', 'UTC');
      let endDate = formatDate(Date.now() + (15 * 86400000), 'yyyy-MM-dd', 'en-US', 'UTC');
      if (this.route.snapshot.queryParamMap.get('sday')) {
        const sday = Number(this.route.snapshot.queryParamMap.get('sday'));
        startDate = formatDate(Date.now() + (sday * 86400000), 'yyyy-MM-dd', 'en-US', 'UTC');
        endDate = formatDate(Date.now() + (sday * 86400000), 'yyyy-MM-dd', 'en-US', 'UTC');
      }

      startDate = startDate + ' 00:00:00';
      endDate = endDate + ' 23:59:59';

      const data = {
        CategoryId: sportId,
        DateStart: startDate,
        DateEnd: endDate,
        Limit: 6,
        IsLive: 0
      };

      this.apiService.post('Competition/GetTopCompetitionsByBetStackEntry', data, 'sidebarTopCompetitions', true).subscribe(
        res => {
          this.list = res;
        }
      );
    }
  }

  setLeague(id: any, catId: any, catName: any, catState: any, countryId: number) {
    let sday = null;

    if (this.route.snapshot.queryParamMap.get('sday')) {
      sday = this.route.snapshot.queryParamMap.get('sday');
    }
    this.router.navigate(['/sportsbook/category/', catId],
      { queryParams: { subcat: countryId, comp: id, name: catName, state: catState, sday: sday } });
  }

}
