import { Pipe, PipeTransform } from '@angular/core';
import { FavoriteService } from 'src/app/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Pipe({
  name: 'favorite'
})
export class FavoritePipe implements PipeTransform {

  constructor(
    private favoriteService: FavoriteService
  ) {
  }

  transform(value: any, ...args: any[]): Observable<boolean> {
    return this.favoriteService.favorites().pipe(
      map(x => {
        if (typeof x !== 'undefined' && typeof x[args[0]] !== 'undefined' && typeof x[args[0]]['_' + value] !== 'undefined') {
          return true;
        } else {
          return false;
        }
      })
    );

  }

}
