import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { VirtualBetComponent } from './virtual-bet.component';
import { EventViewComponent } from './event-view/event-view.component';


const routes: Routes = [{
  path: '',
  component: VirtualBetComponent,
  children: [
    {
      path: '',
      redirectTo: 'eventview',
      pathMatch: 'full'
    },
    {
      path: 'eventview',
      component: EventViewComponent
    }
  ]
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VirtualBetRoutingModule { }
