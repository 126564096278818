import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SportsbookComponent } from './sportsbook.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CategoryBettingOddsComponent } from './category-betting-odds/category-betting-odds.component';
import { EventViewComponent } from './event-view/event-view.component';
import { ResultsComponent } from './results/results.component';

const routes: Routes = [{
  path: '',
  component: SportsbookComponent,
  children: [
    {
      path: '',
      redirectTo: 'dashboard',
      pathMatch: 'full'
    }, {
      path: 'dashboard',
      component: DashboardComponent
    }, {
      path: 'category/:sportName',
      component: CategoryBettingOddsComponent
    }, {
      path: 'eventview/:id',
      component: EventViewComponent
    },{
      path: 'results',
      component: ResultsComponent
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SportsbookRoutingModule { }
