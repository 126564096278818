import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { JwtService } from './jwt.service';
import { StaticService } from './static.service';
import * as hash from 'object-hash';
import { withCache } from '@ngneat/cashew';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  private isResetToken: boolean = false;

  constructor(
    private http: HttpClient,
    private loaderService: NgxUiLoaderService,
    private jwtService: JwtService,
    private staticService: StaticService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  private formatErrors(error: any) {
    if (error.status === 301) {
      if (!this.isResetToken) {
        this.isResetToken = true;
        this.ngUnsubscribe.next();
        this.document.location.href = error.error.Location;
      }
    } else if (error.status === 401 || error.status === 403 || error.status === 405) {
      this.isResetToken = true;
      try {
        this.staticService.post('bugreports', error).subscribe(
          () => { },
          () => { },
          () => {
            this.ngUnsubscribe.next();
          }
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.jwtService.destroyToken();
      }
    } else {
      return throwError(error.message);
    }
  }

  private endLoader(loaderID: any) {
    if (loaderID) {
      if (this.loaderService.hasRunningTask(true, loaderID)) {
        this.loaderService.stopLoader(loaderID);
      }
    }
  }

  private startLoader(loaderID: any) {
    if (loaderID) {
      this.loaderService.startLoader(loaderID);
    }
  }

  private destroyRequest() {
    return new Observable(
      observer => {
        observer.next(false);
        observer.complete();
      }
    );
  }

  get(path: string, params: HttpParams = new HttpParams(), loaderID: any = false): Observable<any> {
    this.startLoader(loaderID);
    if (this.isResetToken) {
      return this.destroyRequest();
    }
    return this.http.get(
      `${environment.api_url}${path}`,
      { params })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        catchError(err => this.formatErrors(err)),
        finalize(() => {
          this.endLoader(loaderID);
        })
      );
  }

  put(path: string, body: Object = {}, loaderID: any = false): Observable<any> {
    this.startLoader(loaderID);
    if (this.isResetToken) {
      return this.destroyRequest();
    }
    return this.http.put(
      `${environment.api_url}${path}`,
      JSON.stringify(body)
    ).pipe(
      takeUntil(this.ngUnsubscribe),
      catchError(err => this.formatErrors(err)),
      finalize(() => {
        this.endLoader(loaderID);
      })
    );
  }

  post(path: string, body: Object = {}, loaderID: any = false, cache: boolean = false): Observable<any> {
    this.startLoader(loaderID);
    if (this.isResetToken) {
      return this.destroyRequest();
    }
    return this.http.post(
      `${environment.api_url}${path}`,
      JSON.stringify(body),
      withCache(
        {
          cache$: cache,
          key$: hash(body),
          ttl$: 300000
        }
      )
    ).pipe(
      takeUntil(this.ngUnsubscribe),
      catchError(err => this.formatErrors(err)),
      finalize(() => {
        this.endLoader(loaderID);
      })
    );
  }

  delete(path: any, loaderID: any = false): Observable<any> {
    this.startLoader(loaderID);
    if (this.isResetToken) {
      return this.destroyRequest();
    }
    return this.http.delete(
      `${environment.api_url}${path}`
    ).pipe(
      takeUntil(this.ngUnsubscribe),
      catchError(err => this.formatErrors(err)),
      finalize(() => {
        this.endLoader(loaderID);
      })
    );
  }
}
