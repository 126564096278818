import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNumberComponent),
      multi: true
    }
  ]
})
export class InputNumberComponent implements ControlValueAccessor {

  @Input() min: number = null;
  @Input() max: number = null;
  @Input() disabled: boolean;
  @Input() required = false;
  @Input() placeholder = '';

  private default: number = Number(1);
  private innerValue: number = Number(this.min) || Number(this.max) || this.default;
  private onTouchedCallback: () => void = () => { };
  private onChangeCallback: (_: any) => void = () => { };

  constructor() { }


  get value(): any {
    console.log('get',this.innerValue);
    return this.innerValue;
  }

  set value(v: any) {
    console.log('set',this.innerValue);
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  countPlus() {
    if (this.max != null && (Number(this.innerValue) + 1) <= this.max) {
      this.innerValue = Number(this.innerValue) + 1;
    } else if (this.max == null && typeof this.innerValue === 'number') {
      this.innerValue = Number(this.innerValue) + 1;
    } else if (typeof this.innerValue !== 'number') {
      this.innerValue = Number(this.min) || Number(this.max) || this.default;
    }
  }

  countMinus() {
    if (this.min != null && (Number(this.innerValue) - 1) >= this.min) {
      this.innerValue = Number(this.innerValue) - 1;
    } else if (this.min == null && typeof this.innerValue === 'number') {
      this.innerValue = Number(this.innerValue) - 1;
    } else if (typeof this.innerValue !== 'number') {
      this.innerValue = Number(this.min) || Number(this.max) || this.default;
    }
  }

  keyUp() {
    this.innerValue = Number(String(this.innerValue).replace(/\D/g, ''));
  }

  focusOut() {
    if ((this.max != null && Number(this.innerValue) > this.max) || (this.min != null && (Number(this.innerValue) - 1) < this.min)) {
      this.innerValue = Math.max(this.innerValue, this.min);
      this.innerValue = Math.min(this.innerValue, this.max);
    }
  }

}
