import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-virtual-bet-event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.scss']
})
export class EventViewComponent implements OnInit {

  playerActive: boolean = false;
  state: any;

  constructor(
    public route: ActivatedRoute,
  ) {
    this.route.queryParams
    .subscribe(p => {
      if (p.c === 'global') {
        this.state = p.state;
        this.playerActive = true;
      } else {
        this.playerActive = false;
      }
    });
  }

  ngOnInit() {
  }

}
