import { Component, OnInit, ViewChild, ElementRef, Renderer2, AfterViewInit, Input, OnDestroy, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { LangService, JwtService, ApiService, CasinoService } from 'src/app/core';
import { Observable } from 'rxjs';
import * as screenfull from 'screenfull';
import { environment } from '../../../environments/environment';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { TabsetComponent } from 'ngx-tabset';
import { Platform } from '@angular/cdk/platform';
import { StaticService } from 'src/app/core';
import { HttpParams } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { InfoModalComponent } from 'src/app/shared/info-modal/info-modal.component';

@AutoUnsubscribe()
@Component({
  selector: 'app-casino-play-suit',
  templateUrl: './casino-play-suit.component.html',
  styleUrls: ['./casino-play-suit.component.scss']
})
export class CasinoPlaySuitComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() isMini: boolean = false;
  @Input() isFastPlay: boolean;
  @Input() onlyPlayer: boolean = false;
  @Input() casinoLobiUrl: string = '/livecasino/overview';
  @Input() slotLobiUrl: string = '/casino/overview';
  @ViewChild('content') content: ElementRef;
  @ViewChild('tabMenu') tabMenu: ElementRef;
  @ViewChild('tabset') tabset: TabsetComponent;
  @ViewChild('fullScreen') fullScreen: ElementRef;
  @ViewChild('gamePlayerReal') gamePlayerReal: ElementRef;
  @ViewChild('gamePlayerDemo') gamePlayerDemo: ElementRef;
  isLoggedIn: Observable<boolean>;
  clock: string = '';
  clockHandle: any;
  gameData: any;
  gameUrl: any;
  defaultLang: any = 'en';
  cookieLang: any;
  selectedLang: any;
  errorMsg: string = '';
  casinoAlert: boolean = false;
  casinoAlertData: any = [];
  customNavClass: string;


  /*carouselTileItems: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 1, 1, 1, 1];
  carouselTileConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 3, md: 5, lg: 8, all: 0 },
    speed: 250,
    point: {
      visible: true
    },
    touch: true,
    loop: true,
    interval: { timing: 1500 },
    animation: 'lazy'
  };*/

  constructor(
    public breakpointObserver: BreakpointObserver,
    private langService: LangService,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    public jwtService: JwtService,
    private apiService: ApiService,
    public casinoService: CasinoService,
    public ngxSmartModalService: NgxSmartModalService,
    private route: ActivatedRoute,
    private router: Router,
    private elem: ElementRef,
    private platform: Platform,
    private staticService: StaticService,
    public deviceService: DeviceDetectorService,
    private bottomSheetRef: MatBottomSheetRef<CasinoPlaySuitComponent>
  ) {
    this.breakPoints();
    this.isLoggedIn = this.jwtService.isLoggedIn();
    this.jwtService.isLoggedIn().subscribe(() => this.playTheGame());
    this.route.queryParams.subscribe(
      (r: any) => {
        if (r['c'] && r['oc'] && r['gid']) {
          this.playTheGame();
        }
      }
    );
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange() {
    this.playTheGame();
  }

  ngOnInit() {
    // this.customNavClass = this.isMini ? 'casino-tabs d-none' : 'casino-tabs';
    // this.clockHandle = setInterval(() => {
    //   this.clock = new Date().toLocaleTimeString();
    // }, 1000);
  }

  ngAfterViewInit() {
    const tab = this.elem.nativeElement.querySelectorAll('.casino-tabs > li')[0];
    if (tab) {
      tab.addEventListener('click', () => {
        if (tab.classList.contains('disabled')) {
          this.casinoService.fastPlayOpen.subscribe(
            isOpen => {
              if (isOpen) {
                this.closeMobile();
                this.ngxSmartModalService.getModal('loginModal').setData('casino').open();
              } else {
                this.ngxSmartModalService.getModal('loginModal').open();
              }
            }
          ).unsubscribe()
        }
      });
    }
    setTimeout(() => {
      this.breakPoints();

      if (screenfull.isEnabled) {
        screenfull.on('change', () => {
          if (!this.isFastPlay) {
            const element = this.tabset['tabs']['first']['active'];
            let type = 'demo';
            if (element) {
              type = 'real';
            }
            let player = this.gamePlayerDemo.nativeElement;
            if (type === 'real') {
              player = this.gamePlayerReal.nativeElement;
            }
            if (!screenfull['isFullscreen']) {
              this.renderer.removeStyle(player, 'height');
            } else {
              const height: any = ((window.outerHeight) - 90) + 'px';
              this.renderer.setStyle(player, 'height', height);
            }
          }
        });
      }

    }, 100);
  }

  ngOnDestroy() { }

  breakPoints() {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.isFastPlay = true;
      }
    }).unsubscribe();
  }

  playTheGame() {
    const gameData = sessionStorage.getItem('casinoGameData');
    if (gameData) {
      this.gameData = JSON.parse(gameData);
      this.gameUrl = null;
      this.errorMsg = '';
      this.jwtService.hasLogin().subscribe(
        isLogin => {
          if (isLogin) {
            if (this.gameData['type'] === 'aviator') {
              this.getAviatorToken(this.gameData['data']['slug']).subscribe(() => { });
            }
            else if (this.gameData['data']['id'] === "-2" && this.gameData['data']['url']) {
              this.getPlaytechLobby(this.gameData['data']['url']);
            }
            else if (this.gameData['data']['id'] === "-2") {
              this.getPlayTechLiveToken().subscribe(() => { });
            }
            else if (this.gameData['data']['id'] === "-1") {
              this.getPlayTechToken().subscribe(() => { });
            } else {
              this.getToken().subscribe(
                r => {
                  if (r['Result']) {
                    this.casinoService.setHistory(this.gameData['id']).subscribe(() => { });
                    this.getRealGame(r['SessionId']);
                  } else {
                    this.errorMsg = r['Message'];
                  }
                }
              );
            }
          } else {
            this.getFunGame();
          }
        }
      )
    } else {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }

  getRealGame(token: string) {
    if (this.gameData['type'] === 'table') {
      this.gameUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(
          environment.casino_player_url + this.gameData['data']['slug'] + '?tableID=' + this.gameData['id'] + '&_sid=' + token + '&language=' + this.langService.currentLang()
        );
    } else {
      this.gameUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(
          environment.casino_player_url + this.gameData['data']['slug'] + '?_sid=' + token + '&language=' + this.langService.currentLang()
        );
    }

    this.casinoMessageControl(String(this.gameData['data']['gameCode']));
  }

  getFunGame() {
    if (this.gameData['data']['id'] === "-1") {
      let gameUrl = 'https://login-mit.onegameslink.com/GameLauncher?gameCodeName=' + this.gameData['data']['slug'] + '&username=Anonymous&casino=aydogan&clientType=casino&clientPlatform=web&language=EN&real=0';
      if (this.deviceService.isMobile()) {
        gameUrl = gameUrl.replace('clientPlatform=web', 'clientPlatform=mobile');
      }
      gameUrl = gameUrl + '&lobbyUrl=' + location.protocol + '//' + location.hostname + '/redirect-lobby' + this.slotLobiUrl;
      this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        gameUrl
      );
    } else {
      this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        environment.casino_player_url + this.gameData['data']['slug'] + '?language=' + this.langService.currentLang()
      );
    }
  }

  getToken() {
    return new Observable(observer => {
      if (sessionStorage.getItem('casinoToken')) {
        const tkn = sessionStorage.getItem('casinoToken')
        this.apiService.post('casinoOps/IsActiveUserSession', { SessionId: tkn }, 'casinoPlayModal').subscribe(
          r => {
            if (r['Result']) {
              observer.next(r);
              observer.complete();
            } else {
              this.apiService.post('casinoOps/Login', {}, 'casinoPlayModal').subscribe(
                l => {
                  sessionStorage.setItem('casinoToken', l.SessionId);
                  observer.next(l);
                  observer.complete();
                }
              );
            }

          }
        );
      } else {
        this.apiService.post('casinoOps/Login', {}, 'casinoPlayModal').subscribe(
          l => {
            sessionStorage.setItem('casinoToken', l.SessionId);
            observer.next(l);
            observer.complete();
          }
        );
      }
    });
  }

  getPlayTechLiveToken() {
    return new Observable(observer => {
      const data = {
        'ImsGameType': this.gameData['ImsGameType'],
        'LaunchAlias': this.gameData['data']['slug']
      }
      this.apiService.post('casino/GetPlaytechLiveCasinoToken', data, 'casinoPlayModal').subscribe(
        r => {
          if (r.result === 'prohibited') {
            this.gameUrl = false;
            this.errorMsg = 'This game is not allowed to play in your country.';
          } else {
            if (this.deviceService.isMobile()) {
              r.result = r.result.replace('clientPlatform=web', 'clientPlatform=mobile');
            }
            const currentHostname = r.result + '&lobbyUrl=' + location.protocol + '//' + location.hostname + '/redirect-lobby' + this.casinoLobiUrl;
            this.gameUrl =
              this.sanitizer.bypassSecurityTrustResourceUrl(
                currentHostname
              );
          }
          this.casinoMessageControl(String(this.gameData['data']['slug']));
          observer.next(r)
        }
      );
    });
  }

  getPlayTechToken() {
    return new Observable(observer => {
      const data = {
        'gameCode': this.gameData['data']['slug']
      }
      this.apiService.post('casino/GetPlayTechCasinoToken', data, 'casinoPlayModal').subscribe(
        r => {
          if (r.result === 'prohibited') {
            this.gameUrl = false;
            this.errorMsg = 'This game is not allowed to play in your country.';
          } else {
            if (this.deviceService.isMobile()) {
              r.result = r.result.replace('clientPlatform=web', 'clientPlatform=mobile');
            }
            const currentHostname = r.result + '&lobbyUrl=' + location.protocol + '//' + location.hostname + '/redirect-lobby' + this.casinoLobiUrl;
            this.gameUrl =
              this.sanitizer.bypassSecurityTrustResourceUrl(
                currentHostname
              );
          }
          this.casinoMessageControl(String(this.gameData['data']['slug']));
          observer.next(r)
        }
      );
    });
  }

  getPlaytechLobby(url: any) {
    this.gameUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(
        url
      );
  }

  getAviatorToken(subBrand: string) {
    return new Observable(observer => {
      this.apiService.post('casino/GetAviatorToken', { SubBrand: subBrand }).subscribe(r => {
        if (r.resultCode === 0) {
          let currentHostname;
          if (subBrand === 'zeppelin') {
            currentHostname = 'http://192.168.3.4:2086/gamelauncher.html?token=' + r.result + '&language=tr';
          } else {
            currentHostname = 'http://192.168.3.4:2082/gamelauncher.html?token=' + r.result + '&language=tr';
          }
          this.gameUrl =
            this.sanitizer.bypassSecurityTrustResourceUrl(
              currentHostname
            );
          observer.next(r.result);
        } else {
          observer.next(false);
        }
      });
    });
  }

  tabControl(event?: any) {
    for (let k = 0; k < this.tabMenu.nativeElement.children.length; k++) {
      if (this.tabMenu.nativeElement.children[k] !== event.target && this.tabMenu.nativeElement.children[k].classList.contains('active')) {
        this.renderer.removeClass(this.tabMenu.nativeElement.children[k], 'active');
      }
    }

    if (event.target.classList.contains('active')) {
      this.renderer.removeClass(this.content.nativeElement, 'active');
      this.renderer.removeClass(event.target, 'active');
      this.renderer.addClass(this.content.nativeElement.children[0], 'd-none');
    } else {
      this.renderer.addClass(this.content.nativeElement, 'active');
      this.renderer.addClass(event.target, 'active');
      this.renderer.removeClass(this.content.nativeElement.children[0], 'd-none');
    }

  }

  casinoMessageControl(gameCode: string) {
    const params = new HttpParams({ fromObject: { file: 'casino_alert' } });
    this.staticService.get('getJson', params, 'InfoContent').subscribe(
      (r: any) => {

        if (r && r.status && r.data && r.data.codes && r.data.codes.length > 0) {
          const codes = r.data.codes.split(',');
          let hasName = false;
          for (const i in codes) {
            if (codes[i] === gameCode) {
              hasName = true;
              break;
            }
          }

          if (hasName) {
            if (!this.isFastPlay) {
              this.alertMdl(r.data);
            } else {
              this.casinoAlert = true;
              this.casinoAlertData = r.data;
            }
          } else {
            this.specialMessage(gameCode);
          }
        } else {
          this.specialMessage(gameCode);
        }
      }
    );

  }

  specialMessage(gameCode: string) {
    const params = new HttpParams({ fromObject: { file: 'casino_games/' + gameCode } });
    this.staticService.get('getJson', params, 'InfoContent').subscribe(
      r => {
        if (r && r.status && r.data) {
          if (!this.isFastPlay) {
            this.alertMdl(r.data);
          } else {
            this.casinoAlert = true;
            this.casinoAlertData = r.data;
          }
        }
      }
    )
  }

  alertMdl(data: any) {
    const options: any = {
      escapable: false,
      dismissable: false,
      customClass: !this.platform.BLINK ? 'app-modal' : 'app-modal animated zoomIn faster'
    };
    this.ngxSmartModalService.create('infoModal', InfoModalComponent, options).removeData().setData(data).open();
  }

  toggleFullScreen() {
    if (this.isFastPlay) {
      if (screenfull.isEnabled) {
        if (screenfull.isFullscreen) {
          screenfull.exit();
        } else {
          screenfull.request(this.fullScreen.nativeElement);
        }
      }
    } else {
      const element = this.tabset['tabs']['first']['active'];
      let type = 'demo';
      if (element) {
        type = 'real';
      }
      let player = this.gamePlayerDemo.nativeElement;
      if (type === 'real') {
        player = this.gamePlayerReal.nativeElement;
      }
      if (screenfull.isEnabled) {
        if (screenfull.isFullscreen) {
          screenfull.exit();
        } else {
          screenfull.request(this.fullScreen.nativeElement);
        }
      }
    }

  }

  tabSelect(event: any) {
    if (event === 0) {
      this.playTheGame();
    } else {
      this.getFunGame();
    }
  }

  closeMobile() {
    this.bottomSheetRef.dismiss();
  }

  log(item) {
    console.log(item['tabs']);
  }
}
