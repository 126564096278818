import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UpdateService } from './update.service';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  public isLoginSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private storage: StorageMap,
    private sw: UpdateService
  ) {
    this.hasLogin().subscribe(
      (r: boolean) => {
        this.isLoginSubject.next(r);
      }
    );
  }

  isLoggedIn(): Observable<boolean> {
    return this.isLoginSubject.asObservable();
  }

  saveToken(token: any) {
    return this.storage.set('token', token);
  }

  async destroyToken() {
    sessionStorage.clear();
    try {
      await this.sw.clearCache().then(() => { });
    } catch (error) {
      console.log('cache temizlenemedi');
    } finally {
      this.storage.clear().subscribe(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    }
  }

  hasLogin() {
    return new Observable(observer => {
      setTimeout(async () => {
        const token = await this.storage.get('token').toPromise();
        if (typeof token !== 'undefined' && token['type'] === 'access') {
          observer.next(true);
        } else {
          observer.next(false);
        }
      }, 0);
    });
  }

  login(token: any) {
    return this.saveToken(token)
      .pipe(
        finalize(() => {
          this.isLoginSubject.next(true);
        })
      );
  }

  logout() {
    this.isLoginSubject.next(false);
    this.destroyToken();
  }

}
