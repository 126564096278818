// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // api_url: 'https://714e364733999e816ed0a5b20dc65163.ncybs.com:8443/apipoker/v1/',
  api_url: 'https://714e364733999e816ed0a5b20dc65163.ncybs.com:8443/apipoker/v1/',
  pt_api_url: 'https://gameslinkpb.lordspoker.com:2053/api/pokerservice/',
  //api_url: 'http://192.168.3.9:8889/api/v1/',
  casino_api_url: 'https://node.ncybs.com/betorder/',
  casino_player_url: '//gamelaunch.everymatrix.com/Loader/Start/1958/',
  event_api_url: 'https://node.ncybs.com/eventstats/',
  virtual_host: 'https://lordspoker.aitcloud.de/',
  static_content: 'assets/static/',
  ga: 'UA-152357208-1',
  onesignal:'6c040121-a63f-470a-abe8-6b288b62446d',
  sitekey: 'lordspoker',
  cdn_url: 'https://cdn.ncybs.com/lp/assets/img/slider',
  redirectUrl: 'https://lordspoker.com/'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.