export const resultFilter = [
  {
    id: 'ALL',
    name: 'All',
    value: null
  },
  {
    id: 'NOT_STARTED',
    name: 'Not Started',
    value: 0
  },
  {
    id: 'IN_PROGRESS',
    name: 'In Progress',
    value: 1
  },
  {
    id: 'PAUSED',
    name: 'Paused',
    value: 2
  },
  {
    id: 'FINISHED',
    name: 'Finished',
    value: 3
  }
];
