import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import {
  AuthGuardService as AuthGuard,
  FirstLoadControlService as FirstLoadGuard,
  LoginModalGuardService as LoginGuard
} from './core';

import { RedirectLobbyComponent } from 'src/app/redirect-lobby/redirect-lobby.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [FirstLoadGuard],
    data: {
      show: false
    }
  }, {
    path: 'sportsbook',
    loadChildren: () => import('./sportsbook/sportsbook.module').then(m => m.SportsbookModule),
    data: {
      title: 'Sports',
      url: '/sportsbook',
      bar: true,
      show: true,
      submenu: [{
        title: 'Sports betting',
        url: '/dashboard',
      },
      {
        title: 'Football',
        url: '/category/1',
        name: 'Football',
        state: 'soccer'
      },
      {
        title: 'Tennis',
        url: '/category/3',
        name: 'Tennis',
        state: 'tennis'
      },
      {
        title: 'Basketball',
        url: '/category/4',
        name: 'Basketball',
        state: 'basketball'
      }, {
        title: 'Results',
        url: '/results',
      }, {
        title: 'Promotions',
        url: '/promotions',
        state: 'sports',
        external: true
      }]
    }
  }, {
    path: 'livebetting',
    loadChildren: () => import('./live-betting/live-betting.module').then(m => m.LiveBettingModule),
    data: {
      title: 'In-Play',
      url: '/livebetting',
      bar: true,
      show: true,
      submenu: [{
        title: 'Live Events',
        url: '/dashboard',
      }, {
        title: 'Upcoming Live Events',
        url: '/schedule',
      }, {
        title: 'Live Event Stream',
        url: '/livestream',
        showType: 'VIP'
      }, {
        title: 'Promotions',
        url: '/promotions',
        state: 'sports',
        external: true
      }
      ]
    }
  }, {
    path: 'casino',
    loadChildren: () => import('./casino/casino.module').then(m => m.CasinoModule),
    data: {
      title: 'Slot',
      url: '/casino',
      show: true,
      bar: true,
      submenu: [{
        title: 'Slot',
        url: '/overview',
      }, {
        title: 'Promotions',
        url: '/promotions',
        state: 'casino',
        external: true
      }
      ]
    }
  }, {
    path: 'livecasino',
    loadChildren: () => import('./live-casino/live-casino.module').then(m => m.LiveCasinoModule),
    data: {
      title: 'Casino',
      url: '/livecasino',
      show: true,
      bar: true,
      submenu: [{
        title: 'Live Casino',
        url: '/overview',
      }, {
        title: 'Promotions',
        url: '/promotions',
        state: 'casino',
        external: true
      }
      ]
    }
  }, {
    path: 'virtualbet',
    loadChildren: () => import('./virtual-bet/virtual-bet.module').then(m => m.VirtualBetModule),
    data: {
      title: 'Virtual Bet',
      show: true
    }
  }, {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
    data: {
      show: false
    }
  }, {
    path: 'promotions',
    loadChildren: () => import('./promotions/promotions.module').then(m => m.PromotionsModule),
    data: {
      title: 'Promotions',
      show: true
    }
  }, {
    path: 'search',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
    data: {
      show: false,
      bar: true
    }
  }, 
  // {
  //   path: 'livegames',
  //   loadChildren: () => import('./live-games/live-games.module').then(m => m.LiveGamesModule),
  //   data: {
  //     show: true
  //   }
  // },
  {
    path: 'poker',
    loadChildren: () => import('./poker/poker.module').then(m => m.PokerModule),
    canActivate: [LoginGuard],
    data: {
      show: true
    }
  }, {
    path: 'bingo',
    loadChildren: () => import('./bingo/bingo.module').then(m => m.BingoModule),
    canActivate: [LoginGuard],
    data: {
      show: true
    }
  },
  {
    path: 'info',
    loadChildren: () => import('./info/info.module').then(m => m.InfoModule),
    data: {
      show: false
    }
  },
  {
    path: 'redirect-lobby/:lobby/:lobby2',
    component: RedirectLobbyComponent,
    data: {
      show: false
    }
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'top',
    relativeLinkResolution: 'legacy',
    useHash : true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }