import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { UpdateService } from './update.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class VersionCheckService {
  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

  constructor(
    private http: HttpClient,
    private sw: UpdateService,
    private translateService: TranslateService) { }


  public initVersionCheck(url: any, frequency = 1000 * 60 * 30) {
    setInterval(() => {
      this.checkVersion(url);
    }, frequency);
  }

  private checkVersion(url: any) {
    this.http.get(url + '?t=' + new Date().getTime())
      .pipe(first())
      .subscribe(
        async (response: any) => {
          const hash = response.hash;
          const hashChanged = this.hasHashChanged(this.currentHash, hash);

          if (hashChanged) {
            this.translateService.get('A new version of the application is available. Would you like to load it?').subscribe(
              async translate => {
                if (confirm(translate)) {
                  this.currentHash = hash;
                  await this.sw.clearCache().then(() => { });
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              }
            );
          }
        },
        (err) => {
          console.error(err, 'Could not get version');
        }
      );
  }

  private hasHashChanged(currentHash: any, newHash: any) {
    if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }

    return currentHash !== newHash;
  }
}
