import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { LangService } from 'src/app/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pokerslider',
  templateUrl: './pokerslider.component.html',
  styleUrls: ['./pokerslider.component.scss']
})
export class PokersliderComponent implements OnInit {

  cdnUrl: string = environment.cdn_url;
  data: any;
  defaultLang: any = 'en';
  customOptions: OwlOptions = {
    autoplay: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    smartSpeed: 1000,
    dotsSpeed: 1000,
    dragEndSpeed: 1000,
    autoplayTimeout: 5000,
    nav: false,
    lazyLoad: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    }
  };

  constructor(
    private http: HttpClient,
    private langService: LangService
  ) {
    this.defaultLang = this.langService.currentLang();
  }

  ngOnInit() {
    this.http.get<any>("https://login.0ef5f5ec2fe4df7759266d11f86d8deb.com:8443/slider").subscribe(
      r => {
        let temp = [];
        if (r != null) {
          for (let index = 0; index < r.length; index++) {
            if (r[index].isShow == undefined || r[index].isShow) {
              if (r[index].startDate == undefined || r[index].endDate == undefined) {
                temp.push(r[index]);
              } else {
                if (new Date(r[index].startDate).getTime() < new Date().getTime() && new Date(r[index].endDate).getTime() > new Date().getTime()) {
                  temp.push(r[index]);
                }
              }
            }
          }
        }
        this.data = temp;
      }
    );

  }

  onError(): void {
    this.defaultLang = 'en';
  }
}