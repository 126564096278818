import { Component, OnInit } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { ApiService, LangService } from 'src/app/core';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-poker-suit',
  templateUrl: './suit.component.html',
  styleUrls: ['./suit.component.scss']
})
export class SuitComponent implements OnInit {

  data: any;
  iframeLink: any;

  constructor(
    private router: Router,
    private platform: Platform,
    private apiService: ApiService,
    public ngxSmartModalService: NgxSmartModalService,
    private sanitizer: DomSanitizer,
    public langService: LangService
  ) {
    this.apiService.post('PokerV2/GGPokerGetUser')
      .pipe(
        map(r => {
          if (r.showRegisterForm) {
            this.router.navigate(['/poker/register']);
          } else {
            return r;
          }
        })
      )
      .subscribe(
        r => {
          this.data = r;
        }
      );
  }

  ngOnInit() {
    this.iframeUrl();
  }

  getData() {
    this.apiService.post('PokerV2/GGPokerGetUser')
      .subscribe(
        r => {
          this.data = r;
          this.ngxSmartModalService.resetModalData('pokerModal');
          this.ngxSmartModalService.setModalData(r, 'pokerModal');
        }
      );
  }

  openWeb() {
    window.open('https://ggpokersite.ggpoker.network/?btag1=' + environment.sitekey, '_blank');
  }

  download() {
    let url = 'http://dlportal.good-game-network.com/installer/win/ggpokersite?btag1=' + environment.sitekey;
    if (this.platform.ANDROID) {
      url = 'https://dlportal.good-game-network.com/mobile/installer/android/ggpokersite?btag1=' + environment.sitekey;
    } else if (this.platform.IOS) {
      url =
        'itms-services://?action=download-manifest&url=https://dlportal.good-game-network.com/mobile/installer/ios/ggpokersite?btag1=' +
        environment.sitekey;
    }
    window.open(url, '_blank');
  }

  iframeUrl(url: string = '') {
    this.iframeLink = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://contents.good-game-network.com/leaflet/ggpokersite'
      + url + '?lang=' + this.langService.currentLang() + '&btag1=' + environment.sitekey
    );
  }

  iframeLoad() {
    window.focus();
  }

}
