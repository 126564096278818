import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { environment } from 'src/environments/environment';

import {
  AnonTokenService,
  ApiService,
  JwtService,
  CasinoService,
  AuthGuardService,
  BetCardService,
  LangService,
  LoginModalGuardService,
  UserService,
  UpdateService,
  VersionCheckService,
  ConfirmService
} from './services';

import { CasinoSocketService } from './socket/casino-socket.service';

import { EventsService } from './helpers';

export function init_app(anonService: AnonTokenService) {
  return (): Promise<any> => {
    return anonService.run();
  };
}

// if (environment.production) {
//   Sentry.init({
//     dsn: 'https://5d9ecc482c4b44a6af443b31d2169d05@o468851.ingest.sentry.io/5497309',
//     environment: environment.production ? 'prod' : 'dev',
//     integrations: [
//       new Integrations.BrowserTracing({
//         tracingOrigins: ['https://714e364733999e816ed0a5b20dc65163.ncybs.com:8443/api/v2'],
//         routingInstrumentation: Sentry.routingInstrumentation
//       }),
//     ],
//     tracesSampleRate: 1.0
//   });
//}


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [AnonTokenService], multi: true },
    ApiService,
    JwtService,
    CasinoService,
    AuthGuardService,
    EventsService,
    BetCardService,
    LangService,
    LoginModalGuardService,
    UserService,
    UpdateService,
    VersionCheckService,
    CasinoSocketService,
    ConfirmService
  ]
})
export class CoreModule { }
