import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ToastrService } from 'ngx-toastr';
import { diff } from 'deep-object-diff';

@Injectable({
  providedIn: 'root'
})
export class BetCardService {

  placeBetSubject = new BehaviorSubject<boolean>(false);
  betCardSubject = new BehaviorSubject<boolean>(true);
  betOdds = new BehaviorSubject<Array<any>>([]);
  loaderOdds = new BehaviorSubject<Array<any>>([]);
  isBottomSheetOpen = new BehaviorSubject<boolean>(false);
  setAmountSubject = new BehaviorSubject<boolean>(false);
  private activeOddList: any = [];

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private storage: StorageMap,
    private toastr: ToastrService
  ) { }

  private setCache(data, snext = true) {
    this.storage.set('betstack/get', data).subscribe(() => {
      this.betCardSubject.next(snext);
      this.activeClassControl(data);
    });
  }

  private activeClassControl(data: any) {
    const temp = [];
    for (let index = 0; index < data.betList.length; index++) {
      const element = String(data.betList[index].eventOddId);
      temp.push(element);
    }
    const df = diff(temp, this.betOdds.value);
    if (Object.keys(df).length > 0) {
      this.betOdds.next(temp);
      this.activeOddList = temp;
    }
  }

  activeClassList(): Observable<Array<any>> {
    return this.betOdds.asObservable();
  }

  isBetCardRefresh(): Observable<boolean> {
    return this.betCardSubject.asObservable();
  }

  isPlaceBetSubject(): Observable<boolean> {
    return this.placeBetSubject.asObservable();
  }

  isSetAmountSubject(): Observable<boolean> {
    return this.setAmountSubject.asObservable();
  }

  activeLoaderOdds(): Observable<Array<any>> {
    return this.loaderOdds.asObservable();
  }

  placeBet(type: string = 'singles', params: any) {
    if (type === 'systems') {
      return this.submitSystem(params);
    } else if (type === 'combos') {
      return this.submitCombo(params);
    } else if (type === 'singles') {
      return this.submitSingles(params);
    }
  }

  submitSystem(params: any) {
    return this.apiService.post('betstack/submitsystems', params, 'betCardLoader')
      .pipe(
        map(
          r => {
            return r;
          }),
        finalize(() => {
          this.userService.updateBalance().subscribe(() => {
            this.placeBetSubject.next(true);
          });
        })
      );
  }

  submitCombo(params: any) {
    return this.apiService.post('betstack/submitcombos', params, 'betCardLoader')
      .pipe(
        map(
          r => {
            return r;
          }),
        finalize(() => {
          this.userService.updateBalance().subscribe(() => {
            this.placeBetSubject.next(true);
          });
        })
      );
  }

  submitSingles(params: any) {
    return this.apiService.post('betstack/submitsingles', params, 'betCardLoader')
      .pipe(
        map(
          r => {
            return r;
          }),
        finalize(() => {
          this.userService.updateBalance().subscribe(() => {
            this.placeBetSubject.next(true);
          });
        })
      );
  }

  acceptChanges(params: any) {
    return new Observable(observer => {
      this.apiService.post('betstack/UpdateRates', params, 'betCardLoader').subscribe(
        u => {
          if (u.result) {
            this.getBetCard(undefined, false).subscribe(() => {
              observer.next(u);
              observer.complete();
            })
          } else {
            this.toastr.error('An error has occurred, try again');
            observer.next(u);
            observer.complete();
          }
        }
      )
    });
  }

  getBetCardData(isLoader?: boolean) {
    return new Observable(observer => {
      this.storage.has('betstack/get').subscribe(
        isHas => {
          if (isHas) {
            this.storage.get('betstack/get').subscribe(
              t => {
                this.activeClassControl(t);
                observer.next(t);
                observer.complete();
              }
            );
          } else {
            this.getBetCard(undefined, isLoader).subscribe(
              r => {
                observer.next(r);
                observer.complete();
              }
            );
          }
        }
      );
    });
  }

  getBetCard(params?, isLoader: boolean = true) {
    const loaderId = isLoader ? 'betCardLoader' : null;
    return this.apiService.post('betstack/get', params, loaderId)
      .pipe(
        map(
          r => {
            this.setCache(r, false);
            return r;
          })
      );
  }

  setSystem(type: string, amount: number) {
    this.setAmountSubject.next(true);
    return new Observable(observer => {
      this.setSelectedSystemOption(type).subscribe(
        r => {
          if (r.result) {
            this.setSystemStake(amount).subscribe(
              t => {
                if (t.result) {
                  this.setCache(t.betStack);
                } else {
                  this.toastr.error(t.message);
                }
                observer.next(t);
                observer.complete();
                this.setAmountSubject.next(false);
              }
            );
          } else {
            this.toastr.error(r.message);
            observer.next(r);
            observer.complete();
            this.setAmountSubject.next(false);
          }
        }
      );
    });
  }

  private setSelectedSystemOption(type: string) {
    const params = {
      type: type
    };
    return this.apiService.post('betstack/SetSelectedSystemOption', params)
      .pipe(
        map(
          r => {
            return r;
          })
      );
  }
  private setSystemStake(amount: number) {
    const params = {
      amount: amount
    };
    return this.apiService.post('betstack/SetSystemStake', params)
      .pipe(
        map(
          r => {
            return r;
          })
      );
  }

  setComboOptionStake(type: string, amount: number) {
    this.setAmountSubject.next(true);
    const params = {
      amount: amount,
      type: type
    };
    return this.apiService.post('betstack/SetComboOptionStake', params)
      .pipe(
        map(
          r => {
            if (r.result) {
              this.setCache(r.betStack);
            } else {
              this.toastr.error(r.message);
            }
            this.setAmountSubject.next(false);
            return r;
          })
      );
  }

  setSinglesStakesAll(amount: number) {
    this.setAmountSubject.next(true);
    const params = {
      amount: amount
    };
    return this.apiService.post('betstack/SetSinglesStakesAll', params)
      .pipe(
        map(
          r => {
            if (r.result) {
              this.setCache(r.betStack);
            } else {
              for (let index = 0; index < r.errors.length; index++) {
                const element = r.errors[index];
                this.toastr.error(element.message);
              }
            }
            this.setAmountSubject.next(false);
            return r;
          })
      );
  }

  setSingleStake(items: any) {
    this.setAmountSubject.next(true);
    const params = {
      items: items
    };
    return this.apiService.post('betstack/SetSinglesStakes', params)
      .pipe(
        map(
          r => {
            if (r.result) {
              this.setCache(r.betStack);
            } else {
              for (let index = 0; index < r.errors.length; index++) {
                const element = r.errors[index];
                this.toastr.error(element.message);
              }
            }
            this.setAmountSubject.next(false);
            return r;
          })
      );
  }

  setGroupOptionStake(type: string, amount: number, groupId: any) {
    this.setAmountSubject.next(true);
    const params = {
      type: type,
      amount: amount,
      groupId: groupId
    };
    return this.apiService.post('betstack/SetGroupOptionStake', params)
      .pipe(
        map(
          r => {
            if (r.result) {
              this.setCache(r.betStack);
            } else {
              for (let index = 0; index < r.errors.length; index++) {
                const element = r.errors[index];
                this.toastr.error(element.message);
              }
            }
            this.setAmountSubject.next(false);
            return r;
          })
      );
  }

  setBonusOption(bonusTxnId: any, section: any = 'combo') {
    const params = {
      bonusTxnId: bonusTxnId,
      section: section
    };
    return this.apiService.post('betstack/setBonusOption', params, 'betCardLoader')
      .pipe(
        map(
          r => {
            if (r.result) {
              this.setCache(r.betStack);
            } else {
              this.toastr.error(r.message);
            }
            return r;
          })
      );
  }

  clearBonusOption(section: any = 'combo') {
    const params = {
      section: section
    };

    return this.apiService.post('betstack/clearBonusOption', params, 'betCardLoader')
      .pipe(
        map(
          r => {
            if (r.result) {
              this.setCache(r.betStack);
            } else {
              this.toastr.error(r.message);
            }
            return r;
          })
      );
  }

  addOdd(id, loaderId = 'betCardLoader') {
    return this.apiService.post('betstack/add/' + id, {}, loaderId)
      .pipe(
        map(
          r => {
            if (r.result) {
              this.setCache(r.betStack);
            } else {
              this.toastr.error(r.message);
            }
            return r;
          })
      );
  }

  toggleBetStack(id: any, status: boolean) {
    const params = {
      bseId: id,
      selected: status
    };
    return this.apiService.post('betstack/ToggleBetStackEntry', params, 'betCardLoader')
      .pipe(
        map(
          r => {
            if (r.result) {
              this.setCache(r.betStack);
            } else {
              this.toastr.error(r.message);
            }
            return r;
          })
      );
  }

  removeOdd(id: any) {
    return this.apiService.post('betstack/remove/' + id, {}, 'betCardLoader')
      .pipe(
        map(
          r => {
            if (r.result) {
              this.setCache(r.betStack);
            } else {
              this.toastr.error(r.message);
            }
            return r;
          })
      );
  }

  clear() {
    return this.apiService.post('betstack/clear', {}, 'betCardLoader')
      .pipe(
        map(
          r => {
            if (r.result) {
              this.setCache(r.betStack);
            } else {
              this.toastr.error(r.message);
            }
            return r;
          })
      );
  }

  toggleOdd(id: any) {
    return new Observable(observer => {
      const loaderOddsList = this.loaderOdds.value;
      if (!loaderOddsList.includes(id)) {
        loaderOddsList.push(id);
        this.loaderOdds.next(loaderOddsList);
      }
      const hasOdd = this.hasOdd(this.activeOddList, id);
      if (hasOdd) {
        this.removeOdd(id)
          .pipe(
            finalize(() => {
              setTimeout(() => {
                const temp = this.loaderOdds.value.filter(e => e !== id);
                this.loaderOdds.next(temp);
              }, 200);
            })
          )
          .subscribe(r => {
            observer.next(r);
            observer.complete();
          });
      } else {
        this.addOdd(id)
          .pipe(
            finalize(() => {
              const temp = this.loaderOdds.value.filter(e => e !== id);
              this.loaderOdds.next(temp);
            })
          )
          .subscribe(r => {
            observer.next(r);
            observer.complete();
          });
      }
    });
  }

  reOrderGroup(groupId: number, items: any) {
    const params = {
      groupId: groupId,
      itemOrder: items
    };
    return this.apiService.post('betstack/reordergroup', params, 'betCardLoader')
      .pipe(
        map(
          r => {
            if (r.result) {
              this.setCache(r.betStack);
            } else {
              for (let index = 0; index < r.errors.length; index++) {
                const element = r.errors[index];
                this.toastr.error(element.message);
              }
            }
            return r;
          })
      );
  }

  toggleEachWayBet(id: number, status: boolean) {
    const params = {
      bseId: id,
      eachWay: status
    };
    return this.apiService.post('betstack/ToggleEachWayBet', params, 'betCardLoader')
      .pipe(
        map(
          r => {
            if (r.result) {
              this.setCache(r.betStack);
            } else {
              for (let index = 0; index < r.errors.length; index++) {
                const element = r.errors[index];
                this.toastr.error(element.message);
              }
            }
            return r;
          })
      );
  }

  private hasOdd(x: any, id: any) {
    for (let index = 0; index < x.length; index++) {
      const element = x[index];
      if (Number(element) === Number(id)) {
        return true;
      }
    }
    return false;
  }

}
