import { Injectable } from '@angular/core';
import { JwtService } from './jwt.service';
import { CanActivate } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginModalGuardService implements CanActivate {

  constructor(
    private jwtService: JwtService,
    private ngxSmartModalService: NgxSmartModalService
  ) { }
  canActivate(): Observable<boolean> {
    return this.jwtService.hasLogin().pipe(
      map(
        r => {
          if (!r) {
            this.ngxSmartModalService.open('loginModal');
            return false;
          }
          return true;
        }
      )
    )
  }
}
