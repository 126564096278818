import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared';

import { PromotionsRoutingModule } from './promotions-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ListComponent } from './list/list.component';
import { ContainerComponent } from './container/container.component';
import { DetailComponent } from './detail/detail.component';
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
  declarations: [
    DashboardComponent,
    ListComponent,
    ContainerComponent,
    DetailComponent,
    SidebarComponent
  ],
  imports: [
    CommonModule,
    PromotionsRoutingModule,
    SharedModule
  ]
})
export class PromotionsModule { }
