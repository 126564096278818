import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared';
import { NgCircleProgressModule } from 'ng-circle-progress';

import { SportsbookRoutingModule } from './sportsbook-routing.module';
import { SportsbookComponent } from './sportsbook.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SliderComponent } from './slider/slider.component';
import { TopLeaguesComponent } from './top-leagues/top-leagues.component';
import { CategoryBettingOddsComponent } from './category-betting-odds/category-betting-odds.component';
import { EventWidgetComponent } from './event-widget/event-widget.component';
import { EventViewComponent } from './event-view/event-view.component';
import { SisRacesComponent } from './sis-races/sis-races.component';
import { ResultsComponent } from './results/results.component';

@NgModule({
  imports: [
    CommonModule,
    SportsbookRoutingModule,
    SharedModule,
    NgCircleProgressModule.forRoot({
      'toFixed': 0,
      'maxPercent': 100,
      'showSubtitle': false,
      'showBackground': false,
      'responsive': true,
      'class': 'event-team-progress',
    })
  ],
  declarations: [
    SportsbookComponent,
    DashboardComponent,
    SliderComponent,
    TopLeaguesComponent,
    CategoryBettingOddsComponent,
    EventWidgetComponent,
    EventViewComponent,
    SisRacesComponent,
    ResultsComponent
  ]
})
export class SportsbookModule { }
