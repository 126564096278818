import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ApiService } from '../../services';
import { ScheduleRequestData } from './events.model';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  data: any;
  private layout: any = [];
  private pager: any = [];
  private totalRow: number = 0;

  constructor(
    private apiService: ApiService
  ) { }

  private formatErrors(error: any) {
    return throwError(error.message);
  }

  getData(params: any, loaderId?: string, type: string = 'date', listType: string = 'comp'): Observable<any> {
    this.data = new ScheduleRequestData();
    const apiUrl = params.isLive ? 'LiveBetsV2/GetLiveBettingScheduleWithMarkets' : 'Schedule/CategoryUpcomingEventsWithMarkets';
    const cache = params.isLive ? false : true;
    delete params.isLive;
    const reqData = Object.assign(this.data, params);
    if (!params['withTime']) {
      reqData.StartDate = params.StartDate + ' 00:00:00';
      reqData.EndDate = params.EndDate + ' 23:59:59';
    }
    delete params.withTime;

    return this.apiService.post(apiUrl, reqData, loaderId, cache).pipe(
      map(
        res => {
          const temp = [];
          for (let index = 0; index < res.length; index++) {
            const element = res[index];
            temp[index] = this.getEventsByCategory(element, type, listType);
          }
          return temp;
        }
      ),
      catchError(err => this.formatErrors(err))
    );
  }

  private getEventsByCategory(category: any, type: string, listType: string) {
    if (category && typeof category['events'] !== 'undefined' && category['events'].length > 0) {

      this.layout[0] = false;
      this.layout[1] = false;
      this.layout[2] = false;
      const layout = category['columnLayout'].split('-');
      for (let index = 0; index < layout.length; index++) {
        this.layout[index] = true;
      }

      this.pager = [];
      this.totalRow = category['totalRowCount'];
      const pageSlice = (Math.ceil(category['totalRowCount'] / this.data.PageSize)) + 1;
      for (let page = 1; page < pageSlice; page++) {
        this.pager.push(page);
      }

      const temp = [];
      let temp2 = [];
      try {
        for (let index = 0; index < category['events'].length; index++) {
          const item = category['events'][index];

          if (item.columns === null) {
            item.columns = [];
          }

          if (category.columnType === 'TwoColumns') {
            item.columns[0] =
              typeof item.columns[0] !== 'undefined' && item.columns[0]['columnNo'] === 1 ? item.columns[0] : {};
            item.columns[1] = typeof item.columns[1] !== 'undefined' ? item.columns[1] : {};
          } else if (category.columnType === 'SingleColumns') {
            item.columns[0] = typeof item.columns[0] !== 'undefined' ? item.columns[0] : {};
          } else {
            item.columns[0] =
              typeof item.columns[0] !== 'undefined' && item.columns[0]['columnNo'] === 1 ? item.columns[0] : {};
            item.columns[1] =
              typeof item.columns[1] !== 'undefined' && item.columns[1]['columnNo'] === 2 ? item.columns[1] : {};
            item.columns[2] =
              typeof item.columns[2] !== 'undefined' && item.columns[2]['columnNo'] === 3 ? item.columns[2] : {};
          }

          let date = '';
          if (type === 'date') {
            date = formatDate(item.date, 'yyyy-MM-dd', 'en-US');
          } else {
            date = type;
          }

          if (!temp[date]) {
            temp[date] = {};
            temp[date].date = date;
            temp[date].class = category['columnType'];
            if (listType === 'events') {
              temp[date].list = [];
              temp[date].list[0] = {};
              temp[date].list[0].events = [];
            } else {
              temp[date].competitions = [];
            }
          }

          if (listType === 'events') {
            temp[date].list[0].events.push(item);

          } else {
            if (!temp[date].competitions[item.competitionName + item.competitionId]) {
              temp[date].competitions[item.competitionName + item.competitionId] = {};
              temp[date].competitions[item.competitionName + item.competitionId].name = item.competitionName;
              temp[date].competitions[item.competitionName + item.competitionId].countryName = item.subCategoryName;
              temp[date].competitions[item.competitionName + item.competitionId].flag = item.countryIsoCode;
              temp[date].competitions[item.competitionName + item.competitionId].events = [];
            }
            temp[date].competitions[item.competitionName + item.competitionId].events.push(item);
          }
        }
      } catch (error) {
        console.log(error);
      }

      if (listType === 'events') {
        for (const key in temp) {
          if (temp.hasOwnProperty(key)) {
            const element = temp[key];
            element.list = this.convertObjectFormat(element.list);
            temp2.push(element);
          }
        }
        temp2 = temp2[0];
      } else {
        for (const key3 in temp) {
          if (temp.hasOwnProperty(key3)) {
            const element = temp[key3];
            element.competitions = this.convertObjectFormat(element.competitions);
            temp2.push(element);
          }
        }
      }

      return {
        list: temp2,
        layout: this.layout,
        pager: this.pager,
        totalRow: this.totalRow,
        state: category.state
      };

    } else {
      return {
        list: [],
        layout: this.layout,
        pager: this.pager,
        totalRow: this.totalRow
      };
    }
  }

  private convertObjectFormat(object: any) {
    const temp = [];
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        const element = object[key];
        element.filters = [];
        if (this.layout[0]) {
          element.filters[0] = this.setFilters(element['events'], 0);
          element.filters[0].index = 0;
        }
        if (this.layout[1]) {
          element.filters[1] = this.setFilters(element['events'], 1);
          element.filters[1].index = 0;
        }
        if (this.layout[2]) {
          element.filters[2] = this.setFilters(element['events'], 2);
          element.filters[2].index = 0;
        }
        temp.push(element);
      }
    }
    return temp;
  }

  private setFilters(events: any, column: number) {
    for (let index = 0; index < events.length; index++) {
      const element = events[index];
      if (typeof element.columns[column].columnNo !== 'undefined' && element.columns[column].markets !== 'undefined') {
        return element.columns[column].markets[0];
      }
    }
    return [];
  }
}
