import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {

  public hasConfirm = new BehaviorSubject<string>('');

  constructor() { }

  confirm(name:string){
    this.hasConfirm.next(name);
  }
}