import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { FavoriteService } from 'src/app/core';

@Component({
  selector: 'app-slide-menu',
  templateUrl: './slide-menu.component.html',
  styleUrls: ['./slide-menu.component.scss']
})
export class SlideMenuComponent implements OnInit, AfterViewInit {

  @Input() list: any[];
  @Input() customStyle: any;
  @Input() customClass: string;
  @Input() title: boolean = false;
  @Input() showCount: boolean = false;

  @ViewChild('scrollMenu') scrollMenu: ElementRef;
  @ViewChild('menu') menu: ElementRef;

  leftReset: boolean = false;
  rightReset: boolean = false;
  favoriteActive: boolean = false;
  showFavorite: any = false;
  indexTrackFn = (index: number) => index;

  constructor(
    private router: Router,
    private favoriteService: FavoriteService
  ) { }

  ngOnInit() {
    this.favoriteService.isFavoriteChange().subscribe(
      async () => {
        const liveComp: any = await this.favoriteService.getFavorite('liveComp').toPromise();
        const liveTeam: any = await this.favoriteService.getFavorite('liveTeam').toPromise();
        if ((liveComp && liveComp.length > 0) || (liveTeam && liveTeam.length > 0)) {
          this.showFavorite = Number(liveComp.length) + Number(liveTeam.length);
        } else {
          this.showFavorite = false;
        }
      }
    )
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.controlMenuResponsive();
    });
  }

  leftScrool(event: any) {
    event.scrollLeft = Number(event.scrollLeft - 50);
    if (Number(event.scrollLeft) <= 50) { this.leftReset = false; }
  }

  rightScrool(event: any) {
    event.scrollLeft = Number(event.scrollLeft + 50);
    this.leftReset = true;
  }

  controlMenuResponsive() {
    const scrollWidth = this.menu.nativeElement.scrollWidth;
    const menuWith = this.menu.nativeElement.offsetWidth;

    if (scrollWidth === menuWith) {
      this.leftReset = false;
      this.rightReset = false;
    } else {
      this.leftReset = false;
      this.rightReset = true;
    }
  }

  changeCat(id: number, state: string) {
    this.favoriteActive = false;
    this.router.navigate([], { queryParams: { c: id, ls: state } });
    for (const key in this.list) {
      if (this.list.hasOwnProperty(key)) {
        const element = this.list[key];
        if (Number(element.id) === Number(id)) {
          element.active = true;
        } else {
          element.active = false;
        }
      }
    }
  }

  getFavorites() {
    this.favoriteActive = true;
    this.router.navigate([], { queryParams: { c: 0, ls: 'favorite' } });
    for (const key in this.list) {
      if (this.list.hasOwnProperty(key)) {
        const element = this.list[key];
        element.active = false;
      }
    }
  }

}
