import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CasinoComponent } from './casino.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SuitComponent } from './suit/suit.component';

const routes: Routes = [{
  path: '',
  component: CasinoComponent,
  children: [
    {
      path: '',
      redirectTo: 'overview',
      pathMatch: 'full'
    },
    {
      path: 'overview',
      component: DashboardComponent,
      data: {
        type: 'games'
      }
    },
    {
      path: 'suit',
      component: SuitComponent,
      data: {
        type: 'games'
      }
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CasinoRoutingModule { }
