import { LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient } from '@angular/common/http';
import { HttpCacheInterceptorModule } from '@ngneat/cashew';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RedirectLobbyComponent } from 'src/app/redirect-lobby/redirect-lobby.component';
import { SharedModule } from './shared/shared.module';
import { HomeModule } from './home/home.module';
import { SportsbookModule } from './sportsbook/sportsbook.module';
import { LiveBettingModule } from './live-betting/live-betting.module';
import { CasinoModule } from './casino/casino.module';
import { LiveCasinoModule } from './live-casino/live-casino.module';
import { PromotionsModule } from './promotions/promotions.module';
import { VirtualBetModule } from './virtual-bet/virtual-bet.module';
import { SearchModule } from './search/search.module';
import { PokerModule } from './poker/poker.module';

import { LangService } from './core/services';
import { StorageModule } from '@ngx-pwa/local-storage';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BingoModule } from './bingo/bingo.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?cb=' + new Date().getTime());
}

@NgModule({
  declarations: [
    AppComponent,
    RedirectLobbyComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpCacheInterceptorModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    NgxGoogleAnalyticsRouterModule,
    SharedModule,
    NgxSmartModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LoadingBarRouterModule,
    HomeModule,
    SportsbookModule,
    LiveBettingModule,
    CasinoModule,
    LiveCasinoModule,
    PromotionsModule,
    VirtualBetModule,
    SearchModule,
    PokerModule,
    StorageModule.forRoot({ IDBNoWrap: false }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BingoModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [LangService],
      useFactory: (langService: any) => langService.setLang()
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
