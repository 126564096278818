import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BetCardService, OddtypeService } from 'src/app/core';

@Component({
  selector: 'app-odds-group',
  templateUrl: './odds-group.component.html',
  styleUrls: ['./odds-group.component.scss']
})
export class OddsGroupComponent implements OnInit, OnDestroy {

  @Input() odds: any;
  @Input() groupName: any;
  @Input() activeTab: any;
  oddType: any;
  homeCorrectScore: number = 0;
  awayCorrectScore: number = 0;
  correctOddIndex: string = '0:0';
  indexTrackFn = (index: number) => index;

  constructor(
    private betCardService: BetCardService,
    private oddTypeService: OddtypeService
  ) {
    this.oddTypeService.oddSubject.subscribe(
      r => {
        this.oddType = r;
      }
    );
  }

  ngOnInit() { }

  toggleOdd(id: any) {
    this.betCardService.toggleOdd(id).subscribe(() => { });
  }

  scoreUp(type: string, max: number) {
    if (type === 'home' && this.homeCorrectScore < max) {
      this.homeCorrectScore++;
      this.correctOddIndex = this.homeCorrectScore + ':' + this.awayCorrectScore;
    } else if (type === 'away' && this.awayCorrectScore < max) {
      this.awayCorrectScore++;
      this.correctOddIndex = this.homeCorrectScore + ':' + this.awayCorrectScore;
    }
  }

  scoreDown(type: string) {
    if (type === 'home' && this.homeCorrectScore > 0) {
      this.homeCorrectScore--;
      this.correctOddIndex = this.homeCorrectScore + ':' + this.awayCorrectScore;
    } else if (type === 'away' && this.awayCorrectScore > 0) {
      this.awayCorrectScore--;
      this.correctOddIndex = this.homeCorrectScore + ':' + this.awayCorrectScore;
    }
  }

  ngOnDestroy() { }

}
