import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private apiService: ApiService,
    private storage: StorageMap,
  ) { }

  isVip() {
    return new Observable(observer => {
      this.storage.get('userInfo').subscribe(
        user => {
          observer.next(user['user'].isVip);
          observer.complete();
          
        }
      );
    });
  }

  getBalance() {
    return this.apiService.post('account/balance');
  }

  getCurrency() {
    return new Observable(observer => {
      this.storage.get('userInfo').subscribe(
        user => {
          observer.next(user['user'].currencyCode);
          observer.complete();
        }
      );
    });
  }

  getCountry() {
    return new Observable(observer => {
      this.storage.get('userInfo').subscribe(
        user => {
          observer.next(user['user'].preferredCountry);
          observer.complete();
        }
      );
    });
  }

  isCasinoTransferActive() {
    return new Observable(observer => {
      this.storage.get('userInfo').subscribe(
        user => {
          observer.next(user['user'].casinoTransfer);
          observer.complete();
        }
      );
    });
  }

  updateBalance() {
    return new Observable(observer => {
      this.getBalance().subscribe(
        (r: any) => {
          this.storage.set('balance', r.result).subscribe(() => {
            observer.next(r);
            observer.complete();
          });
        }
      );
    });
  }
}
