import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { ApiService, EventsService, FavoriteService } from 'src/app/core';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { diff } from 'deep-object-diff';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'app-sportsbook-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('panel') panel: ElementRef;
  data: any = [];
  pageIndex: any = 1;
  sliceStart: any = 0;
  sliceEnd: any = 4;
  liveList: any = [];
  liveLayout: any = [];
  menuList: object;
  timer: any;
  catTimer: any;
  columnLimit: any = 3;
  tableClass: any = 'large';

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private eventService: EventsService,
    private router: Router,
    private favoriteService: FavoriteService
  ) {
    this.getCategories();
  }

  ngOnInit() {
    let c: number;
    combineLatest([this.route.queryParams]).subscribe(
      r => {
        if (Number(r[0].c) !== c) {
          c = Number(r[0].c);
          this.getLive(c);
        }
      }
    );

    const rdata = {
      CategoryId: 1,
      DateStart: formatDate(Date.now(), 'yyyy-MM-dd', 'en-US', 'UTC') + ' 00:00:00',
      DateEnd: formatDate(Date.now() + (15 * 86400000), 'yyyy-MM-dd', 'en-US', 'UTC') + ' 23:59:59',
      Limit: 3
    };

    this.apiService.post('Competition/GetTopCompetitionsByBetStackEntry', rdata, false, true).subscribe(
      async r => {
        const data = [];
        for (let index = 0; index < r.length; index++) {
          const element = r[index];
          const snc = await this.get(element.id);
          if (snc['list'] && snc['list'].length > 0) {
            data.push(snc);
          }
        }
        this.data = data;
      }
    );

    this.timer = setInterval(() => {
      const c = this.route.snapshot.queryParams.c;
      if (c) {
        this.getLive(c, null);
      }
    }, 3000);

    this.catTimer = setInterval(() => {
      this.getCategories(true);
    }, 6000);
  }

  ngOnDestroy() {
    clearInterval(this.timer);
    clearInterval(this.catTimer);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.onResized();
    }, 0);
  }

  onResized() {
    if (this.panel.nativeElement.offsetWidth > 900) {
      this.columnLimit = 3;
      this.tableClass = 'large';
    } else if (this.panel.nativeElement.offsetWidth < 900 && this.panel.nativeElement.offsetWidth > 700) {
      this.columnLimit = 2;
      this.tableClass = 'medium';
    } else {
      this.columnLimit = 1;
      this.tableClass = 'small';
    }
  }

  get(CompetitionId: any) {
    const params = {};
    params['StartDate'] = formatDate(Date.now(), 'yyyy-MM-dd', 'en-US', 'UTC');
    params['EndDate'] = formatDate(Date.now() + (7 * 86400000), 'yyyy-MM-dd', 'en-US', 'UTC');
    params['CompetitionId'] = CompetitionId;

    return new Promise((resolve) => {
      this.eventService.getData(params, null, 'comp').subscribe(
        r => {
          r = r[0] || [];
          resolve(r);
        }
      );
    });

  }

  async getLive(catId: any, loaderId: any = 'liveHighlights') {
    const params = {};
    params['StartDate'] = formatDate(Date.now(), 'yyyy-MM-dd', 'en-US', 'UTC');
    params['EndDate'] = formatDate(Date.now(), 'yyyy-MM-dd', 'en-US', 'UTC');
    params['CategoryId'] = catId;
    params['isLive'] = true;
    params['Limit'] = 4;

    if (Number(catId) === 0) {
      const compFavs = await this.favoriteService.getFavorite('liveComp').toPromise();
      params['CompetitionFilter'] = compFavs.toString();
    }

    this.eventService.getData(params, loaderId, 'date', 'events').subscribe(
      r => {
        r = r[0];
        if (r && r.list) {
          const df: any = diff(r.list, this.liveList);
          if (Object.keys(df).length > 0) {
            this.liveList = r.list;
            this.liveLayout = r.layout;
          }
        } else {
          this.liveList = [];
          this.liveLayout = [];
        }
      }
    );
  }

  getCategories(refresh: boolean = false) {
    const data = {
      IsLive: 1,
      currentOnly: 1
    };
    let firstActv = false;
    let lastActv = false;
    this.apiService.post('Category/GetCategoriesWithEventCounter', data)
      .pipe(
        map(data => data.filter(item => item.type === 0 && item.enabled && item.eventCount > 0)),
        map(data => data.filter(
          item => {
            if (refresh) {
              if (Number(item.id) === Number(this.route.snapshot.queryParams.c)) {
                item.active = true;
                firstActv = item.id;
              }
              return item;
            } else {
              if (item.liveEventCount > 0 && Number(firstActv) !== 1) {
                if (item.id === 1) {
                  firstActv = item.id;
                } else if (item.id === 3) {
                  firstActv = item.id;
                } else if (item.id === 4) {
                  firstActv = item.id;
                } else if (item.id === 2) {
                  firstActv = item.id;
                } else {
                  lastActv = item.id;
                }
              }
            }
            return item;
          }
        )
        )
      )
      .subscribe(
        r => {
          const sportId = firstActv || lastActv || false;
          if (sportId) {
            this.router.navigate([], { queryParams: { c: sportId } });
          }
          this.menuList = r;
        }
      );
  }

}
