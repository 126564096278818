import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared';

import { PokerRoutingModule } from './poker-routing.module';
import { PokerComponent } from './poker.component';
import { RegisterComponent } from './register/register.component';
import { SuitComponent } from './suit/suit.component';
import { SliderComponent } from './slider/slider.component';
import { CashierComponent } from './cashier/cashier.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';


@NgModule({
  declarations: [PokerComponent, RegisterComponent, SuitComponent, SliderComponent, CashierComponent, ResetPasswordComponent],
  imports: [
    CommonModule,
    PokerRoutingModule,
    SharedModule
  ]
})
export class PokerModule { }
