import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-suit-suit',
  templateUrl: './suit.component.html',
  styleUrls: ['./suit.component.scss']
})
export class SuitComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
