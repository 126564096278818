import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Platform } from '@angular/cdk/platform';
import * as hash from 'object-hash';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {

  saveCookie: any = false;

  constructor(
    public platform: Platform,
    public ngxSmartModalService: NgxSmartModalService
  ) { }

  ngOnInit(): void {
  }

  afterNoShow(obj: object) {
    localStorage.setItem(hash(obj), 'true');
  }
}
