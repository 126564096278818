import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared';
import { LiveCasinoRoutingModule } from './live-casino-routing.module';
import { LiveCasinoComponent } from './live-casino.component';
import { SliderComponent } from './slider/slider.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SuitComponent } from './suit/suit.component';

@NgModule({
  imports: [
    CommonModule,
    LiveCasinoRoutingModule,
    SharedModule
  ],
  declarations: [LiveCasinoComponent, SliderComponent, DashboardComponent, SuitComponent]
})
export class LiveCasinoModule { }
