import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { LangService } from 'src/app/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { NgxSmartModalService } from 'ngx-smart-modal';
declare let STATSCORE: any;

@AutoUnsubscribe()
@Component({
  selector: 'app-stat-score',
  templateUrl: './stat-score.component.html',
  styleUrls: ['./stat-score.component.scss']
})
export class StatScoreComponent implements OnInit, AfterViewInit, OnDestroy {

  trackerGenerator = new STATSCORE.LSP.Generator();
  tracker: any;
  config = {
    lang: this.langService.currentLang(),
    timezone: 'Local',
    useMappedId: '1'
  };
  noTracker: any = false;
  constructor(
    private route: ActivatedRoute,
    private langService: LangService,
    public ngxSmartModalService: NgxSmartModalService
  ) { }

  ngOnInit() {
    window.addEventListener('statscore.livematchpro.betorder.error', () => {
      this.noTracker = true;
    });
  }

  ngAfterViewInit() {
    combineLatest([this.route.queryParams]).subscribe(
      r => {
        if (r[0]['t'] && Number(r[0]['t']) !== 0) {
          setTimeout(() => {
            this.noTracker = false;
            this.setTracker(r[0]['t']);
          }, 300);
        } else {
          setTimeout(() => {
            this.noTracker = true;
          }, 300);
        }
      }
    );
  }

  setTracker(id: any) {
    try {
      if (typeof this.tracker === 'object') {
        this.tracker.setEvent(id).refresh();
      } else {
        this.tracker = this.trackerGenerator.create('#trak', 'betorder', id, 469, this.config);
      }
    } catch (error) {
      setTimeout(() => {
        this.setTracker(id);
      }, 1000);
    }
  }

  ngOnDestroy() {
    //this.tracker.remove();
  }
}
