import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { RSA_NO_PADDING } from 'constants';

@Injectable({
  providedIn: 'root'
})
export class StaticService {

  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private http: HttpClient,
    private loaderService: NgxUiLoaderService
  ) { }

  private formatErrors(error: any) {
    return throwError(error.message);
  }

  private endLoader(loaderID: any) {
    if (loaderID) {
      if (this.loaderService.hasRunningTask(true, loaderID)) {
        this.loaderService.stopLoader(loaderID);
      }
    }
  }

  private startLoader(loaderID: any) {
    if (loaderID) {
      this.loaderService.startLoader(loaderID);
    }
  }

  gets(path: string, params: HttpParams = new HttpParams(), loaderID: any = false): Observable<any> {
    this.startLoader(loaderID);
    return this.http.get(
      `${environment.static_content}${path}`,
      { params })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        catchError(err => this.formatErrors(err)),
        finalize(() => {
          this.endLoader(loaderID);
        })
      );
  }

  get(path: string, params: HttpParams = new HttpParams(), loaderID: any = false): Observable<any> {
    this.http.get(`${environment.static_content}${path}`).subscribe(res => {
      console.log(res)
    }, err => {
      console.log(err)
    });

    this.startLoader(loaderID);
    return this.http.get(
      `${environment.static_content}${path}`,
      { params })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        catchError(err => this.formatErrors(err)),
        finalize(() => {
          this.endLoader(loaderID);
        })
      );
  }

  post(path: string, body: object = {}, loaderID: any = false): Observable<any> {
    this.startLoader(loaderID);
    return this.http.post(
      `${environment.static_content}${path}`,
      JSON.stringify(body))
      .pipe(
        takeUntil(this.ngUnsubscribe),
        catchError(err => this.formatErrors(err)),
        finalize(() => {
          this.endLoader(loaderID);
        })
      );
  }
}
