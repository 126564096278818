import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LiveBettingComponent } from './live-betting.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EventViewComponent } from './event-view/event-view.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { LiveMatchStreamComponent } from './live-match-stream/live-match-stream.component';

import {
  LoginModalGuardService as LoginGuard
} from 'src/app/core';

const routes: Routes = [{
  path: '',
  component: LiveBettingComponent,
  children: [
    {
      path: '',
      redirectTo: 'dashboard',
      pathMatch: 'full'
    }, {
      path: 'dashboard',
      component: DashboardComponent
    }, {
      path: 'eventview/:id',
      component: EventViewComponent
    }, {
      path: 'schedule',
      component: ScheduleComponent
    }, {
      path: 'livestream',
      component: LiveMatchStreamComponent,
      canActivate: [LoginGuard]
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LiveBettingRoutingModule { }
