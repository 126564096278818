import { Component, OnInit, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService, BetCardService } from 'src/app/core';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-featured-events',
  templateUrl: './featured-events.component.html',
  styleUrls: ['./featured-events.component.scss']
})
export class FeaturedEventsComponent implements OnInit {

  @Input() title: any = false;
  @Input() direction: boolean = false;
  @Input() dot: boolean = false;

  isTitle: boolean = false;
  res: any;
  imgUrl: string = environment.event_api_url + 'logo/';

  customOptions: OwlOptions;

  constructor(
    private apiService: ApiService,
    private betCardService: BetCardService
  ) { }

  ngOnInit() {
    this.customOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: false,
      dots: this.dot,
      navSpeed: 1500,
      margin: 12,
      stagePadding: 0,
      nav: this.direction,
      navText: ['<span class="icon bet-left-dir direction left"></span>', '<span class="icon bet-right-dir direction right"></span>'],
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 1
        },
        740: {
          items: 2
        },
        940: {
          items: 3
        }
      }
    };
    this.apiService.post('Schedule/PopularEventsWithMarkets', { Limit: 10 }, false, true).subscribe(
      res => {
        this.res = res['events'] || [];
      }
    );
  }

  toggleOdd(id: any) {
    this.betCardService.toggleOdd(id).subscribe(() => { });
  }
}
