import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/core';

@Component({
  selector: 'app-live-betting-top-live-list',
  templateUrl: './top-live-list.component.html',
  styleUrls: ['./top-live-list.component.scss']
})
export class TopLiveListComponent implements OnInit {

  selectEvent: any;
  data: any;
  time: any;
  indexTrackFn = (index: number) => index;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {

    this.getLiveEvents();
    this.time = setInterval(() => {
      this.getLiveEvents(false);
    }, 6000);
  }

  eventChange(event: any) {
    this.router.navigate(['/livebetting/eventview', event.id], { queryParams: { t: event.externalId }} );
  }

  getLiveEvents(loaderId: any = 'sidebarLiveList') {
    this.apiService.post('LiveBetsV2/GetFullLiveBettingWithMarkets', {}, loaderId).subscribe(
      r => {
        this.data = r;

        for (let index = 0; index < r.length; index++) {
          const element = r[index];
          if (Number(element.id) === Number(this.route.snapshot.paramMap.get('id'))) {
            this.selectEvent = element;
            break;
          }
        }

      }
    );
  }
}
