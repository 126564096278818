import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/core';

@Component({
  selector: 'app-promotions-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  data: any;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private apiService: ApiService
  ) {
    this.route.queryParams.subscribe(
      params => {
        if (typeof params.id === 'undefined') {
          this.router.navigate(['/promotions'], { queryParams: { state: 'all' } });
        } else {
          this.getDetails(params.id);
        }
      }
    )
  }

  ngOnInit() { }

  getDetails(id) {
    this.apiService.post('bonus/GetLandingPage/', { urlCode: id }, 'promoDetailLDR', true).subscribe(
      r => {
        this.data = r.landingPage;
      }
    )
  }

}
