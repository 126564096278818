import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';
import * as hash from 'object-hash';
import { withCache } from '@ngneat/cashew';
import {BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CasinoService {

  fastPlayOpen = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private loaderService: NgxUiLoaderService,
    private storage: StorageMap,
  ) { }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  private endLoader(loaderID: any) {
    if (loaderID) {
      this.loaderService.stopLoader(loaderID);
    }
  }

  private startLoader(loaderID: any) {
    if (loaderID) {
      this.loaderService.startLoader(loaderID);
    }
  }

  post(path: string, body: Object = {}, loaderID: any = false): Observable<any> {
    this.startLoader(loaderID);
    return this.http.post(
      `${environment.casino_api_url}${path}`,
      JSON.stringify(body),
      withCache(
        hash(body)
      )
    ).pipe(
      catchError(this.formatErrors),
      finalize(() => {
        this.endLoader(loaderID);
      })
    );
  }

  setHistory(gameID: any) {
    return new Observable(observer => {
      this.storage.get('userInfo').subscribe(
        r => {
          const query = {
            userID: r['user']['xcode'],
            gameID: gameID,
            market: environment.sitekey
          };

          this.post('setHistory', query).subscribe(
            l => {
              observer.next(l);
              observer.complete();
            }
          );
        }
      );
    });
  }

  getHistory() {
    return new Observable(observer => {
      this.storage.get('userInfo').subscribe(
        r => {
          const query = {
            userID: r['user']['xcode'],
            market: environment.sitekey
          };
          this.post('getHistory', query).subscribe(
            l => {
              observer.next(l);
              observer.complete();
            }
          );
        }
      );
    });
  }

  /*setFavorities() {
    const query = {};
    query.userID = globals.auth.username;
    query.gameID = vm.playGameData['id'];
    query.market = environment.sitekey;

    HttpCasino('setFavorities', query).then(function (res) {
      if (res.status) {
        vm.isFavorite = true;
      } else {
        vm.isFavorite = false;
      }
    }, function (err) {
      vm.isFavorite = false;
    });
  }

  getFavorities() {
    const query = {};
    query.userID = globals.auth.username;
    query.market = environment.sitekey;

    HttpCasino('getFavorities', query).then(function (res) {
      if (res.status) {
        vm.data = res;
      } else {
        console.error('Req Error!');
      }
    }, function (err) {
      console.error('Req Error!', err);
    });
  }

  isFavorite(gameID: any) {
    const query = {};
    query.userID = globals.auth.username;
    query.market = environment.sitekey;
    query.gameID = gameID;

    HttpCasino('hasFavorite', query).then(function (res) {
      if (res.status) {
        vm.isFavorite = true;
      } else {
        vm.isFavorite = false;
      }
    }, function (err) {
      vm.isFavorite = false;
    });
  }*/

}
