import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-standings',
  templateUrl: './standings.component.html',
  styleUrls: ['./standings.component.scss']
})
export class StandingsComponent implements OnInit {

  list: any = [];

  constructor(
    private apiService: ApiService,
    private ngxSmartModalService: NgxSmartModalService
  ) { }

  ngOnInit(): void {

    const data = this.ngxSmartModalService.getModalData('standingModal');
    this.getStanding(data);
  }

  getStanding(data: any) {
    this.apiService.post('competition/standings/' + data.competitonId).subscribe(
      r => {
        this.list = r;
      }
    )
  }

}
