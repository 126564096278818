import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-stream-tracker',
  templateUrl: './stream-tracker.component.html',
  styleUrls: ['./stream-tracker.component.scss']
})
export class StreamTrackerComponent implements OnInit {

  firstTab: boolean = true;
  lastTab: boolean = false;


  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd)
      )
      .subscribe(() => {
        if (this.router.url.search('/livebetting/livestream') > -1) {
          this.firstTab = false;
          this.lastTab = true;
        } else {
          this.firstTab = true;
          this.lastTab = false;
        }
      })
  }

}
