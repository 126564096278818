import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ApiService } from 'src/app/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { environment } from '../../../environments/environment';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-live-betting-event-widget',
  templateUrl: './event-widget.component.html',
  styleUrls: ['./event-widget.component.scss']
})
export class EventWidgetComponent implements OnInit {

  @Input() data: any;
  imgUrl: string = environment.event_api_url + 'logo/';
  scoresFilter: any = { scoreValue: '' };

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit() {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd)
      )
      .subscribe(
        () => {
          const id = Number(this.route.snapshot.paramMap.get('id'));
          if (id) {
            //this.getLiveStats(id);
          }
        }
      );
  }

  getLiveStats(id: number) {
    const data = {
      id: id
    };
    this.apiService.post('livescore/details', data).subscribe(
      r => {
        console.log(r);
      }
    );
  }

  back() {
    this.location.back();
  }

}
