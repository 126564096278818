import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { StorageMap } from '@ngx-pwa/local-storage';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private storage: StorageMap
    ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(req, next));
  }


  async handle(req: HttpRequest<any>, next: HttpHandler) {
    const token = await this.storage.get('token').toPromise();

    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };

    headersConfig['x-' + environment.sitekey + '-aboutme'] = await this.storage.get('uuid').toPromise() || '';
    headersConfig['x-' + environment.sitekey + '-locale'] = await this.storage.get('lang').toPromise() || '';

    if (typeof token !== 'undefined' && token['clientId']) {
      headersConfig['x-' + environment.sitekey + '-clientId'] = token['clientId'];
    }

    if (typeof token !== 'undefined' && token['token']) {
      headersConfig['Authorization'] = 'Bearer ' + token['token'];
    }
    const request = req.clone({
      setHeaders: headersConfig
    });

    return next.handle(request).toPromise();
  }
}
