import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core';

@Component({
  selector: 'app-sportsbook-sis-races',
  templateUrl: './sis-races.component.html',
  styleUrls: ['./sis-races.component.scss']
})
export class SisRacesComponent implements OnInit {

  list:any = [];

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    const data = {
      categoryId: 118,
      startDate: '2020-03-01',
      endDate: '2020-03-29'
    }

    //this.apiService.post('HorseGreyhound/Menu',data).subscribe(() => {})

    this.apiService.post('HorseGreyhound/Promoted', data).subscribe(
      r => {
        const temp = [];
        const temp1 = [];
        for (const item of r) {
          if(!temp[item.fedName]){
            temp[item.fedName] = {};
            temp[item.fedName].fedName = item.fedName;
            temp[item.fedName].comps = [];
          }
          temp[item.fedName].comps.push(item);
        }

        for (const key in temp) {
          if (temp.hasOwnProperty(key)) {
            const element = temp[key];
            temp1.push(element);
          }
        }
        this.list = temp1;

        const data2 = {
          competitions: [4023, 4029],
          startDate: '2020-03-01',
          endDate: '2020-03-29'
        }

        this.apiService.post('HorseGreyhound/GetV2', data2).subscribe(
          r => {
            console.log(r);
          }
        )

        const data3 = {
          id: 288229
        }


        this.apiService.post('HorseGreyhound/Event/288229').subscribe(
          r => {
            console.log(r);
          }
        )

      }
    )
  }

}
