import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core';
import { formatDate } from '@angular/common';
import { resultFilter as fltr, resultCategories as ctgr} from 'src/app/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Platform } from '@angular/cdk/platform';
import { Router } from '@angular/router';
import { StatScoreComponent } from 'src/app/shared/stat-score/stat-score.component';

@Component({
  selector: 'app-sportsbook-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {

  results: any = [];
  countries: any = [];
  categories = ctgr;
  date: any =
    new Date().getUTCFullYear() + '-' +
    ('0' + Number(new Date().getUTCMonth() + 1)).slice(-2) + '-' + ('0' + new Date().getDate()).slice(-2);
  filter = fltr;
  selectedCountry: any;
  selectedCategory: number = 1;
  selectedFilter = fltr[0];
  filterFeed: any = { fedTitle: '' };
  filterEvent: any = { simpleStatus: '' };

  constructor(
    private apiService: ApiService,
    private ngxSmartModalService: NgxSmartModalService,
    private platform: Platform,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getResults();
  }

  getResults() {
    const data = {
      categoryId: this.selectedCategory,
      dateEnd: this.date + 'T23:59:59',
      dateStart: this.date + 'T00:00:00',
      quickFilter: null
    };

    this.apiService.post('livescore/listscoresv2', data, 'resultsLDR', false).subscribe(
      r => {
        this.results = r.competitions;
        this.countries = r.subCategories;
        this.selectedCountry = null;
        this.selectedFilter = fltr[0];
        this.filterFeed = { fedTitle: '' };
        this.filterEvent = { simpleStatus: '' };
      }
    )
  }

  formatDate(date: any) {
    return formatDate(date, 'yyyy-MM-dd', 'en-US');
  }

  getTypeName(id: number): string {
    for (let index = 0; index < this.filter.length; index++) {
      const element = this.filter[index];
      if (element.value === Number(id)) {
        return element.name;
      }
    }
    return String(id);
  }

  openModal(betradarId: number) {
    this.router.navigate([], { queryParams: { t: betradarId }, queryParamsHandling: 'merge' }).then(() => {
      const options: any = {
        escapable: false,
        dismissable: false,
        customClass: !this.platform.BLINK ? 'theme-light app-modal statscore-modal' : 'theme-light app-modal statscore-modal animated zoomIn faster'
      };
      this.ngxSmartModalService.create('statScoreModal', StatScoreComponent, options).open();
    });
  }

}
