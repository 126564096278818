import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared';

import { VirtualBetRoutingModule } from './virtual-bet-routing.module';
import { EventViewComponent } from './event-view/event-view.component';
import { VirtualBetComponent } from './virtual-bet.component';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';
import { RacesComponent } from './races/races.component';
import { VirtualComponent } from './virtual/virtual.component';


@NgModule({
  imports: [
    CommonModule,
    VirtualBetRoutingModule,
    SharedModule
  ],
  declarations: [EventViewComponent, VirtualBetComponent, SidebarMenuComponent, RacesComponent, VirtualComponent]
})
export class VirtualBetModule { }
