import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { menu as topMenu } from 'src/app/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss']
})
export class MobileNavComponent implements OnInit {

  @Output() sendDataToParent = new EventEmitter<any>();
  list = topMenu;

  activeTab: any = 0;

  constructor(
    private router: Router
  ) { }

  ngOnInit() { }

  searchRedirect(){
    this.sendDataToParent.emit();
    this.router.navigate(['/search']);
  }

}
