import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FirstLoadControlService implements CanActivate {

  constructor(
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {

    const queryParams = route.queryParamMap;

    if (queryParams.has('btag')) {
      localStorage.setItem('btag', String(queryParams.get('btag')));
    }

    if (queryParams.has('promo')) {
      localStorage.setItem('promo', String(queryParams.get('promo')));
    }

    return true;

    /*if (localStorage.getItem('firstLoad') && sessionStorage.getItem('previousRoute') === '/') {
      this.router.navigate(['/sportsbook'], { queryParams: { firsLoad: true }, queryParamsHandling: 'preserve' });
      return false;
    } else {
      localStorage.setItem('firstLoad', 'true');
      return true;
    }*/
  }
}
