import { Injectable } from '@angular/core';
import { JwtService } from './jwt.service';
import { Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    private jwtService: JwtService
  ) { }

  canActivate(): Observable<boolean> {
    return this.jwtService.hasLogin().pipe(
      map(
        r => {
          if (!r) {
            this.router.navigate(['/']);
            return false;
          }
          return true;
        }
      )
    )
  }
}
