import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { CasinoService, StaticService, ApiService } from 'src/app/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { delay } from 'rxjs/operators';

export class CasinoPostObject {
  vendorID: any = '';
  category: any = '';
  type: any = 'games';
  platform: any = 'PC';
  nextIndex: number = 0;
}

@Component({
  selector: 'app-casino-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  list: any = [];
  playtechList: any = [];
  category: any[];
  vendors: any[];
  catId: any;
  count: any;
  total: any = 0;
  casinoObject = new CasinoPostObject();
  featuredVendors: any[];
  otherVendors: any[];
  discountList: any[];
  timeReset: any;
  isSearch: boolean = false;
  selectedVendor: string = '';
  searchKeyword: string = '';
  private featuredList: any = {
    'EGT': 1,
    'NetEnt': 2,
    'EvolutionGaming': 3,
    'PragmaticPlay': 4
  };

  constructor(
    private casinoService: CasinoService,
    private staticService: StaticService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private platform: Platform,
    private apiService: ApiService
  ) {
    if (this.platform.ANDROID) {
      this.casinoObject.platform = 'Android';
    } else if (this.platform.IOS) {
      this.casinoObject.platform = 'iPhone';
    } else if (this.platform.SAFARI) {
      this.casinoObject.platform = 'iPad';
    } else {
      this.casinoObject.platform = 'PC';
    }
  }

  ngOnInit() {
    //this.getNoDiscount();
    //this.getVendors();
    //this.getGames();
    //this.getPlaytechList();
    combineLatest([this.route.queryParams]).subscribe(
      r => {
        if (typeof r[0].k !== 'undefined' && r[0].k.length >= 3) {
          this.searchKeyword = r[0].k;
          clearTimeout(this.timeReset);
          this.timeReset = setTimeout(() => {
            this.search(r[0].k);
          }, 1000);
        } else if (typeof r[0].k === 'undefined') {
          this.searchKeyword = '';
          //this.getGames();
        }
      }
    );
  }

  search(key: string) {
    const data = this.casinoObject;
    data['search'] = key;
    this.casinoService.post('search', data, 'casinoModal')
      .pipe(
        delay(500),
      )
      .subscribe(
        res => {
          this.isSearch = true;
          this.list = res['data'];
        }
      );
  }

  getGames(reset?: any, loaderId: any = 'casinoModal') {
    this.isSearch = false;
    if (this.total >= this.casinoObject.nextIndex) {
      const data = this.casinoObject;
      this.casinoService.post('games', data, loaderId)
        .pipe(
          delay(500),
        )
        .subscribe(
          res => {
            reset ? this.list = res.data : this.list = [...this.list, ...res.data];
            const cats = [];
            this.translateService.get('All').subscribe(
              l => {
                cats.push({
                  id: '',
                  label: l
                });
              }
            );

            for (let index = 0; index < res.categories.length; index++) {
              const item = res.categories[index];
              this.translateService.get(String(item)).subscribe(
                l => {
                  cats.push({
                    id: item,
                    label: l
                  });
                }
              );
            }
            this.category = cats;
            this.count = this.list.length;
            this.casinoObject.nextIndex = res.nextIndex;
            this.total = res.total;
          }
        );
    }
  }

  getVendors() {
    const query = {};
    query['type'] = 'games';
    this.casinoService.post('vendors', query)
      .pipe(
        delay(500),
      )
      .subscribe(
        res => {
          let vendors = [];
          const otherVendors = [],
            featuredVendors = [];
          for (let index = 0; index < res.length; index++) {
            const item = res[index];
            this.translateService.get(String(item.id)).subscribe(
              l => {
                item.langText = l;
              }
            );
            if (this.featuredList[item.id]) {
              item.pr = this.featuredList[item.id];
              featuredVendors.push(item);
            } else {
              otherVendors.push(item);
            }
          }
          this.translateService.get('All').subscribe(
            l => {
              vendors.push({
                id: 'All',
                langText: l
              });
            }
          );
          vendors.push({
            id: 'Playtech',
            langText: 'Playtech',
            data: {
              vendorID: '-1',
              name: 'Playtech',
              presentation: {
                icon: {
                  '*': './assets/img/casinovendors/playtech.png'
                }
              }
            }
          });
          vendors = [...vendors, ...res];
          this.vendors = vendors;
          this.otherVendors = otherVendors;
          this.featuredVendors = featuredVendors;

          console.log(this.vendors);
        }
      );
  }

  changeCategory(id: any) {
    this.casinoObject.nextIndex = 0;
    this.casinoObject.category = id;
    this.getGames(true);
  }

  changeVendor(id: any) {
    this.selectedVendor = id;
    if (id !== '-1') {
      this.casinoObject.nextIndex = 0;
      this.casinoObject.category = '';
      this.casinoObject.vendorID = id;
      this.getGames(true);
    }
  }

  getNoDiscount() {
    const params = new HttpParams({ fromObject: { file: 'casino_no_discount_list' } });
    this.staticService.get('getJson', params).subscribe(
      (r: any) => {
        if (r && r.status && r.data) {
          this.discountList = r.data;
        }
      }
    );
  }

  getPlaytechList() {
    this.apiService.post('casino/getPtSlotList').subscribe(
      r => {
        this.playtechList = r;
      }
    )
  }

}
