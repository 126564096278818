import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.scss']
})
export class ValidateComponent implements OnInit {

  constructor(
    public platform: Platform,
    public ngxSmartModalService: NgxSmartModalService
  ) { }

  ngOnInit() {
  }

}
