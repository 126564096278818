import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HttpClient } from '@angular/common/http';
import { LangService, CasinoService, JwtService } from 'src/app/core';
import { Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-sidebar-card-casino',
  templateUrl: './sidebar-card-casino.component.html',
  styleUrls: ['./sidebar-card-casino.component.scss']
})
export class SidebarCardCasinoComponent implements OnInit {

  data: any;
  defaultLang: any = 'en';
  customOptions: OwlOptions = {
    autoplay: true,
    autoplayHoverPause: true,
    autoplayTimeout: 8000,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    loop: true,
    smartSpeed: 1500,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    lazyLoad: true,
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    }
  };

  constructor(
    private http: HttpClient,
    private langService: LangService,
    private casinoService: CasinoService,
    private router: Router,
    private ngxSmartModalService: NgxSmartModalService,
    private jwtService: JwtService
  ) {
    this.defaultLang = this.langService.currentLang();
  }

  ngOnInit() {
    this.http.get('assets/img/slider/mini-casino.json?' + Date.now()).subscribe(
      r => {
        this.data = r;
      }
    );
  }

  openCasinoGame(casino: any) {
    this.casinoService.post('getTheGame', { slug: casino.slug },'sideBarCasinoSld').subscribe(
      r => {
        const item = r.data || null;
        if(r.status){
          this.jwtService.isLoggedIn().subscribe(
            isLogin => {
              if (!item.data.playMode.fun && !isLogin) {
                this.ngxSmartModalService.getModal('loginModal').open();
              } else {
                sessionStorage.setItem('casinoGameData', JSON.stringify(item));
                this.router.navigate(['/' + casino.suitUrl]);
              }
            }
          ).unsubscribe();
        }
      }
    )
  }

  redirectPage(item: any) {
    if (item.casino) {
      this.openCasinoGame(item.casino)
    } else {
      if (item.route) {
        if(item.params){
          const params = item.params;
          this.router.navigate([item.route], { queryParams: params});
        }else{
          this.router.navigate([item.route]);
        }
      }
    }
  }

}
