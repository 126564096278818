import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appLiveSupport]'
})

export class LiveSupportDirective {

  constructor() { }

  @HostListener('click') onMouseEnter() {
    document.getElementById('comm100-float-button-2').getElementsByTagName('div')[0].getElementsByTagName('a')[0].click();
  }

}
