import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared';
import { HomeRoutingModule } from './home-routing.module';
import { NgCircleProgressModule } from 'ng-circle-progress';

import { HomeComponent } from './home.component';
import { CarouselComponent } from './carousel/carousel.component';
import { ProductsComponent } from './products/products.component';
import { OurFeaturesComponent } from './our-features/our-features.component';
import { CasinoGamesComponent } from './casino-games/casino-games.component';
import { FeaturedProvidersComponent } from './featured-providers/featured-providers.component';
import { PokersliderComponent } from './pokerslider/pokerslider.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    HomeRoutingModule,
    NgCircleProgressModule.forRoot({
      'backgroundOpacity': 0,
      'backgroundStrokeWidth': 0,
      'backgroundPadding': 0,
      'radius': 30,
      'space': -3,
      'toFixed': 5,
      'maxPercent': 100,
      'outerStrokeWidth': 3,
      'innerStrokeWidth': 3,
      'title': '',
      'titleFontSize': '12',
      'subtitleFontSize': '20',
      'animation': true,
      'animateTitle': false,
      'animationDuration': 15000,
      'showTitle': false,
      'showSubtitle': false,
      'showUnits': false,
      'showBackground': false,
      'startFromZero': true,
      'renderOnClick': false,
      'class': 'slider-nav',
      'responsive': true
    })
  ],
  declarations: [
    HomeComponent,
    CarouselComponent,
    ProductsComponent,
    OurFeaturesComponent,
    CasinoGamesComponent,
    FeaturedProvidersComponent,
    PokersliderComponent
  ],
  providers: []
})
export class HomeModule { }
