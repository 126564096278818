import { Component, OnInit } from '@angular/core';
import { CasinoService } from 'src/app/core';

@Component({
  selector: 'app-home-casino-games',
  templateUrl: './casino-games.component.html',
  styleUrls: ['./casino-games.component.scss']
})
export class CasinoGamesComponent implements OnInit {

  list: any = [];

  constructor(private casinoService: CasinoService) { }

  ngOnInit() {
    // const data = this.getFilterData();
    // this.casinoService.post('games', data).subscribe(
    //   res => {
    //     res.data = res.data.slice(0, 8);
    //     this.list = res.data;
    //   }
    // )
  }

  getFilterData() {
    return {
      vendorID: '128',
      category: 'LIVEDEALER',
      type: 'tables',
      platform: 'PC',
      nextIndex: 0
    };
  }

}
