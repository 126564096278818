import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ApiService, RegisterRequestData, JwtService } from 'src/app/core';
import { formatDate } from '@angular/common';
import { NgForm } from '@angular/forms';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerData = new RegisterRequestData();
  @ViewChild('registerForm') registerForm: NgForm;
  isLast: boolean = false;
  countries = [];
  currencies = [];
  securityQuestions = [];
  maxDate = new Date(Date.now() - ((86400000 * 365) * 18));
  country: any;
  currency: any;
  securityQ: any;
  response: any;
  hasBonus: boolean = false;
  bonusTrackingCode: string = '';

  constructor(
    public platform: Platform,
    private apiService: ApiService,
    public ngxSmartModalService: NgxSmartModalService,
    private jwtService: JwtService
  ) { }

  ngOnInit() {
    this.jwtService.hasLogin().subscribe(
      hasLogin => {
        if (!hasLogin) {
          this.loadFirstData();
          this.getSignUpBonus();
        }
      }
    );
  }

  register(formData: any, isFirst: boolean = false) {
    if (isFirst) {
      formData.target = 1;
      formData.mode = 0;
    } else {
      formData.target = 0;
      formData.mode = 1;
    }

    if (localStorage.getItem('btag')) {
      formData.AffiliateDetails.Tag = localStorage.getItem('btag');
    }

    if (localStorage.getItem('promo')) {
      formData.PromoCode = localStorage.getItem('promo');
    }

    if (this.hasBonus) {
      formData.BonusTrackingCode = this.bonusTrackingCode;
    } else {
      formData.BonusTrackingCode = '';
    }

    this.apiService.post('user/lordspokerregisterv2', formData, 'registerModal').subscribe(
      r => {
        this.response = r;
        if (isFirst && r.status) {
          this.response = '';
          this.isLast = true;
        }
      }
    );
  }

  getSignUpBonus() {
    this.apiService.post('bonus/CreateTrackingData/', { urlCode: 'BXPQLORPSLKOJRP' }).subscribe(
      bonus => {
        if (bonus.status) {
          this.bonusTrackingCode = bonus.trackData;
        }
      }
    )
  }

  formatDate(date: any) {
    return formatDate(date, 'yyyy/MM/dd', 'en-US');
  }

  loadFirstData() {
    this.apiService.post('user/RegistrationFormStaticData', {}, false, true).subscribe(
      res => {
        this.countries = res.countries;
        this.currencies = res.currencies;
        this.securityQuestions = res.securityQuestions;
        this.country = res.countries[226];
        this.currency = res.currencies[0];
      }
    );
  }

  resetForm() {
    this.securityQ = null;
    this.response = null;
    this.registerForm.reset();
    this.registerData = new RegisterRequestData();
    this.loadFirstData();
  }
}