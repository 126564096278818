import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sportsbook-event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.scss']
})
export class EventViewComponent implements OnInit {

  data: any = [];
  markets: any = [];
  moreMarkets: any = [];
  fullMarkets: any = [];
  selectedMarket: any;
  active: any = 'all';
  homeName: string;
  awayName: string;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    this.route.params
      .pipe(filter(params => params.id))
      .subscribe(
        r => {
          this.getOdds(r.id);
        }
      )
  }

  ngOnInit() {
  }

  getOdds(sportId: any) {
    this.apiService.post('event/' + sportId + '/oddswithgroup', {}, 'eventViewComp').subscribe(
      res => {
        const markets = [];
        for (let index = 0; index < res.oddSets.length; index++) {
          const element = res.oddSets[index];
          const oddGroup = element.competitionEventOddGroup;
          if (element.oddTypeGroupName === 'SCORE') {
            for (let i = 0; i < element.competitionEventOddGroup.length; i++) {
              const el = element.competitionEventOddGroup[i];
              if (el.typeId === '332') {
                res.oddSets[index].competitionEventOddGroup[i] = this.correctScore(el, res.title);
              }
            }
          } else if (oddGroup.length > 1) {

            const newGroups = {};
            for (let i = 0; i < oddGroup.length; i++) {
              const e = oddGroup[i];

              if (!newGroups[e.typeId]) {
                newGroups[e.typeId] = {};
                newGroups[e.typeId]['typeId'] = e.typeId;
                newGroups[e.typeId]['title'] = String(e.title.split('(' + e.specialBetValue + ')')[0]).trim();
                newGroups[e.typeId]['odds'] = [];
                if (e['odds'].length === 1) {
                  newGroups[e.typeId]['clsName'] = 12;
                } else if (e['odds'].length === 2) {
                  newGroups[e.typeId]['clsName'] = 6;
                } else {
                  newGroups[e.typeId]['clsName'] = 4;
                }
              }
              newGroups[e.typeId]['odds'] = [...newGroups[e.typeId]['odds'], ...e['odds']];

            }

            res.oddSets[index].competitionEventOddGroup = [];
            for (const key in newGroups) {
              if (newGroups.hasOwnProperty(key)) {
                const n = newGroups[key];
                res.oddSets[index].competitionEventOddGroup.push(n);
              }
            }
          }

          this.translate.get(element.oddTypeGroupName).subscribe((r: string) => {
            element.oddTypeGroupName = r;
            markets.push({
              oddTypeGroupIndex: element.oddTypeGroupIndex,
              oddTypeGroupName: element.oddTypeGroupName
            });
          });
        }

        this.data = res;
        this.fullMarkets = markets;
        this.markets = markets.slice(0, 4);
        this.moreMarkets = markets.slice(4);
        this.homeName = res.title.split('-')[0];
        this.awayName = res.title.split('-')[1];
      }
    );
  }

  correctScore(temp: any, title: string) {
    const item = {
      home: title.split('-')[0],
      away: title.split('-')[1],
      homeMax: 0,
      awayMax: 0,
      scores: {}
    };

    for (let index = 0; index < temp.odds.length; index++) {
      const element = temp.odds[index];
      const scrs = element.title.split(':');
      if (typeof scrs[0] !== 'undefined' && scrs[1] !== 'undefined') {
        if (item.homeMax < Number(scrs[0])) {
          item.homeMax = Number(scrs[0]);
        }
        if (item.awayMax < Number(scrs[1])) {
          item.awayMax = Number(scrs[1]);
        }

        item.scores[element.title] = element;
      }
    }
    return Object.assign({}, temp, item);
  }

}
