export class ScheduleRequestData {
  StartDate: any;
  EndDate: any;
  Limit: number = 0;
  Locale: string = 'en-gb';
  WildcardLocale: string = 'en-gb';
  CountryId: any = null;
  CompetitionId: any = null;
  CategoryId: any = null;
  TeamId: number = 0;
  PageSize: number = 10;
  PageIndex: number = 1;
  EventFilter: string = '';
  CompetitionFilter: string = '';
}
