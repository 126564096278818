import { Directive, HostListener, HostBinding, Renderer2, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDropdown]'
})
export class DropdownDirective {

  constructor(private renderer: Renderer2, private el: ElementRef) { }

  @HostBinding('class.active')
  private isActive = false;

  @HostListener('click') onMouseEnter() {
    this.isActive = !this.isActive;
    if (this.isActive) {
      this.addClass();
    } else {
      this.removeClass();
    }

  }

  @HostListener('document:click', ['$event']) clickedOutside($event: any) {
    if ($event.target.parentElement.classList[0] !== 'dropdown') {
      this.isActive = false;
      this.removeClass();
    }
  }

  private removeClass() {
    this.renderer.removeClass(this.el.nativeElement.children[0], 'active');
  }

  private addClass() {
    this.renderer.addClass(this.el.nativeElement.children[0], 'active');
  }


}
