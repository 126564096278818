import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'odd'
})
export class OddPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (args[0] && args[0] === 1) {
      return this.fractional(value);
    } else if (args[0] && args[0] === 2) {
      return this.decimal(value);
    } else if (args[0] && args[0] === 4) {
      return this.american(value);
    } else {
      return this.decimal(value);
    }
  }

  decimal(value: any) {
    return parseFloat(value).toFixed(2);
  }

  american(value: any) {
    let result;
    if (value <= 1) {
      result = '0';
    } else if (value < 2) {
      result = Math.round(-100 / (value - 1));
    } else if (value === 2) {
      result = '+/-100';
    } else if (value > 2) {
      result = '+' + Math.round(100 * (value - 1));
    }
    return result;
  }

  fractional(value: any) {
    if (value <= 1) {
      return '0';
    }

    const xStr = Math.round(value * 100) - 100;
    const yStr = 100;
    let x2Str, y2Str;
    let output;
    let gcd;
    const x = parseInt(String(xStr), 10);
    const y = parseInt(String(yStr), 10);

    if (x < 0 || x >= 9007199254740992 || y < 0 || y >= 9007199254740992) {
      return 0;
    } else {
      gcd = this.findGCD(Math.abs(x), Math.abs(y)).toString(10);
    }
    x2Str = xStr / gcd;
    y2Str = yStr / gcd;
    output = x2Str + '/' + y2Str;

    return output;
  }

  findGCD(x: any, y: any) {
    if (isNaN(x) || isNaN(y)) {
      return null;
    }

    while (y !== 0) {
      const z = x % y;
      x = y;
      y = z;
    }
    return x;
  }

}
