import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { EventsService, ApiService } from 'src/app/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ISubscription } from 'rxjs/Subscription';
import { diff } from 'deep-object-diff';
import { environment } from '../../../environments/environment';

@AutoUnsubscribe()
@Component({
  selector: 'app-sportsbook-category-betting-odds',
  templateUrl: './category-betting-odds.component.html',
  styleUrls: ['./category-betting-odds.component.scss']
})
export class CategoryBettingOddsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('mobileTopLeagues') mobileTopLeagues: ElementRef;
  @ViewChild('prematch') prematch: ElementRef;
  layout: any = [];
  pager: any = [];
  totalRow: number = 0;
  list: any = [];
  days: any = [];
  title: any;
  selectedDay: any = 0;
  pageIndex: any = 1;
  sliceStart: any = 0;
  sliceEnd: any = 4;
  oddType: any;
  liveList: any = [];
  liveLayout: any = [];
  countryList: any = [];
  leagueList: any = [];
  countryMdl: any = 0;
  leagueMdl: any = 0;
  columnLimit: any = 3;
  tableClass: any = 'large';
  timer: any;
  scrollLoad: boolean = false;
  imgUrl: string = environment.event_api_url + 'logo/';
  private preMatch: ISubscription;
  private liveMatch: ISubscription;
  indexTrackFn = (index: number) => index;

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    public eventService: EventsService,
    private apiService: ApiService
  ) {
    this.route.queryParams
      .subscribe(r => {
        if (typeof this.preMatch !== 'undefined') {
          this.preMatch.unsubscribe();
        }
        if (typeof this.liveMatch !== 'undefined') {
          this.liveMatch.unsubscribe();
        }
        this.get();
        if ((r['subcat'] && r['comp'])) {
          this.getSubCategories(Number(this.route.snapshot.paramMap.get('sportName')), true);
        } else if (r['sday']) {
          this.getSubCategories(Number(this.route.snapshot.paramMap.get('sportName')), true);
        }
      });

    this.route.paramMap.subscribe(
      () => {
        this.countryMdl = 0;
        this.leagueMdl = 0;
      }
    );
  }

  ngOnInit() {
    this.getSubCategories(Number(this.route.snapshot.paramMap.get('sportName')), true);
    for (let index = 0; index < 5; index++) {
      this.days.push({
        'day': Date.now() + (index * 86400000),
        'index': index
      });
    }
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.onResized();
    }, 0);
  }

  onResized() {
    if (this.prematch.nativeElement.offsetWidth > 900) {
      this.columnLimit = 3;
      this.tableClass = 'large';
    } else if (this.prematch.nativeElement.offsetWidth < 900 && this.prematch.nativeElement.offsetWidth > 700) {
      this.columnLimit = 2;
      this.tableClass = 'medium';
    } else {
      this.columnLimit = 1;
      this.tableClass = 'small';
    }
  }

  get(startDate?: any, endDate?: any, page?: any, resetContent: boolean = true) {
    const params = {};
    if (this.route.snapshot.queryParamMap.get('sday')) {
      const sday = Date.now() + (Number(this.route.snapshot.queryParamMap.get('sday')) * 86400000);
      params['StartDate'] = formatDate(sday, 'yyyy-MM-dd', 'en-US', 'UTC');
      params['EndDate'] = formatDate(sday, 'yyyy-MM-dd', 'en-US', 'UTC');
    } else if (this.route.snapshot.queryParamMap.get('mintime') && this.route.snapshot.queryParamMap.get('maxtime')) {
      const min = Number(this.route.snapshot.queryParamMap.get('mintime')) * 3600000;
      const max = Number(this.route.snapshot.queryParamMap.get('maxtime')) * 3600000;
      params['StartDate'] = formatDate(Date.now() + min, 'yyyy-MM-dd HH:mm:ss', 'en-US', 'UTC');
      params['EndDate'] = formatDate(Date.now() + max, 'yyyy-MM-dd HH:mm:ss', 'en-US', 'UTC');
      this.selectedDay = -1;
      params['withTime'] = true;
    } else {
      if (!startDate && !endDate) {
        params['StartDate'] = formatDate(Date.now(), 'yyyy-MM-dd', 'en-US', 'UTC');
        params['EndDate'] = formatDate(Date.now() + (15 * 86400000), 'yyyy-MM-dd', 'en-US', 'UTC');
      } else {
        params['StartDate'] = formatDate(startDate, 'yyyy-MM-dd', 'en-US', 'UTC');
        params['EndDate'] = formatDate(endDate, 'yyyy-MM-dd', 'en-US', 'UTC');
      }
    }

    if (page) {
      params['PageIndex'] = page;
    } else {
      params['PageIndex'] = 1;
      this.pageIndex = 1;
      this.sliceStart = 0;
      this.sliceEnd = 4;
    }

    params['CompetitionId'] = this.route.snapshot.queryParamMap.get('comp');
    params['CountryId'] = this.route.snapshot.queryParamMap.get('subcat');
    params['CategoryId'] = Number(this.route.snapshot.paramMap.get('sportName'));
    params['TeamId'] = Number(this.route.snapshot.queryParamMap.get('team'));

    const loader = resetContent ? 'sportsbBookTPLModal' : null;

    this.preMatch = this.eventService.getData(params, loader)
      .subscribe(
        r => {
          r = r[0];
          if (typeof r !== 'undefined' && r.list) {
            if (resetContent) {
              this.list = r.list;
            } else {
              this.list = [...this.list, ...r.list];
            }
            this.layout = r.layout;
            this.pager = r.pager;
            this.totalRow = r.totalRow;
          }else{
            this.list = [];
          }
          this.scrollLoad = true;
        }
      );

    if (this.selectedDay === 0 && !page && !this.route.snapshot.paramMap.get('sportName')) {
      this.getLive(params);
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.getLive(params, null);
      }, 6000);
    }
  }

  getLive(params: any, loaderId: string = 'catLives') {
    params['isLive'] = true;
    this.eventService.getData(params, loaderId, 'date', 'events')
      .subscribe(
        r => {
          r = r[0];
          if (r.list) {
            const df: any = diff(r.list, this.liveList);
            if (Object.keys(df).length > 0) {
              this.liveList = r.list;
              this.liveLayout = r.layout;
            }
          } else {
            this.liveList = [];
            this.liveLayout = [];
          }
        }
      );
  }

  getEventsOfDay(day: any) {
    if (this.mobileTopLeagues) {
      this.router.navigate([], { queryParams: { mintime: null, maxtime: null, subcat: null, comp: null, sday: day }, queryParamsHandling: 'merge' });
    } else {
      this.router.navigate([], { queryParams: { mintime: null, maxtime: null, sday: day }, queryParamsHandling: 'merge' });
    }
  }

  nextPage(page: any, resetContent: boolean = true) {
    this.scrollLoad = false;
    this.get(null, null, page, resetContent);
    this.pageIndex = page;
    if (page + 3 < this.pager.length) {
      this.sliceStart = page - 1;
      this.sliceEnd = page + 3;
    } else if (this.pager.length > 6) {
      this.sliceStart = this.pager.length - 4;
      this.sliceEnd = this.pager.length;
    }
  }

  getSubCategories(id?: any, noRoute: boolean = false) {
    let starDate = formatDate(Date.now(), 'yyyy-MM-dd', 'en-US', 'UTC');
    let endDate = 15;

    if (this.route.snapshot.queryParamMap.get('sday')) {
      const sday = Number(this.route.snapshot.queryParamMap.get('sday'));
      starDate = formatDate(Date.now() + (sday * 86400000), 'yyyy-MM-dd', 'en-US', 'UTC');
      endDate = sday;
    }

    if (id) {
      const data = {
        CategoryId: id,
        CompetitionId: null,
        IsLive: 0,
        dateStart: starDate + ' 00:00:00',
        dateEnd: formatDate(Date.now() + (endDate * 86400000), 'yyyy-MM-dd', 'en-US', 'UTC') + ' 23:59:59'
      };
      this.apiService.post('SubCategory/GetSubCategoriesWithCompetitions', data, false, true).subscribe(
        res => {
          if (res.length > 0) {
            this.countryList = res;
            if (this.route.snapshot.queryParamMap.get('subcat')) {
              this.countryMdl = this.route.snapshot.queryParamMap.get('subcat');
              this.countryChange(this.countryMdl, noRoute);
            } else {
              this.countryMdl = 0;
              this.leagueMdl = 0;
              this.leagueList = [];
            }
          }
        }
      );
    }
  }

  countryChange(item: number, noRoute: boolean = false) {
    if (Number(item) === 0) {
      if (!noRoute) {
        this.router.navigate([], { queryParams: { subcat: null, comp: null }, queryParamsHandling: 'merge', preserveFragment: true });
      }
      this.leagueList = [];
    } else {
      if (!noRoute) {
        this.router.navigate([], { queryParams: { subcat: item, comp: null }, queryParamsHandling: 'merge', preserveFragment: true });
      }
      for (let index = 0; index < this.countryList.length; index++) {
        const element = this.countryList[index];
        if (Number(item) === element.countryId) {
          this.leagueList = element.competitions;
          if (this.route.snapshot.queryParamMap.get('comp')) {
            this.leagueMdl = this.route.snapshot.queryParamMap.get('comp');
          } else {
            this.leagueMdl = 0;
          }
          break;
        }
      }
    }
  }

  leagueChange(item: number) {
    if (Number(item) === 0) {
      this.router.navigate([], { queryParams: { comp: null }, queryParamsHandling: 'merge', preserveFragment: true });
    } else {
      this.router.navigate([], { queryParams: { comp: item }, queryParamsHandling: 'merge', preserveFragment: true });
    }
  }
}
