import { Component, OnInit, OnDestroy } from '@angular/core';
import { formatDate } from '@angular/common';
import { ApiService } from 'src/app/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { map, filter } from 'rxjs/operators';

@AutoUnsubscribe()
@Component({
  selector: 'app-sidebar-prematch',
  templateUrl: './sidebar-prematch.component.html',
  styleUrls: ['./sidebar-prematch.component.scss']
})
export class SidebarPrematchComponent implements OnInit, OnDestroy {

  slidervalue: number = 0;
  categories: any = [];
  maxShowSub: number = 15;
  firstLine: boolean = false;
  lastLine: boolean = false;
  allLang: string = 'All';
  hourLang: string = 'Hours';

  constructor(
    private apiService: ApiService,
    public route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnDestroy() { }

  ngOnInit() {
    this.slidervalue = Number(this.route.snapshot.queryParamMap.get('mintime')) || this.slidervalue;
    this.getData();

    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd)
      )
      .subscribe(
        () => {
          const sportId = this.route.firstChild.snapshot.paramMap.get('sportName');
          const id = sportId ? Number(sportId) : false;
          if (id && id !== 118 && id !== 119) {
            this.isTopClick(id);
          }
        }
      );
  }

  formatLabel(value: number) {
    if (value !== 0) {
      return value;
    } else {
      return '?';
    }
  }

  setTime(val: number) {
    if (val !== 0) {
      this.router.navigate([], { queryParams: { mintime: 0, maxtime: val }, queryParamsHandling: 'merge' });
    } else {
      this.router.navigate([], { queryParams: { mintime: null, maxtime: null }, queryParamsHandling: 'merge' });
    }
  }

  selectResult(item: any) {
    if (item.type === 'Team') {
      this.router.navigate(['/sportsbook/eventview', item.id]);
    } else if (item.type === 'Competition') {
      this.router.navigate(
        ['/sportsbook/category', item.categoryId], { queryParams: { name: item.categoryName, state: item.categoryState, comp: item.id } }
      ).finally(
        () => {
          this.getData();
        }
      )
    }
  }

  getData() {
    this.apiService.post('Category/GetCategoriesWithEventCounter', { IsLive: 0, CurrentOnly: 1 }, 'sidebarCats', true)
      .pipe(
        map(data =>
          data.filter(item => (item.liveEventCount > 0 || item.eventCount > 0 || item.type === 1)
            && item.enabled && item.id !== 122 && item.id !== 135 && item.id !== 134)),
      ).subscribe(
        res => {
          this.categories = res;
          const state = Number(this.route.firstChild.snapshot.paramMap.get('sportName'));
          if (state === 118 || state === 119) {
            this.getSubCategories(null, true);
          } else {
            this.getSubCategories(state);
          }
        }
      );
  }

  getSubCategories(id?: any, isRace?: boolean) {
    if (id) {
      const data = {
        CategoryId: id,
        CompetitionId: null,
        IsLive: 0,
        dateStart: formatDate(Date.now(), 'yyyy-MM-dd', 'en-US', 'UTC'),
        dateEnd: formatDate(Date.now() + (15 * 86400000), 'yyyy-MM-dd', 'en-US', 'UTC')
      };
      this.apiService.post('SubCategory/GetSubCategoriesWithCompetitions', data, 'sidebarCats', true).subscribe(
        res => {
          if (res.length > 0) {

            const firstCountries = [];
            const secondCountries = [];
            const lastCountries = [];

            for (let index = 0; index < res.length; index++) {
              const element = res[index];
              if (Number(element.countryLevel) <= 3) {
                firstCountries.push(element);
              } else if (element.countryLevel <= 9) {
                secondCountries.push(element);
              } else {
                lastCountries.push(element);
              }
            }

            if (firstCountries.length > 0) {
              firstCountries[firstCountries.length - 1].borderBottom = true;
            }
            if (secondCountries.length > 0) {
              secondCountries[secondCountries.length - 1].borderBottom = true;
            }

            lastCountries.sort((a, b) => {
              const textA = a.subCategoryName.toUpperCase();
              const textB = b.subCategoryName.toUpperCase();
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

            res = [...firstCountries, ...secondCountries, ...lastCountries];

            for (let index = 0; index < this.categories.length; index++) {
              const element = this.categories[index];
              if (Number(element.id) === Number(id)) {
                element.subCategories = res;
                element.isShow = true;
              } else if (typeof element.subCategories !== 'undefined') {
                delete element.subCategories;
                element.isShow = false;
              } else {
                element.isShow = false;
              }
              this.categories[index] = element;
            }
          } else {
            this.getSubCategories();
          }
        }
      );
    } else {
      for (let index = 0; index < this.categories.length; index++) {
        const element = this.categories[index];
        if (typeof element.subCategories !== 'undefined') {
          delete element.subCategories;
          element.isShow = true;
        } else {
          element.isShow = true;
        }
        this.categories[index] = element;
      }
    }
  }

  isTopClick(id: number) {
    for (let index = 0; index < this.categories.length; index++) {
      const element = this.categories[index];
      if (element.id === id) {
        if (!element.isShow) {
          this.getSubCategories(id);
        }
      }
    }
  }
}
