import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgForm } from '@angular/forms';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ApiService, JwtService, UserService, BetCardService, CasinoService } from 'src/app/core';
import { Platform } from '@angular/cdk/platform';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BetSlipComponent } from 'src/app/shared/bet-slip/bet-slip.component';
import { CasinoPlaySuitComponent } from 'src/app/shared/casino-play-suit/casino-play-suit.component';
import { ResetPasswordComponent } from 'src/app/shared/reset-password/reset-password.component';
import { DatafeedService } from 'src/app/core/services/datafeed.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  response: any = false;
  LoginName: any;
  Password: any;
  email: any;

  constructor(
    public platform: Platform,
    public ngxSmartModalService: NgxSmartModalService,
    private storage: StorageMap,
    private apiService: ApiService,
    private jwtService: JwtService,
    private userService: UserService,
    private dataFeed: DatafeedService,
    private betcardService: BetCardService,
    private casinoService: CasinoService,
    private bottomSheet: MatBottomSheet) { }

  ngOnInit() {
  }

  login(loginForm: NgForm) {
    this.response = false;
    this.apiService.post('login', loginForm.form.value, 'loadingModal').subscribe(
      res => {
        this.response = res;
        if (res.status) {

          this.jwtService.login(res.token).subscribe(
            () => {
              const hasData = this.ngxSmartModalService.getModal('loginModal').getData();
              if (hasData === 'betcard') {
                this.openBottom();
                this.ngxSmartModalService.getModal('loginModal').removeData();
              } else if (hasData === 'casino') {
                this.openCasino();
                this.ngxSmartModalService.getModal('loginModal').removeData();
              }
              this.ngxSmartModalService.closeLatestModal();
              this.apiService.post('user/IsVip').subscribe(
                isVip => {
                  res.user.isVip = isVip.status || false;
                },
                err => {
                  res.user.isVip = false;
                },
                () => {
                  this.apiService.post('Account/IsLiveCasinoTransferEnabled').subscribe(
                    isEnable => {
                      res.user.casinoTransfer = isEnable.result || false;
                      this.storage.set('userInfo', res).subscribe(() => { });
                      this.userService.updateBalance().subscribe(() => { });
                      this.getData();
                    },
                    error => {
                      res.user.casinoTransfer = false;
                      this.storage.set('userInfo', res).subscribe(() => { });
                      this.userService.updateBalance().subscribe(() => { });
                      this.getData();
                    }
                  )
                }
              );
            }
          );
        }
      }
    );
  }

  getData() {
    this.apiService.post('User/GetDashboard').subscribe(
      r => {
        this.storage.set('User/GetDashboard', r).subscribe(() => { });
      }
    );
  }

  openBottom() {
    this.bottomSheet.open(BetSlipComponent, { panelClass: 'bottom-sheet-betslip', disableClose: true, closeOnNavigation: false })
      .afterOpened().subscribe(
        () => {
          this.betcardService.isBottomSheetOpen.next(true);
        }
      )
  }

  openCasino() {
    this.bottomSheet.open(CasinoPlaySuitComponent,
      { panelClass: 'bottom-sheet-casino', disableClose: true, closeOnNavigation: false })
      .afterOpened().subscribe(
        () => {
          this.casinoService.fastPlayOpen.next(true);
        }
      );
  }

  openResetPass() {
    const options: any = {
      escapable: false,
      dismissable: false,
      customClass: 'nsm-dialog-animation-btt app-modal login-modal'
    };

    this.ngxSmartModalService.create('resetPassModal', ResetPasswordComponent, options).open();
  }

}
