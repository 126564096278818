import { Component, OnInit, OnDestroy, Renderer2, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { ApiService, BetCardService } from 'src/app/core';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('searchInput') sInput: ElementRef;
  searchResult: any = [];

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private betCardService: BetCardService,
    private renderer: Renderer2
  ) { }

  ngOnInit() {

    combineLatest([this.route.queryParams]).subscribe(
      r => {
        this.search(r[0].k);
      }
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.sInput) {
        document.querySelector('#search-input').querySelector('input').focus();
      }
    }, 300);
  }

  search(key: string) {
    const dt = {
      q: key
    };
    this.apiService.post('Search/SearchV2', dt, 'searchResultLDR', true).subscribe(
      r => {
        if (r.length > 0) {
          const data = [],
            temp = [];
          for (let index = 0; index < r.length; index++) {
            const element = r[index];
            if (!data[element.categoryLocalized]) {
              data[element.categoryLocalized] = {};
              data[element.categoryLocalized].cat = element.categoryLocalized;
              data[element.categoryLocalized].state = element.categoryState;
              data[element.categoryLocalized].events = [];
            }
            data[element.categoryLocalized].events.push(element);
          }

          for (let key in data) {
            if (data.hasOwnProperty(key)) {
              const element = data[key];
              temp.push(element);
            }
          }
          this.searchResult = temp;
        } else {
          this.searchResult = false;
        }
      }
    );
  }

  toggleOdd(id: any) {
    this.betCardService.toggleOdd(id).subscribe(() => { });
  }

  ngOnDestroy() { }

}
