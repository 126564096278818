import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-casino-menu',
  templateUrl: './casino-menu.component.html',
  styleUrls: ['./casino-menu.component.scss']
})
export class CasinoMenuComponent implements OnInit {

  @Input() isSlide: boolean = false;
  @Input() category: any[];
  @Input() vendors: any[];
  @Output() catid = new EventEmitter();
  @Output() vendorid = new EventEmitter();
  @ViewChild('menu') menu: ElementRef;
  selectedCat: any = '';
  searchModel: any;
  selectedVendor: any = '';

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private router: Router
  ) { }

  ngOnInit() {}

  catChange(id: any) {
    if (id !== this.selectedCat) {
      this.catid.emit(id);
      this.selectedCat = id;
    } else {
      this.catid.emit('');
      this.selectedCat = '';
    }
  }

  vendorChange(id?: any) {
    if (id) {
      this.vendorid.emit(id);
      this.selectedVendor = id;
    } else {
      this.vendorid.emit('');
      this.selectedVendor = '';
    }
  }

  openSelect() {
    this.renderer.addClass(this.el.nativeElement.closest('.ng-sidebar__content'), 'overflow-hidden');
  }

  closeSelect() {
    this.renderer.removeClass(this.el.nativeElement.closest('.ng-sidebar__content'), 'overflow-hidden');
  }

  searchControl(key: string) {
    if (key && key.length > 0) {
      this.router.navigate([], { queryParams: { k: key } });
    } else {
      this.router.navigate([], { queryParams: { k: null } });
    }
  }

  leftScrool(event: any) {
    event.scrollLeft = 0;
  }

  rightScrool(event: any) {
    event.scrollLeft = Number(event.scrollLeft + (this.menu.nativeElement.clientWidth-50));
  }

}
