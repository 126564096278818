import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CasinoRoutingModule } from './casino-routing.module';
import { SharedModule } from '../shared';
import { CasinoComponent } from './casino.component';
import { SliderComponent } from './slider/slider.component';
import { SuitComponent } from './suit/suit.component';
import { DashboardComponent } from './dashboard/dashboard.component';

@NgModule({
  imports: [
    CommonModule,
    CasinoRoutingModule,
    SharedModule
  ],
  declarations: [CasinoComponent, SliderComponent, SuitComponent, DashboardComponent]
})
export class CasinoModule { }
