import { Component, OnInit, Input, forwardRef, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, AbstractControl, ValidatorFn, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { ValidatorService } from './validator.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    }
  ]
})
export class InputComponent implements ControlValueAccessor, OnInit {

  @Output() onfocus = new EventEmitter<any>();
  @Input() type: string = 'text';
  @Input() required = false;
  @Input() readonly = false;
  @Input() placeholder = '';
  @Input() val: any = null;
  @Input() danger: boolean;
  @Input() success: boolean;
  @Input() disabled: boolean;
  @Input() withIcon: string;
  @Input() rightSide: boolean;
  @Input() iconBox: boolean;
  @Input() alert: string;
  @Input() validation: any;
  @Input() bmask: any = null;
  @Input() bprefix: any = '';
  @Input() bsuffix: any = '';
  @Input() bvalidation: boolean = true;
  @Input() bshowmask: boolean = false;
  @Input() bspecialCharacters: string = '[]';
  @Input() min: any = 0;
  @Input() max: any = 255;
  @Input() clsName: string = '';


  private innerValue: any = '';
  private validator: ValidatorFn;
  private onTouchedCallback: () => void = () => { };
  private onChangeCallback: (_: any) => void = () => { };

  constructor(private validationService: ValidatorService) { }

  ngOnInit() {
    this.validator = this.validationService.validate(this.validation);
  }

  validate(c: AbstractControl) {
    return this.validator(c);
  }

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
