import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'app-promotions-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit,OnDestroy {

  data = [];
  data2 = {};
  activeData: any = 'all';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService
  ) {
    this.route.queryParams.subscribe(
      params => {
        if (typeof params.state === 'undefined') {
          this.router.navigate([], { queryParams: { state: 'all' } });
        } else {
          this.activeData = params.state;
        }
      }
    )
  }

  ngOnInit() {
    this.getBonusList();
  }

  ngOnDestroy(){}

  getBonusList() {

    this.apiService.post('bonus/GetActiveBonusList', null, 'promoListLDR', true).subscribe(
      r => {
        if (r && r.status) {
          const data = [];
          const list = {};
          let tmp: any[] = [];
          tmp = JSON.parse(JSON.stringify(r.bonusList));

          for (let index3 = 0; index3 < tmp.length; index3++) {
            const element = tmp[index3];
            element.bannerUrl = './assets/img/bonus/' + element.bannerUrl;
            element.bannerUrl = element.bannerUrl.replace('-tr.jpg', '.jpg');
            element.bannerUrl = element.bannerUrl.replace('-en.jpg', '.jpg');
            data.push(element);
          }

          list['all'] = {};
          list['all'].name = 'all';
          list['all'].sort = 0;
          list['all'].data = [];
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            const types = element.subType.split(',');

            for (let index2 = 0; index2 < types.length; index2++) {
              const type = types[index2];

              if (!list[type]) {
                list[type] = {};
                list[type].name = type;
                list[type].data = [];
                if (type === 'other') {
                  list[type].sort = 99;
                } else {
                  list[type].sort = index;
                }
              }
              element.type = type;
              list[type].data.push(element);
            }

            list['all'].data.push(element);
          }

          const temp = [];
          for (const key in list) {
            if (list.hasOwnProperty(key)) {
              const element = list[key];
              temp.push(element);
            }
          }

          this.data2 = list;
          this.data = temp;
        }
      }
    );
  }
}
