import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService, BetCardService } from 'src/app/core';
import { environment } from '../../../environments/environment';
import { combineLatest } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'app-virtual-bet-virtual',
  templateUrl: './virtual.component.html',
  styleUrls: ['./virtual.component.scss']
})
export class VirtualComponent implements OnInit, OnDestroy {

  data: any = [];
  iframeUrl: any;
  orginalUrl: any;
  type: string;
  timer: any;
  moreOdds: any = [];
  openEvents: any = [];
  indexTrackFn = (index: number) => index;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private betCardService: BetCardService
  ) { }

  ngOnInit() {
    combineLatest([this.route.queryParams]).subscribe(
      r => {
        if (r[0]['c']) {
          this.getTheVirtual(r[0]['c']);
          this.type = r[0]['c'];
          clearInterval(this.timer);
          this.timer = setInterval(() => {
            this.getTheVirtual();
          }, 6000);
        }
      }
    );
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }


  toggleOdd(id: any) {
    this.betCardService.toggleOdd(id).subscribe(() => { });
  }

  getTheVirtual(type: string = this.type) {
    switch (type) {
      case 'vfl':
        this.getVfl();
        break;
      case 'vtl':
        this.getVto();
        break;
      case 'vbl':
        this.getVbl();
        break;
      default:
        break;
    }
  }

  getVfl() {
    this.apiService.post('vfl/get/125').subscribe(
      r => {
        if (typeof r.resultCode !== 'undefined') {
          this.data = r.result;
          if (String(this.orginalUrl) === String(environment.virtual_host + r.result.clientUrl.substr(1))) {
            return;
          } else {
            this.orginalUrl = false;
            this.orginalUrl = environment.virtual_host + r.result.clientUrl.substr(1);
            this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.orginalUrl + '2');
          }
        }
      }
    );
  }

  getVto() {
    this.apiService.post('vto/get', {}).subscribe(
      r => {
        if (typeof r.resultCode !== 'undefined') {
          this.data = r.result;
          if (String(this.orginalUrl) === String(environment.virtual_host + r.result.clientUrl.substr(1))) {
            return;
          } else {
            this.orginalUrl = false;
            this.orginalUrl = environment.virtual_host + r.result.clientUrl.substr(1);
            this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.orginalUrl + '2');
          }
        }
      }
    );
  }

  getVbl() {
    this.apiService.post('vbl/get/135', {}).subscribe(
      r => {
        if (typeof r.resultCode !== 'undefined') {
          this.data = r.result;
          if (String(this.orginalUrl) === String(environment.virtual_host + r.result.clientUrl.substr(1))) {
            return;
          } else {
            this.orginalUrl = false;
            this.orginalUrl = environment.virtual_host + r.result.clientUrl.substr(1);
            this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.orginalUrl + '2');
          }
        }
      }
    );
  }

  getIframe() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.orginalUrl);
  }

  getMoreOdds(eventID: number) {
    this.openEvents[eventID] = !this.openEvents[eventID];
    this.moreOdds = [];
    this.apiService.post('event/' + eventID + '/odds', {}, 'virtualLeagueLoader').subscribe(
      r => {
        this.moreOdds = r.oddSets;
      }
    )
  }

}
