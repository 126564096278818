import { Component, OnInit, OnDestroy, HostListener, Inject } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ApiService, CasinoService, BetCardService, UpdateService, VersionCheckService } from 'src/app/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from './../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  homeHeader: any;
  homeFooter: boolean = false;
  hideMobileMenu: any = false;
  pageClass: string;
  betSlipOpen: boolean;
  _opened: boolean = false;
  opened: boolean = false;
  deferredPrompt: any;
  showButton = false;

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e: any) {
    e.preventDefault();
    this.deferredPrompt = e;
    this.showButton = true;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private ngxSmartModalService: NgxSmartModalService,
    private sw: UpdateService,
    private breakpointObserver: BreakpointObserver,
    private casinoService: CasinoService,
    public betCardService: BetCardService,
    private versionCheck: VersionCheckService,
    private deviceService: DeviceDetectorService,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document) {
    // this.redirectMobile();
    this.sw.checkForUpdates();
    this.homeFooter = true;

    // this.breakPoints();

    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd)
      )
      .subscribe(() => {

        //  if (this.router.url.search('/sportsbook') > -1 || this.router.url.search('/livebetting') > -1) {
        //    document.body.classList.add('overflow-xl-y-hidden');
        //  } else {
        //    document.body.classList.remove('overflow-xl-y-hidden');
        //  }

        if (this.route.snapshot.queryParams.validate && this.route.snapshot.queryParams.validate.length > 5) {
          this.validateAccount(this.route.snapshot.queryParams.validate);
        }
      });
  }

  ngOnInit() {

    // this.versionCheck.initVersionCheck('./version.json');

    // this.betCardService.isBottomSheetOpen.subscribe(
    //   isOpen => {
    //     this.betSlipOpen = isOpen;
    //   }
    // );

    // this.casinoService.fastPlayOpen.subscribe(
    //   isOpen => {
    //     this._opened = isOpen;
    //   }
    // );
  }

  ngOnDestroy() { }


  breakPoints() {
    this.breakpointObserver.observe([
      Breakpoints.Small,
      Breakpoints.XSmall
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.homeFooter = true;
      } else {
        this.homeFooter = this.router.url.search('/casino/suit') === -1 &&
          this.router.url.search('/sportsbook') === -1 &&
          this.router.url.search('/livebetting') === -1;
      }
    });
  }

  validateAccount(tkn: string) {
    this.apiService.post('validate/index', { token: tkn }).subscribe(
      r => {
        this.openModal(r);
      }
    );
  }
  openModal(item: any) {
    this.ngxSmartModalService.getModal('validateModal').setData(item).open();
  }

  addBodyCls() {
    if (this.opened) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }

  // redirectMobile() {
  //   if (this.deviceService.isMobile() && environment.production) {
  //     const mobile = 'https://m.' + this.document.location.hostname + this.document.location.search;
  //     this.document.location.href = mobile;
  //   }
  // }
}
