import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HttpClient } from '@angular/common/http';
import { LangService } from 'src/app/core';

@Component({
  selector: 'app-sportsbook-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  data: any;
  defaultLang: any = 'en';

  customOptions: OwlOptions = {
    autoplay: true,
    autoplayHoverPause: true,
    loop: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    smartSpeed: 1000,
    dotsSpeed: 1000,
    dragEndSpeed: 1000,
    autoplayTimeout: 5000,
    nav: false,
    lazyLoad: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    }
  };

  constructor(
    private http: HttpClient,
    private langService: LangService
  ) {
    this.defaultLang = this.langService.currentLang();
  }

  ngOnInit() {
    this.http.get('assets/img/slider/sportsbook.json?' + Date.now()).subscribe(
      r => {
        this.data = r;
      }
    );
  }

}
