import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { BetCardService, OddtypeService, FavoriteService } from 'src/app/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Platform } from '@angular/cdk/platform';
import { StandingsComponent } from 'src/app/shared/standings/standings.component';

@Component({
  selector: 'app-sportsbook-top-leagues',
  templateUrl: './top-leagues.component.html',
  styleUrls: ['./top-leagues.component.scss']
})
export class TopLeaguesComponent implements OnInit, OnDestroy {

  @ViewChild('panel') panel: ElementRef;
  @Input() layout: any;
  @Input() list: any = [];
  @Input() catIcon: any;
  @Input() columnLimit: any = 3;
  @Input() tableClass: any = 'large';
  categoryFilter: any;
  oddType: any;
  indexTrackFn = (index: number) => index;

  constructor(
    public betCardService: BetCardService,
    private oddTypeService: OddtypeService,
    private ngxSmartModalService: NgxSmartModalService,
    private platform: Platform,
    private favoriteService: FavoriteService
  ) {
    this.oddTypeService.oddSubject.subscribe(
      r => {
        this.oddType = r;
      }
    );
  }

  ngOnInit() { }

  ngOnDestroy() { }

  onResized() {
    if (this.panel.nativeElement.offsetWidth > 900) {
      this.columnLimit = 3;
    } else if (this.panel.nativeElement.offsetWidth < 900 && this.panel.nativeElement.offsetWidth > 700) {
      this.columnLimit = 2;
    } else {
      this.columnLimit = 1;
    }
  }

  getFilterObject(temp: any, event: any) {
    for (let index = 0; index < temp.length; index++) {
      const element = temp[index];
      if (element.oddTypeTitle === event.oddTypeTitle) {
        event.index = index;
        break;
      }
    }
    return event;
  }

  toggleOdd(id: any) {
    this.betCardService.toggleOdd(id).subscribe(() => { });
  }

  addFav(item: any) {
    const temp = {
      id: item.competitionId,
      competitionId: item.competitionId,
      competitionName: item.competitionName,
      subCategoryName: item.subCategoryName,
      sportId: item.sportId,
      countryIsoCode: item.countryIsoCode
    };
    this.favoriteService.setFavorite(temp, 'comp');
  }

  openStanding(id: number, homeTeamId: number, awayTeamId: number) {
    const options: any = {
      escapable: false,
      dismissable: false,
      customClass: !this.platform.BLINK ? 'theme-light app-modal statscore-modal' : 'theme-light app-modal statscore-modal animated zoomIn faster'
    };

    const data = {
      competitonId: id,
      homeId: homeTeamId,
      awayId: awayTeamId
    };
    this.ngxSmartModalService.resetModalData('standingModal');
    this.ngxSmartModalService.create('standingModal', StandingsComponent, options).setData(data).open();
  }

  isTrueOddType(oddType: any, filter: any) {
    if (String(oddType.oddTypeTitle + oddType.oddTypeSpecialBetValue) === String(filter.oddTypeTitle + filter.oddTypeSpecialBetValue)) {
      return true;
    } else {
      return false;
    }
  }

}
