import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiService, JwtService } from 'src/app/core';
import { formatDate } from '@angular/common';
import { resultCategories as ctgr } from 'src/app/core';

@Component({
  selector: 'app-live-tv',
  templateUrl: './live-tv.component.html',
  styleUrls: ['./live-tv.component.scss']
})
export class LiveTvComponent implements OnInit, AfterViewInit {

  sources: any = [
    {
      src: 'https://tv-trtspor1.live.trt.com.tr/master_720.m3u8',
      type: 'video',
      homeTeam: 'TRT Spor',
      awayTeam: '',
      matchDate: Date.now
    }
  ];

  categories = ctgr;
  selectedChannel: any;
  beforeSelectedChannel: any;
  isActive: boolean = true;

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService
  ) {
    this.selectedChannel = this.sources[0];
    this.beforeSelectedChannel;
  }

  ngOnInit(): void {
    this.jwtService.hasLogin().subscribe(
      _ => {
        this.getLiveStreamingSchedule();
      }
    );
  }

  ngAfterViewInit(): void {
    this.played(this.sources[0].src);
    this.run();
  }

  run() {
    this.jwtService.isLoggedIn().subscribe(
      isLogin => {
        if (isLogin) {
          this.getLiveStreamingSchedule();
        }
      }
    );
  }

  played(src: any = this.sources[0].src, isDestroy = false) {
    this.isActive = false;
    if (isDestroy) {
      setTimeout(() => {
        this.isActive = true;
      }, 0);
    } else {
      this.isActive = true;
    }
    this.beforeSelectedChannel = src;
  }

  getLiveStreamingSchedule() {

      const data = {
        sportTypeId: 0,
        startDate: formatDate(Date.now(), 'yyyy-MM-dd', 'en-US', 'UTC') + ' 00:00:00',
        endDate: formatDate(Date.now(), 'yyyy-MM-dd', 'en-US', 'UTC') + ' 23:59:59'
      };

      this.apiService.post('User/GetLiveStreamingSchedule', data, null, true).subscribe(
        r => {
          if (r && r.length > 0) {
            r.map((k: any) => {
              k.src = k.streamingUrl;
              k.type = 'video';
              return k;
            });
            const defaultChannel = [];
            defaultChannel.push(this.sources[0]);
            this.sources = [...defaultChannel, ...r];
          }
        }
      );
  }

}
