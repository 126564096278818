import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CasinoService, JwtService, ApiService } from 'src/app/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CasinoPlaySuitComponent } from 'src/app/shared/casino-play-suit/casino-play-suit.component';

@Component({
  selector: 'app-playtech-casino',
  templateUrl: './playtech-casino.component.html',
  styleUrls: ['./playtech-casino.component.scss']
})
export class PlaytechCasinoComponent implements OnInit {

  @Input() dataList: any = [];
  classList: string = 'col-xl-3 col-lg-3 col-md-4';
  animateClass: string = 'zoomIn';
  suitUrl: string = 'casino/suit';
  sampleData: object = {
    "data": {
      "id": "-1",
      "slug": "alaskan-fishing",
      "playMode": {
        "fun": true,
        "anonymity": true,
        "realMoney": true
      },
      "presentation": {
        "gameName": {
          "*": "Alaskan Fishing"
        },
        "shortName": {
          "*": "Alaskan Fishing"
        },
        "backgroundImage": {
          "*": "//static.everymatrix.com/cms2/base/_casino/3/35DC003F8443F0B7C73E73F25DDE8598.jpg"
        }
      }
    }
  };

  constructor(
    private breakpointObserver: BreakpointObserver,
    public jwtService: JwtService,
    private casinoService: CasinoService,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
  }

  play(item: any) {
    this.sampleData['data']['slug'] = item.Code;
    sessionStorage.setItem('casinoGameData', JSON.stringify(this.sampleData));
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.bottomSheet.open(CasinoPlaySuitComponent,
          { panelClass: 'bottom-sheet-casino', disableClose: true, closeOnNavigation: false })
          .afterOpened().subscribe(
            () => {
              this.casinoService.fastPlayOpen.next(true);
            }
          );
      } else {
        this.router.navigate(['/' + this.suitUrl]);
      }
    }).unsubscribe();
  }

}
