import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core';

@Component({
  selector: 'app-live-betting-sidebar-live-list',
  templateUrl: './sidebar-live-list.component.html',
  styleUrls: ['./sidebar-live-list.component.scss']
})
export class SidebarLiveListComponent implements OnInit {

  data: any;
  time: any;
  indexTrackFn = (index: number) => index;

  constructor(
    private apiService: ApiService
  ) {
    this.getLiveEvents();
    this.time = setInterval(() => {
      this.getLiveEvents(false);
    }, 60000);
  }

  ngOnInit() {
  }

  getLiveEvents(loaderId: any = 'sidebarLiveList') {
    this.apiService.post('LiveBetsV2/GetFullLiveBettingWithMarkets', {}, loaderId).subscribe(
      r => {
        const data = [],
          temp = [];
        for (let index = 0; index < r.length; index++) {
          const item = r[index];
          if (!data[item.sportId]) {
            data[item.sportId] = {};
            data[item.sportId].id = item.sportId;
            data[item.sportId].name = item.categoryName;
            data[item.sportId].state = item.categoryState;
            data[item.sportId].comps = [];
          }

          if (!data[item.sportId].comps[item.competitionId]) {
            data[item.sportId].comps[item.competitionId] = {};
            data[item.sportId].comps[item.competitionId].id = item.competitionId;
            data[item.sportId].comps[item.competitionId].name = item.competitionName;
            data[item.sportId].comps[item.competitionId].events = [];
          }

          data[item.sportId].comps[item.competitionId].events.push(item);
        }

        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const element = data[key];
            element.comps = this.formatComp(element.comps);
            temp.push(element);
          }
        }
        this.data = temp;
      }
    );
  }

  formatComp(temp: any) {
    const data = [];
    for (const k in temp) {
      if (temp.hasOwnProperty(k)) {
        const e = temp[k];
        data.push(e);
      }
    }
    return data;
  }

}
