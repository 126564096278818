import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ApiService } from 'src/app/core';

export class ResetPassData {
  EMail: any = '';
  SecurityAnswer: any = '';
  SecurityQuestion: any = '';
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  passResetOk: boolean = false;
  passResetRes: any = '';
  resetPassData = new ResetPassData();
  securityQuestions: any;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.loadFirstData();
  }

  loadFirstData() {
    this.apiService.post('user/RegistrationFormStaticData', {}, false, true).subscribe(
      res => {
        this.securityQuestions = res.securityQuestions;
      }
    );
  }

  resetPassword() {
    this.apiService.post('user/resetpassword', this.resetPassData, 'resetPassModal').subscribe(
      r => {
        if (r.status === 1) {
          this.passResetOk = true;
        } else {
          this.passResetOk = false;
        }
        this.passResetRes = r;
      }
    );
  }

}
