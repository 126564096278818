import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-best-seller',
  templateUrl: './best-seller.component.html',
  styleUrls: ['./best-seller.component.scss']
})
export class BestSellerComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    nav: true,
    navText: ['<span class="icon bet-left-dir direction left"></span>', '<span class="icon bet-right-dir direction right"></span>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    }
  };

  dataModel17: number = 150;
  index: any = 0;
  data = [
    {
      'id': 0,
      'url': 'assets/img/bestseller.png',
      'title': 'Jackpot Bestseller',
      'content': 'Small stakes, big winnings!'
    },
    {
      'id': 1,
      'url': 'assets/img/bestseller.png',
      'title': 'Jackpot Bestseller',
      'content': 'Small stakes, big winnings!'
    },
    {
      'id': 2,
      'url': 'assets/img/bestseller.png',
      'title': 'Jackpot Bestseller',
      'content': 'Small stakes, big winnings!'
    },
    {
      'id': 3,
      'url': 'assets/img/bestseller.png',
      'title': 'Jackpot Bestseller',
      'content': 'Small stakes, big winnings!'
    }
  ];

  constructor() { }

  ngOnInit() {
  }

  number(val: any) {
    return Number(val);
  }

}
