import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core';

@Component({
  selector: 'app-promotions-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  list: any = [];

  constructor(
    private apiService: ApiService
  ) {
    this.get();
  }

  ngOnInit() {
  }

  get() {
    this.apiService.post('bonus/GetActiveBonusList', null, 'promoListSideLDR', true).subscribe(
      r => {
        if (r && r.status) {
          this.list = r.bonusList;
        }
      }
    )
  }

}
