import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared';

import { LiveBettingRoutingModule } from './live-betting-routing.module';
import { LiveBettingComponent } from './live-betting.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EventViewComponent } from './event-view/event-view.component';
import { EventWidgetComponent } from './event-widget/event-widget.component';
import { SidebarLiveListComponent } from './sidebar-live-list/sidebar-live-list.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { TopLiveListComponent } from './top-live-list/top-live-list.component';
import { LiveMatchStreamComponent } from './live-match-stream/live-match-stream.component';

@NgModule({
  imports: [
    CommonModule,
    LiveBettingRoutingModule,
    SharedModule
  ],
  declarations: [LiveBettingComponent, DashboardComponent, EventViewComponent, EventWidgetComponent, SidebarLiveListComponent, ScheduleComponent, TopLiveListComponent, LiveMatchStreamComponent]
})
export class LiveBettingModule { }
