import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { LangService } from './lang.service';
import { OddtypeService } from './oddtype.service';
import { FavoriteService } from './favorite.service';
import { StorageMap } from '@ngx-pwa/local-storage';

@Injectable({
  providedIn: 'root'
})
export class AnonTokenService {

  constructor(
    private jwtService: JwtService,
    private apiService: ApiService,
    private langService: LangService,
    private oddTypeService: OddtypeService,
    private favoriteService: FavoriteService,
    private storage: StorageMap
  ) { }


  run() {

    return new Promise(async resolve => {
      if (!await this.storage.has('uuid').toPromise()) {
        const uid = String(await uuid());
        await this.storage.set('uuid', uid).toPromise();
      }

      await this.langService.init().toPromise();
      this.oddTypeService.init();
      this.favoriteService.init();

      const token = await this.storage.get('token').toPromise();

      if (!token) {
        setTimeout(() => {
          this.apiService.get('token').subscribe(
            res => {
              if (res.token) {
                this.jwtService.saveToken(res.token).subscribe(
                  ()=>{
                    resolve(true);
                  }
                )
              } else {
                resolve(false);
              }
            },
            () => {
              resolve(false);
            }
          );
        }, 500);
      } else {
        resolve(false);
      }
    });
  }
}
