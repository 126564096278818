import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-redirect-lobby',
  templateUrl: './redirect-lobby.component.html',
  styleUrls: ['./redirect-lobby.component.scss']
})
export class RedirectLobbyComponent implements OnInit, AfterViewInit {

  @ViewChild('lobby') lobby: ElementRef;
  lobbyUrl:any;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.lobbyUrl = './'+this.route.snapshot.paramMap.get('lobby')+'/'+this.route.snapshot.paramMap.get('lobby2');
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.lobby.nativeElement.click();
    }, 10000)
  }

}
