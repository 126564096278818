import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { LangService } from 'src/app/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-live-casino-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {


  data: any;
  defaultLang: any = 'en';
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 1500,
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    }
  };

  constructor(
    private http: HttpClient,
    private langService: LangService
  ) {
    this.defaultLang = this.langService.currentLang();
  }

  ngOnInit() {
    this.http.get('assets/img/slider/casino.json?' + Date.now()).subscribe(
      r => {
        this.data = r;
      }
    );
  }

}
