import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { ApiService, GlobalModalService, JwtService, LangService } from 'src/app/core';
import { DatafeedService } from '../core/services/datafeed.service';
import { forkJoin } from 'rxjs';
import { WINDOWS_OS } from 'ngx-responsive';
import { ConsoleService } from '@ng-select/ng-select/lib/console.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  selectedLang: any;
  langs: any[];
  langCode: string;
  token: string;
  pokerUrl: any;
  qToken: string;
  usercode: string;
  language: string;
  errorMsg: string;
  isValid: boolean;
  userName: string;
  qUserCode: string;
  isMobile: boolean;
  isPlayPoker: boolean = false;
  isLoggedIn: Observable<boolean>;
  casinoLobiUrl: string = "www.google.com";
  pageHeight: number = 800;
  buttonWidth: number;

  constructor(
    private globalModalService: GlobalModalService,
    private sanitizer: DomSanitizer,
    public deviceService: DeviceDetectorService,
    private jwtService: JwtService,
    private langService: LangService,
    private apiService: ApiService,
    private dataFeed: DatafeedService) {
    this.isLoggedIn = this.jwtService.isLoggedIn();
    this.isMobile = this.deviceService.isMobile();

    if (this.isMobile) {
      this.pageHeight = window.innerHeight * 0.88;
    }
  }

  ngOnInit() {
    if (window.matchMedia("(orientation: portrait)").matches) {
      this.buttonWidth = 35;
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
      this.buttonWidth = 60;
    }

    this.dataFeed.isPlayPoker.subscribe(res => {
      this.isPlayPoker = res;
    });

    this.langs = this.langService.langs;
    this.langService.selectedLang().subscribe(
      r => {
        this.selectedLang = r;
        this.langCode = this.selectedLang.code;
      }
    );

    this.jwtService.isLoggedIn().subscribe(res => {
      if (res) {
        this.getProfile();
      }
    });
  }

  ngAfterViewInit() {
    this.globalModalService.messageControl('modal_home');
  }

  PlayPoker() {
    this.apiService.post('casino/GetPlaytechPokerLauncher').subscribe(
      r => {
        if (r.resultCode == 0) {
          this.pokerUrl = r.result;
          this.pokerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.pokerUrl);
          this.isPlayPoker = true;
        }
        else {
          console.log("error ", r.result)
        }
      }
    );
  }

  PlayPokerMobile() {
    this.apiService.post('casino/GetPlaytechPokerLauncher').subscribe(
      r => {
        if (r.resultCode == 0) {
          window.open(r.result, "_blank");
        }
        else {
          console.log("error ", r.result)
        }
      }
    );
  }

  profileData: any;
  getProfile() {
    const data = this.apiService.post('User/GetProfile', {}, 'myaccountMdl');
    forkJoin([data]).subscribe(
      r => {
        this.userName = r[0].userName;
      }
    );
  }

  GetToken() {
    return new Observable(observer => {
      this.apiService.post('casino/GetPlaytechPokerLauncher', 'casinoPlayModal').subscribe(
        r => {
          if (r.result === 'prohibited') {
            this.pokerUrl = false;
            this.errorMsg = 'This game is not allowed to play in your country.';
          } else {
            if (this.deviceService.isMobile()) {
              r.result = r.result.replace('clientPlatform=web', 'clientPlatform=mobile');
            }
            const currentHostname = r.result + '&component=poker&lobbyUrl=' + location.protocol + '//' + location.hostname + '/redirect-lobby' + this.casinoLobiUrl;
            this.pokerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(currentHostname);
          }
          // this.casinoMessageControl(String(this.gameData['data']['slug']));
          observer.next(r)
        }
      );
    });
  }
}