import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-live-casino',
  templateUrl: './live-casino.component.html',
  styleUrls: ['./live-casino.component.scss']
})
export class LiveCasinoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
