import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io } from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class CasinoSocketService {

  private socket: any;

  constructor() { }

  connect() {
    this.socket = io('https://node.ncybs.com:2053', {secure: true});
  }

  sendMessage(msg: string) {
    this.socket.emit('msg', { message: msg });
  }

  onMessage(type: string) {
    return new Observable(
      observer => {
        this.socket.on(type, msg => {
          observer.next(msg);
        });
      });
  }
}
